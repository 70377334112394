import { render, staticRenderFns } from "./CodeTagEditor.vue?vue&type=template&id=70223ac9&scoped=true&"
import script from "./CodeTagEditor.vue?vue&type=script&lang=js&"
export * from "./CodeTagEditor.vue?vue&type=script&lang=js&"
import style0 from "./CodeTagEditor.vue?vue&type=style&index=0&id=70223ac9&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70223ac9",
  null
  
)

export default component.exports