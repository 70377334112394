import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "destination-card"
  }, [_c('div', {
    staticClass: "card-header d-flex align-center justify-space-between",
    class: {
      'selected': _vm.selected
    }
  }, [_c(VIcon, [_vm._v("mdi-drag-vertical")]), _c('div', {
    staticClass: "destination-name font-weight-medium mr-auto"
  }, [_vm._v(_vm._s(_vm.destination.name))]), !_vm.redirectionSimulator ? _c('div', {
    staticClass: "actions-ctn"
  }, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editDestination();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-circle-edit-outline")])], 1), _c(VBtn, {
    attrs: {
      "color": "red",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteDialog = true;
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-minus-circle-outline")])], 1)], 1) : _c('div', {
    staticClass: "pa-2"
  }, [_vm.simulating ? _c(VProgressCircular, {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "size": "16",
      "width": "2"
    }
  }) : _vm.getDestinationSimulationStatus(_vm.destination) === 'fail' ? _c(VIcon, {
    attrs: {
      "color": "red"
    }
  }, [_vm._v("mdi-close")]) : _vm.getDestinationSimulationStatus(_vm.destination) === 'success' ? _c(VIcon, {
    attrs: {
      "color": "green"
    }
  }, [_vm._v("mdi-flag")]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "card-content pa-3"
  }, [_vm.computedConditions.length ? _c('div', [_c('div', {
    staticClass: "summary-ctn"
  }, _vm._l(_vm.computedConditions, function (condition, index) {
    return _c('div', {
      key: "summary-".concat(index),
      staticClass: "expression d-flex justify-center align-center my-0"
    }, [_c('expression-block-preview', {
      attrs: {
        "conditions": condition
      }
    })], 1);
  }), 0), _c(VIcon, [_vm._v("mdi-arrow-down")]), _c('div', {
    staticClass: "redirection mx-2"
  }, [_vm.destination.type === 'product' ? _c('div', [_vm._v(" Product URL ")]) : _vm.destination.type === 'brand' ? _c('div', [_vm._v(" Brand URL ")]) : _vm.destination.type === 'stc' ? _c('div', [_vm._v(" Landing page ")]) : _vm.destination.type === 'elabel' ? _c('div', [_vm._v(" e-label landing page ")]) : _vm.destination.type === 'url' ? _c('div', [_vm._v(" URL: " + _vm._s(_vm.destination.url) + " ")]) : _vm._e()]), _vm.redirectionSimulator && _vm.hasRedirectionFallback(_vm.destination) ? _c('div', [_vm._v(" Fallback ! ")]) : _vm._e()], 1) : _c('div', {
    staticClass: "d-flex align-center justify-center"
  }, [!_vm.isOnlyDestination ? _c('div', [_vm._v("If none of the above, redirect to")]) : _c('div', [_vm._v("Always redirects to")]), _c('span', {
    staticClass: "redirection mx-2"
  }, [_vm.destination.type === 'product' ? _c('div', [_vm._v(" Product URL ")]) : _vm.destination.type === 'brand' ? _c('div', [_vm._v(" Brand URL ")]) : _vm.destination.type === 'stc' ? _c('div', [_vm._v(" Landing page: "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.getLandingPage(_vm.destination.stc_config).name))])]) : _vm.destination.type === 'elabel' ? _c('div', [_vm._v(" e-label Landing page ")]) : _vm.destination.type === 'url' ? _c('div', [_vm._v(" URL: " + _vm._s(_vm.destination.url) + " ")]) : _vm._e()])]), _vm.redirectionSimulator && _vm.hasRedirectionFallback(_vm.destination) ? _c('div', {
    staticClass: "text-red"
  }, [_c('span', [_vm.destination.type === 'product' ? _c('span', [_vm._v(" Product URL is not set for this product ")]) : _vm.destination.type === 'brand' ? _c('span', [_vm._v(" Brand URL is not set for this brand. ")]) : _vm.destination.type === 'stc' ? _c('span', [_vm._v(" Landing page: "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.getLandingPage(_vm.destination.stc_config).name))])]) : _vm.destination.type === 'elabel' ? _c('span', [_vm._v(" e-label Landing page is not published ")]) : _vm.destination.type === 'url' ? _c('span', [_vm._v(" URL: " + _vm._s(_vm.destination.url) + " ")]) : _vm._e()]), _c('br'), _vm._v(" Will be redirected to: "), _vm.simulationResults.fallback_type === 'product_url' ? _c('span', [_vm._v("Product URL")]) : _vm._e(), _vm.simulationResults.fallback_type === 'brand_url' ? _c('span', [_vm._v("Brand URL")]) : _vm._e(), _vm.simulationResults.fallback_type === 'company_url' ? _c('span', [_vm._v("Company Website")]) : _vm._e(), _vm._v(" instead ")]) : _vm._e()]), _c(VDialog, {
    attrs: {
      "width": "600"
    },
    model: {
      value: _vm.deleteDialog,
      callback: function callback($$v) {
        _vm.deleteDialog = $$v;
      },
      expression: "deleteDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v("Delete destination")]), _c(VCardText, {
    staticClass: "pa-2"
  }, [_vm._v(" Do you really want to delete the destination: " + _vm._s(_vm.destination.name) + "? ")]), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteDialog = false;
      }
    }
  }, [_vm._v("cancel")]), _c(VBtn, {
    attrs: {
      "text": "",
      "loading": _vm.deleting,
      "color": "red"
    },
    on: {
      "click": _vm.deleteDestination
    }
  }, [_vm._v("confirm")])], 1)], 1)], 1), _c(VDialog, {
    attrs: {
      "width": "900",
      "scrollable": "",
      "persistent": true
    },
    model: {
      value: _vm.destinationDialog,
      callback: function callback($$v) {
        _vm.destinationDialog = $$v;
      },
      expression: "destinationDialog"
    }
  }, [_c('destination-logic', {
    attrs: {
      "rule": _vm.rule,
      "destination": _vm.destination
    },
    on: {
      "cancel": _vm.onDestinationCancel,
      "destination-updated": _vm.onDestinationUpdated
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }