<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="primary" icon @click="openDialog">
          <v-icon>$gs1-edit-icon</v-icon>
        </v-btn>
      </template>
      {{ $t('qrcode_edit_gs1_link') }}
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'code-edit-gs1-digital-link',
  props: {
    code: {
      type: Object,
      required: false
    }
  },
  methods: {
    openDialog () {
      this.$emit('open-edit-dialog', this.code)
    }
  }
}
</script>
