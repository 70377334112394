import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VDialog, {
    attrs: {
      "width": "759px"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "text-h6 grey lighten-2"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_edit_gs1_link')))]), _c(VCardText, {
    staticClass: "mt-4 black--text"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_modal_content')))]), _c(VCardText, {
    staticClass: "font-bold red--text"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_modal_sub_content')))]), _c(VCardActions, {
    staticClass: "pa-4 px-8 mt-5 pb-6"
  }, [_c(VSpacer), _c(VBtn, {
    staticClass: "blue--text mr-16",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]), _c(VBtn, {
    staticClass: "primary white--text",
    on: {
      "click": _vm.handleConfirmBtnClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('qrcode_s1_modal_understand_change')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }