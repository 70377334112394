import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VCard, {
    staticClass: "summary-card"
  }, [_c('card-label', {
    attrs: {
      "color": "0789b2",
      "content": "summary"
    }
  }), _c(VRow, {
    staticClass: "ma-0 my-12 scan-count-ctn",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c(VIcon, {
    staticClass: "scan-icon",
    attrs: {
      "size": "48px"
    }
  }, [_vm._v("$scan")]), _c('div', {
    staticClass: "pl-3"
  }, [_c('div', {
    staticClass: "scan-number font-weight-bold"
  }, [_vm._v(_vm._s(_vm.scanSummary.meta.scans.total))]), _c('div', {
    staticClass: "legend font-weight-bold"
  }, [_vm._v("Scans")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }