import de from './de.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import it from './it.json'
import nl from './nl.json'
import zh from './zh.json'

export default {
  messages: {
    en,
    fr,
    de,
    es,
    it,
    nl,
    zh
  }
}
