import { default as axios } from '../utils/axios.instances' // eslint-disable-line

const axiosInstance = axios.getSTAxios() // eslint-disable-line

export default {

  async simulateRedirection (params) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('/redirect-rules/simulate/', params).then((res) => {
        console.log('RES ->', res.data)
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async updateRulesPositions (positions) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('/redirect-rules/positions/', positions).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async updateRulesDestinationPositions (rule, positions) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/redirect-rules/${rule}/destinations/positions/`, positions).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async updateRule (rule) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/redirect-rules/${rule.id}/`, rule).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async archiveRule (ruleId) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/redirect-rules/${ruleId}/archive/`, {}).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async unarchiveRule (ruleId) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/redirect-rules/${ruleId}/unarchive/`, {}).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async switchRuleStatus (rule) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/redirect-rules/${rule.id}/`, { is_paused: !rule.is_paused }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async loadRules (filters) {
    const params = {
      ...filters,
      offset: 0,
      limit: 500
    }
    if (params.campaigns.indexOf(null) > -1) {
      delete params.campaigns
    }
    const url = new URLSearchParams(params).toString()
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/redirect-rules/?${url}`).then((res) => {
        resolve(res.data.results)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async createRule (rule) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('/redirect-rules/', rule).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async createRuleDestination (ruleId, destination) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/redirect-rules/${ruleId}/destinations/`, destination).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async deleteRuleDestination (ruleId, destinationId) {
    return new Promise((resolve, reject) => {
      axiosInstance.delete(`/redirect-rules/${ruleId}/destinations/${destinationId}`).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async updateRuleDestination (ruleId, destination) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/redirect-rules/${ruleId}/destinations/${destination.id}/`, destination).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async loadRuleDestinations (ruleId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/redirect-rules/${ruleId}/destinations/?is_archived=false`).then((res) => {
        resolve(res.data.results)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async loadRuleDestination ({ ruleId, destinationId }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/redirect-rules/${ruleId}/destinations/${destinationId}`).then((res) => {
        resolve(res.data.results)
      }).catch((err) => {
        reject(err)
      })
    })
  }

}
