var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "44",
      "height": "44",
      "viewBox": "0 0 44 44",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0 43.9997H19.9999V23.9995H0V43.9997ZM3.99968 27.9998H15.9998V39.9689H3.99968V27.9998Z",
      "fill": "#FF7F00"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.9998 31.9995H7.99988V36H11.9998V31.9995Z",
      "fill": "#FF7F00"
    }
  }), _c('path', {
    attrs: {
      "d": "M36.0001 40.0001H31.9992V44.0001H36.0001V40.0001Z",
      "fill": "#FF7F00"
    }
  }), _c('path', {
    attrs: {
      "d": "M44 40.0001H39.9992V44.0001H44V40.0001Z",
      "fill": "#FF7F00"
    }
  }), _c('path', {
    attrs: {
      "d": "M39.9997 27.9998H36V23.9995H23.9995V43.9997H27.9998V31.9995H31.9992V36H43.9997V23.9995H39.9997V27.9998Z",
      "fill": "#FF7F00"
    }
  }), _c('path', {
    attrs: {
      "d": "M0 20.0004H19.9999V0H0V20.0004ZM3.99968 3.99968H15.9998V15.9998H3.99968V3.99968Z",
      "fill": "#FF7F00"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.9998 7.99988H7.99988V12.0001H11.9998V7.99988Z",
      "fill": "#FF7F00"
    }
  }), _c('path', {
    attrs: {
      "d": "M23.9995 0V20.0004H44V0H23.9995ZM39.9997 15.9998H27.9999V3.99968H39.9997V15.9998Z",
      "fill": "#FF7F00"
    }
  }), _c('path', {
    attrs: {
      "d": "M36.0001 7.99988H31.9992V12.0001H36.0001V7.99988Z",
      "fill": "#FF7F00"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }