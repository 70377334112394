<template>
  <v-card class="sku-donut-card">
    <card-label color="00aeef" content="scan by sku"></card-label>
    <div>
      <apexchart width="100%" height="170px" type="donut" :options="options" :series="series"></apexchart>
    </div>
  </v-card>
</template>

<script>

import CardLabel from '@/components/CardLabel'
import { mapGetters } from 'vuex'

export default {
  name: 'SkuDonutChart',
  components: {
    CardLabel
  },
  computed: {
    ...mapGetters(['scans'])
  },
  watch: {
    scans () {
      this.buildProductSeries()
    }
  },
  methods: {
    buildProductSeries () {
      const products = {}
      this.scans.forEach((scan) => {
        if (!products[scan.product_sku]) {
          products[scan.product_sku] = { name: scan.product_name, count: 1 }
        } else {
          products[scan.product_sku].count++
        }
      })

      const labels = []
      const series = []

      for (const key in products) {
        labels.push(products[key].name)
        series.push(products[key].count)
      }

      this.series = series
      this.options.labels = labels
    }
  },
  data () {
    return {
      options: {
        legend: {
          show: false
        },
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          },
          selection: {
            enabled: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          pie: {
            donut: {
              size: '30%'
            }
          }
        },
        colors: ['#00aeef', '#00aeef33', '#00aeefcc', '#00aeef66', '#00aeef99'],
        labels: []
      },
      series: []
    }
  },
  created () {
    this.buildProductSeries()
  }
}

</script>

<style lang="sass" scoped>

@import "@/variables.sass"

.sku-donut-card
  padding-top: 8px

.product-ctn
  color: $dark-grey
  .number
    line-height: 28px
    font-size: 36px
  .legend
    line-height: 15px

</style>
