<template>
  <v-dialog
    v-model="dialog"
    width="759px"
  >
    <v-card>
      <v-card-title class="text-h6 grey lighten-2">{{ $t('qrcode_edit_gs1_link') }}</v-card-title>
      <v-card-text class="mt-4 black--text">{{ $t('qrcode_modal_content') }}</v-card-text>
      <v-card-text class="font-bold red--text">{{ $t('qrcode_modal_sub_content') }}</v-card-text>

      <v-card-actions class="pa-4 px-8 mt-5 pb-6">
        <v-spacer></v-spacer>
        <v-btn
          text
          class="blue--text mr-16"
          @click="closeModal"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          class="primary white--text"
          @click="handleConfirmBtnClick"
        >
          {{ $t('qrcode_s1_modal_understand_change') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'edit-confirmation-modal',
  data: () => ({}),
  props: {
    displayEditModal: {
      type: Boolean,
      required: true
    },
    selectedCode: {
      type: Object,
      required: false
    }
  },
  computed: {
    dialog: {
      get () {
        return this.displayEditModal
      },
      set (value) {
        this.$emit('update:displayEditModal', value)
      }
    }
  },
  methods: {
    ...mapMutations({
      setSelectedProductCode: 'SET_SELECTED_PRODUCT_CODE',
      setCodeEditing: 'SET_CODE_EDITING'
    }),

    closeModal () {
      this.dialog = !this.dialog
    },
    handleConfirmBtnClick () {
      this.updateProductState()
      this.dialog = !this.dialog
      this.$emit('open-dialog')
    },
    updateProductState () {
      // update selected product code only after confirm button is clicked
      this.setCodeEditing(true)
      this.setSelectedProductCode(this.selectedCode)
    }
  }

}
</script>
