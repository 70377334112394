export default {
  debounce: (callback, wait, immediate = false) => {
    let timeout = null

    return function () {
      const callNow = immediate && !timeout
      const next = () => callback.apply(this, arguments)

      clearTimeout(timeout)
      timeout = setTimeout(next, wait)
      if (callNow) {
        next()
      }
    }
  },

  formatDate: (string, short) => {
    let date
    if (!string) {
      date = new Date()
    } else {
      date = new Date(string)
    }

    const minutes = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes()
    const days = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()
    const months = (date.getMonth() + 1) <= 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1

    if (short) {
      return `${date.getFullYear()}-${months}-${days}`
    } else {
      return `${date.getFullYear()}-${months}-${days} ${date.getHours()}:${minutes}`
    }
  },

  compareObjects: (object, target) => {
    for (const p in object) {
      if (Object.prototype.hasOwnProperty.call(target, p)) {
        if (object[p] !== target[p]) {
          return false
        }
      }
    }
    return true
  },

  isObject: function (item) {
    return (item && typeof item === 'object' && !Array.isArray(item))
  },

  mergeDeep: function (target, ...sources) {
    if (!sources.length) return target
    const source = sources.shift()

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} })
          this.mergeDeep(target[key], source[key])
        } else {
          if ((source[key] && source[key].length) || typeof source[key] === 'boolean' || typeof source[key] === 'number') {
            Object.assign(target, { [key]: source[key] })
          } else {
            Object.assign(target, { [key]: target[key] })
          }
        }
      }
    }

    return this.mergeDeep(target, ...sources)
  },

  CODE_TYPE: {
    GS1: 'gs1',
    DEFAULT: 'default'
  },

  isAlphaNumeric: (value) => {
    return /^[a-zA-Z0-9]+$/.test(value)
  },

  generateLotNumber: () => {
    const regexPattern = /^[a-zA-Z0-9]+$/
    let lotNumber = ''

    while (lotNumber.length < 6) {
      const randChar = String.fromCharCode(Math.floor(Math.random() * 123))
      if (regexPattern.test(randChar)) lotNumber += randChar
    }

    return lotNumber
  },

  AI: {
    GTIN: '01',
    LOT_BATCH_NUMBER: '10',
    CPV: '22'
  },

  isGS1Identifier: (message) => {
    const regex = /\//

    return message && regex.test(message)
  },

  excludedTagRegEx: /^(scantrust_.*|cpv|lot|gtin|gs1_.*)$/

}
