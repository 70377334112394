var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "qr-quota mono"
  }, [_c('div', {
    staticClass: "d-flex align-center justify-end"
  }, [_c('div', {
    staticClass: "quota-label font-weight-bold mono"
  }, [_vm._v(" " + _vm._s(_vm.$t('qr_quota')) + " ")])]), _c('div', {
    staticClass: "font-weight-medium"
  }, [_c('span', {
    staticClass: "activated"
  }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")(_vm.totalActiveCodesCount)) + " ")]), _vm._v(" / "), _c('span', [_vm._v(" " + _vm._s(_vm._f("formatNumber")(_vm.totalActiveCodesCount + _vm.totalInactiveCodesCount)) + " " + _vm._s(_vm.$t('total')) + ": ")])]), _vm.totalBlacklistedAssignedCodesCount ? _c('div', {
    staticClass: "inactive-ctn"
  }, [_vm._v(" " + _vm._s(_vm.totalBlacklistedAssignedCodesCount) + " " + _vm._s(_vm.$t('blacklisted')) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }