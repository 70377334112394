import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VAppBar, {
    attrs: {
      "absolute": "",
      "color": "#ffffff"
    }
  }, [_c(VRow, {
    attrs: {
      "align": "stretch",
      "justify": "space-between"
    }
  }, [_c(VImg, {
    staticClass: "ml-5",
    attrs: {
      "src": require('../assets/st-logo.svg'),
      "max-width": "170px",
      "contain": ""
    }
  }), _vm.currentPlan ? _c(VRow, {
    staticClass: "justify-end align-center ma-0"
  }, [_c('nav', [_c('ul', {
    staticClass: "nav-list font-weight-medium"
  }, [_c('li', {
    staticClass: "nav-item",
    class: {
      'active': this.active == 'DASHBOARD'
    },
    on: {
      "click": function click($event) {
        return _vm.sendNavigationEvent('dashboard');
      }
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('navigation_dashboard')) + " ")])], 1), _c('li', {
    staticClass: "nav-item",
    class: {
      'active': this.active == 'PRODUCTS'
    },
    on: {
      "click": function click($event) {
        return _vm.sendNavigationEvent('products');
      }
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/products"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('navigation_products')) + " ")])], 1), _c('li', {
    staticClass: "nav-item",
    class: {
      'active': this.active == 'LANDING-PAGE'
    },
    on: {
      "click": function click($event) {
        return _vm.sendNavigationEvent('landing-page');
      }
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/landing-page"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('navigation_landing_page')) + " ")])], 1), _c('li', {
    staticClass: "nav-item",
    class: {
      'active': this.active == 'UPGRADE'
    },
    on: {
      "click": function click($event) {
        return _vm.sendNavigationEvent('upgrade');
      }
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/upgrade"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('navigation_upgrade')) + " ")])], 1)])]), _c(VMenu, {
    attrs: {
      "full-width": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', {
          staticClass: "account-icon pr-2",
          class: {
            'pr-5': _vm.currentPlan.id == 'epac-connect-premium'
          }
        }, [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, [_vm._v("mdi-account-circle")])], 1), _c('div', {
          staticClass: "plan-pill"
        }, [_vm.currentPlan.id == 'epac-connect' ? _c(VImg, {
          attrs: {
            "src": require('../assets/free.svg')
          }
        }) : _vm._e(), _vm.currentPlan.id == 'epac-connect-plus' ? _c(VImg, {
          attrs: {
            "src": require('../assets/plus.svg')
          }
        }) : _vm._e(), _vm.currentPlan.id == 'epac-connect-premium' ? _c(VImg, {
          attrs: {
            "src": require('../assets/premium.svg')
          }
        }) : _vm._e()], 1)], 1)];
      }
    }], null, false, 3084360993)
  }, [_c(VList, [_c(VListItem, {
    staticClass: "menu-link"
  }, [_c('router-link', {
    attrs: {
      "to": "/upgrade"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold mr-5 navigation-menu-label uppercase"
  }, [_vm._v(_vm._s(_vm.$t('current_plan')) + ":")]), _c('br'), _c(VRow, {
    staticClass: "ma-0",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c(VCol, {
    staticClass: "flex-grow-0 pa-0 mr-3"
  }, [_vm.currentPlan.id == 'epac-connect' ? _c(VImg, {
    staticClass: "pill",
    attrs: {
      "src": require('../assets/free.svg')
    }
  }) : _vm._e(), _vm.currentPlan.id == 'epac-connect-plus' ? _c(VImg, {
    staticClass: "pill",
    attrs: {
      "src": require('../assets/plus.svg')
    }
  }) : _vm._e(), _vm.currentPlan.id == 'epac-connect-premium' ? _c(VImg, {
    staticClass: "pill",
    attrs: {
      "src": require('../assets/premium.svg')
    }
  }) : _vm._e()], 1), _c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" " + _vm._s(_vm.planName) + " ")])], 1)], 1)], 1), _c(VListItem, {
    on: {
      "click": _vm.logout
    }
  }, [_c(VIcon, {
    staticClass: "mr-3"
  }, [_vm._v("mdi-logout")]), _vm._v(" " + _vm._s(_vm.$t('logout')) + " ")], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }