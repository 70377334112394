// import * as types from '../mutations'
import ELabelService from '@/services/elabel'

const state = {
}

const getters = {
}

const actions = {
  async loadElabel ({ commit }, { id, sku }) {
    try {
      const elabel = await ELabelService.loadElabel({ id })
      elabel.product = id
      elabel.sku = sku
      return elabel
    } catch (err) {
      return err
    }
  },

  async updateElabel ({ commit }, { id, sku, json, publish }) {
    try {
      const elabel = await ELabelService.updateELabel({ id: id, name: sku, data: json, is_published: publish })
      return elabel
    } catch (err) {
      return (err)
    }
  }
}

const mutations = {

}

export default {
  state,
  getters,
  actions,
  mutations
}
