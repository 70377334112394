var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M38.8572 30.6892L33.6892 34.1071C33.0355 32.9886 31.8058 32.2438 30.4354 32.2438H25.8717C24.8892 32.2438 24.4288 31.5304 23.9678 31.199C20.4401 28.1404 15.1198 28.1404 11.5939 31.1971L10.5974 32.0563H0.9375C0.419678 32.0563 0 32.4759 0 32.9938V44.2434C0 44.7612 0.419678 45.1809 0.9375 45.1809H10.6707L27.4688 47.9806C27.7163 48.022 27.9708 47.9623 28.1748 47.8136L47.6147 33.6493C48.0315 33.3453 48.1245 32.7616 47.8228 32.343C45.7646 29.4895 41.8293 28.7611 38.8572 30.6892ZM27.3889 46.0664L10.9025 43.3187C10.8516 43.3103 10.7999 43.3059 10.7483 43.3059H1.875V33.9313H10.946C11.5148 33.9313 11.6766 33.5277 12.8203 32.6155C15.6475 30.164 19.912 30.164 22.7395 32.6158C23.1332 32.881 24.0542 34.1188 25.8717 34.1188H30.4354C31.4531 34.1188 32.3104 34.9244 32.3104 35.9421C32.3104 36.9529 31.4692 37.775 30.4354 37.775H19.1854C18.668 37.775 18.2479 38.1947 18.2479 38.7125C18.2479 39.23 18.668 39.65 19.1854 39.65H30.4354C32.4741 39.65 34.1385 38.0325 34.1843 36.0271L39.8844 32.2577C41.7411 31.0532 44.1259 31.2967 45.6815 32.7378L27.3889 46.0664Z",
      "fill": "#020202"
    }
  }), _c('path', {
    attrs: {
      "d": "M30.562 18.3691C30.562 18.887 30.1423 19.3066 29.6245 19.3066C29.1071 19.3066 28.687 18.887 28.687 18.3691C28.687 17.8513 29.1071 17.4316 29.6245 17.4316C30.1423 17.4316 30.562 17.8513 30.562 18.3691Z",
      "fill": "#020202"
    }
  }), _c('path', {
    attrs: {
      "d": "M18.2813 23.0563H40.781C41.2988 23.0563 41.7185 22.6366 41.7185 22.1191V18.46L44.5577 3.92981C44.6302 3.55847 44.4724 3.17981 44.1574 2.96997C43.8425 2.76013 43.4323 2.76013 43.1174 2.96997L35.4599 8.07495L30.3553 0.41748C30.1814 0.156738 29.8888 0 29.5753 0C29.2618 0 28.9692 0.156738 28.7953 0.41748L23.6903 8.07458L16.0332 2.96997C15.719 2.7605 15.3103 2.75977 14.9953 2.96814C14.6808 3.17651 14.5218 3.55334 14.5921 3.92432L17.3438 18.457V22.1191C17.3438 22.6366 17.7635 23.0563 18.2813 23.0563ZM23.4303 10.155C23.8613 10.4421 24.4432 10.3257 24.7303 9.89502L29.5753 2.62756L34.4203 9.89502C34.7074 10.3257 35.2893 10.4421 35.7199 10.155L42.2861 5.77771L40.009 17.4316H33.8435C33.3257 17.4316 32.906 17.8513 32.906 18.3691C32.906 18.887 33.3257 19.3066 33.8435 19.3066H39.8435V21.1816H19.2188V19.3066H25.4063C25.9242 19.3066 26.3438 18.887 26.3438 18.3691C26.3438 17.8513 25.9242 17.4316 25.4063 17.4316H19.0581L16.8491 5.76746L23.4303 10.155Z",
      "fill": "#020202"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }