<template>
  <v-card scrollable>
    <v-card-title class="grey lighten-2">
      {{ rule.id ? 'Edit redirection rule' : 'Create a Redirection rule' }}
      <!-- or edit -->
    </v-card-title>

    <v-card-text class="pa-0 ma-0">

      <div v-if="rule.id" class="pa-3">
        <v-form v-model="valid" ref="edit-rule-form">
          <!-- Rule Name -->
          <div class="my-3">
            <div class="section-title">Rule name:</div>
            <v-text-field counter maxlength="50" v-model="form.name" :rules="rules.name" required dense outlined></v-text-field>
          </div>

          <!-- Rule Campaigns -->
          <div class="mt-3 mb-5">
            <div class="section-title">Active for campaigns:</div>
            <v-autocomplete
              v-model="form.campaigns"
              chips
              multiple
              deletable-chips
              :items="campaignsInSelect"
              :search-input.sync="searchCampaign"
              item-text="name"
              item-value="id"
              dense
              hide-details
              outlined
              @change="searchCampaign=''"
            ></v-autocomplete>
          </div>

          <v-divider></v-divider>

          <!-- Rule Calendar -->
          <div class="my-3">
            <div class="section-title">Active Period:</div>

            <v-radio-group class="pl-2 mt-1" v-model="activePeriod">
              <v-radio :key="'ALWAYS_ON'" :value="'ALWAYS_ON'" label="Active all the time"></v-radio>
              <v-radio :key="'HAS_TIMEFRAME'" :value="'HAS_TIMEFRAME'" label="Active during the specified time frame"></v-radio>
            </v-radio-group>
            <div v-if="activePeriod !== 'ALWAYS_ON'" class="d-flex align-center my-3">
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.start"
                    readonly
                    label="Start Date"
                    hide-details
                    dense
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    append-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date.start"
                  no-title
                  scrollable
                  :min="new Date().toISOString().slice(0,10)"
                  @input="startDateMenu = false"
                >
                </v-date-picker>
              </v-menu>
              <!-- AT -->
              <div class="mx-2">at</div>
              <v-menu
                ref="startTimeMenu"
                v-model="startTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time.start"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time.start"
                    placeholder="00:00"
                    readonly
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    append-icon="mdi-clock"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="startTimeMenu"
                  v-model="time.start"
                  full-width
                  @click:minute="$refs.startTimeMenu.save(time.start)"
                ></v-time-picker>
              </v-menu>

              <!-- TO -->
              <div class="mx-2">to</div>
              <v-menu
                ref="endDateMenu"
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.end"
                    label="End Date"
                    readonly
                    hide-details
                    dense
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    append-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date.end"
                  no-title
                  scrollable
                  :min="date.start"
                  @input="endDateMenu = false"
                >
                </v-date-picker>
              </v-menu>
              <div class="mx-2">at</div>
              <v-menu
                ref="endTimeMenu"
                v-model="endTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time.end"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time.end"
                    placeholder="00:00"
                    readonly
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    append-icon="mdi-clock"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="endTimeMenu"
                  v-model="time.end"
                  full-width
                  @click:minute="$refs.endTimeMenu.save(time.end)"
                ></v-time-picker>
              </v-menu>
            </div>
            <div v-if="activePeriod !== 'ALWAYS_ON'" class="d-flex pt-3 align-start">
              <div class="section-title mr-5 pt-2">Select timezone:</div>
              <v-autocomplete
                v-model="form.timezone"
                :items="timezones"
                :search-input.sync="searchTimezone"
                item-text="label"
                item-value="value"
                dense
                outlined
                persistent-placeholder
                placeholder="Timezone"
                @change="searchTimezone=''"
                :rules="rules.timezone"
                required
              ></v-autocomplete>
            </div>
          </div>
          <v-divider></v-divider>
          <div>
            <v-switch hide-details label="Enable Appclip/Instant app" v-model="form.app_clip_enabled"></v-switch>
          </div>
        </v-form>
      </div>
      <v-stepper v-else class="no-shadow" v-model="step">
        <v-stepper-header>
          <v-stepper-step step="1">Rule setup</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2">Destination</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form v-model="valid" ref="rule-form">
              <!-- Rule Name -->
              <div class="my-3">
                <div class="section-title">Rule name:</div>
                <v-text-field counter maxlength="50" v-model="form.name" :rules="rules.name" required dense outlined></v-text-field>
              </div>

              <!-- Rule Campaigns -->
              <div class="mt-3 mb-5">
                <div class="section-title">Add or remove campaigns:</div>
                <v-autocomplete
                  v-model="form.campaigns"
                  chips
                  multiple
                  deletable-chips
                  :items="campaignsInSelect"
                  :search-input.sync="searchCampaign"
                  item-text="name"
                  item-value="id"
                  dense
                  hide-details
                  outlined
                  @change="searchCampaign=''"
                ></v-autocomplete>
              </div>

              <v-divider></v-divider>

              <!-- Rule Calendar -->
              <div class="my-3">
                <div class="section-title">Active Period:</div>

                <v-radio-group class="pl-2 mt-1" v-model="activePeriod">
                  <v-radio :key="'ALWAYS_ON'" :value="'ALWAYS_ON'" label="Active all the time"></v-radio>
                  <v-radio :key="'HAS_TIMEFRAME'" :value="'HAS_TIMEFRAME'" label="Active during the specified time frame"></v-radio>
                </v-radio-group>
                <div v-if="activePeriod !== 'ALWAYS_ON'" class="d-flex align-center my-3">
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date.start"
                        readonly
                        label="Start Date"
                        hide-details
                        dense
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        append-icon="mdi-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date.start"
                      no-title
                      scrollable
                      :min="new Date().toISOString().slice(0,10)"
                      @input="startDateMenu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                  <!-- AT -->
                  <div class="mx-2">at</div>
                  <v-menu
                    ref="startTimeMenu"
                    v-model="startTimeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time.start"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="time.start"
                        placeholder="00:00"
                        readonly
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        append-icon="mdi-clock"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="startTimeMenu"
                      v-model="time.start"
                      full-width
                      @click:minute="$refs.startTimeMenu.save(time.start)"
                    ></v-time-picker>
                  </v-menu>

                  <!-- TO -->
                  <div class="mx-2">to</div>
                  <v-menu
                    ref="endDateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date.end"
                        label="End Date"
                        readonly
                        hide-details
                        dense
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        append-icon="mdi-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date.end"
                      no-title
                      scrollable
                      :min="date.start"
                      @input="endDateMenu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                  <div class="mx-2">at</div>
                  <v-menu
                    ref="endTimeMenu"
                    v-model="endTimeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time.end"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="time.end"
                        placeholder="00:00"
                        readonly
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        append-icon="mdi-clock"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="endTimeMenu"
                      v-model="time.end"
                      full-width
                      @click:minute="$refs.endTimeMenu.save(time.end)"
                    ></v-time-picker>
                  </v-menu>
                </div>
                <div v-if="activePeriod !== 'ALWAYS_ON'" class="d-flex pt-3 align-start">
                  <div class="section-title mr-5 pt-2">Select timezone:</div>
                  <v-autocomplete
                    v-model="form.timezone"
                    :items="timezones"
                    :search-input.sync="searchTimezone"
                    item-text="label"
                    item-value="value"
                    :rules="rules.timezone"
                    dense
                    outlined
                    persistent-placeholder
                    placeholder="Timezone"
                    @change="searchTimezone=''"
                    required
                  ></v-autocomplete>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="pa-3">
                <v-switch hide-details label="Enable App clip/Instant app" v-model="form.app_clip_enabled"></v-switch>
              </div>
            </v-form>
          </v-stepper-content>
          <v-stepper-content :class="{ 'pa-0': addingDestination}" step="2">
            <!-- Rule Destinations -->
            <!-- v-if="rule.id && mode === 'preview' " -->
            <div v-if="!addingDestination" class="destinations-ctn my-3">
              <div v-if="destinations.length" class="section-title">Destinations:</div>
              <div class="call-to-action" v-if="!destinations?.length">
                <div class="title">Action Required:</div>
                <div class="description">
                  A functioning rule must have at least 1 scan destination.<br>You can add multiple destinations here for the scans that meet with different conditions.
                </div>
                <v-btn @click="newDestination()" color="primary" class="my-2" text large><v-icon class="mr-2" color="primary">mdi-plus-circle-outline</v-icon>Add Destination</v-btn>
              </div>
              <div v-else class="destination-list">
                <draggable class="drop-area" v-model="destinations" group="a">
                  <div v-for="destination in destinations" :key="destination.id">
                    <destination-card class="my-4" :rule="rule" :destination="destination" @destination-updated="onDestinationUpdated" :is-only-destination="destinations && destinations.length == 1"></destination-card>
                  </div>
                </draggable>
                <v-btn @click="newDestination()" color="primary" class="my-4" text large><v-icon class="mr-2" color="primary">mdi-plus-circle-outline</v-icon>Add Destination</v-btn>
              </div>
            </div>
            <div v-else>
              <destination-logic @cancel="onDestinationCancel" @destination-created="onDestinationCreated" @destination-updated="onDestinationUpdated" :rule="isNewRule ? newRule : rule" :destination="destination" hide-header="true"></destination-logic>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions v-if="!addingDestination">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="close"
      >
        close without publishing
      </v-btn>
      <v-btn
        color="primary"
        text
        :disabled="loading"
        @click="save()"
        v-if="step === 1"
      >
        {{ rule.id ? 'save' : 'create' }}
      </v-btn>
      <v-btn
        color="primary"
        raised
        :disabled="publishing"
        @click="publish()"
        v-if="step === 2"
      >
        Publish
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import { mapGetters } from 'vuex'
import Timezones from '@/utils/timezones'
import Utils from '@/utils/utils'
import DestinationCard from '@/components/redirection/DestinationCard'
import DestinationLogic from '@/components/redirection/DestinationLogic'
import draggable from 'vuedraggable'
import { DateTime } from 'luxon'

export default {
  name: 'RuleForm',
  props: ['rule'],
  components: {
    DestinationLogic,
    DestinationCard,
    draggable
  },
  computed: {
    ...mapGetters(['redirectionVariables', 'campaigns', 'redirectionRules']),
    campaignsInSelect () {
      const array = this.campaigns
      if (this.campaigns.length && this.campaigns[0].id) {
        array.unshift({ name: 'All Campaigns', id: null })
      }
      return array
    },
    destinations: {
      get () {
        if (this.rule.id) {
          return this.$store.getters.redirectionRuleDestinations(this.rule.id)
        } else if (this.newRule.id) {
          console.log('NEW RULE ID', this.newRule.id, this.newRule, this.rule)
          return this.$store.getters.redirectionRuleDestinations(this.newRule.id)
        }
        return []
      },
      set (destinations) {
        this.$store.dispatch('updateRulesDestinationPositions', { ruleId: this.rule.id ? this.rule.id : this.newRule.id, destinations })
      }
    }
  },
  watch: {

    rule: {
      handler (newVal) {
        if (newVal && newVal.id !== this.form.id) {
          this.step = 1
          this.form = {
            ...newVal
          }
        }
        if (newVal.active_from) {
          console.log('copying active from', newVal)
          this.date.start = DateTime.fromISO(newVal.active_from, { zone: this.form.timezone }).toISODate()
          this.time.start = DateTime.fromISO(newVal.active_from, { zone: this.form.timezone }).toLocaleString(DateTime.TIME_24_SIMPLE)
          console.log(this.time, this.date)
        }
        if (newVal.active_to) {
          this.date.end = DateTime.fromISO(newVal.active_to, { zone: this.form.timezone }).toISODate()
          this.time.end = DateTime.fromISO(newVal.active_to, { zone: this.form.timezone }).toLocaleString(DateTime.TIME_24_SIMPLE)
        }
        this.activePeriod = newVal.active_from || newVal.active_to ? 'HAS_TIMEFRAME' : 'ALWAYS_ON'
        if (!newVal.id) {
          this.date.start = null
          this.date.end = null
          this.time.start = null
          this.time.end = null
          this.hasTimeframe = false
          this.isNewRule = true
          this.newRule = {}
        } else {
          this.isNewRule = false
          this.newRule = {}
        }
      },
      deep: true
    },

    activePeriod (newVal) {
      if (newVal && newVal === 'ALWAYS_ON') {
        this.form.active_from = null
        this.form.active_to = null
        this.date.start = null
        this.date.end = null
        this.time.start = null
        this.time.end = null
        this.hasTimeframe = false
      }
    },

    'form.campaigns' (newVal, oldVal) {
      this.searchCampaign = ''
      if (newVal === null || newVal === undefined) {
        return
      }
      const allIndex = newVal.indexOf(null)
      if (!newVal.length || (~allIndex && newVal.length > 1 && !~oldVal.indexOf(null))) {
        this.form.campaigns = [null]
      } else if (~allIndex && newVal.length > 1) {
        this.form.campaigns.splice(allIndex, 1)
      }
    },

    'form.timezone' (newVal) {
      if (newVal === null) {
        this.form.timezone = ''
      }
    }
  },
  data () {
    return {
      step: 1,
      valid: false,
      activePeriod: 'ALWAYS_ON',
      status: '',
      loading: false,
      searchCampaign: '',
      searchTimezone: '',
      selectedCampaigns: [],
      startTimeMenu: false,
      startDateMenu: false,
      endTimeMenu: false,
      endDateMenu: false,
      timezones: Timezones,
      addingDestination: false,
      publishing: false,
      isNewRule: false,
      newRule: {},
      destination: {},
      form: {
        active_from: null,
        active_to: null,
        timezone: null
      },
      time: {
        start: null,
        end: null
      },
      date: {
        start: null,
        end: null
      },
      rules: {
        name: [
          v => !!v || 'A rule name is required',
          v => (v && v.length <= 50) || 'Max 50 characters'
        ],
        timezone: [
          v => !!v || 'This field is required'
        ]
      }
    }
  },
  methods: {

    onDestinationCreated (destination) {
      this.addingDestination = false
      // this.destinations.push(destination)
    },

    onDestinationUpdated (destination) {
      this.addingDestination = false
      const index = this.destinations.findIndex((dest) => {
        return dest.id === destination.id
      })

      if (~index) {
        this.destinations[index] = destination
      }
    },

    onDestinationCancel () {
      this.addingDestination = false
    },

    async publish () {
      this.publishing = true
      await this.$store.dispatch('toggleRedirectionRule', this.isNewRule ? this.newRule : this.rule)
      this.publishing = false
      this.close()
    },

    getCampaign (id) {
      return this.campaigns.find((c) => c.id === id)
    },

    getTimezone (id) {
      return this.timezones.find((t) => t.value === id) || { label: 'No timezone' }
    },

    getRuleStatus (rule) {
      const now = new Date()
      if (rule.is_paused) {
        return 'paused'
      } else if (rule.active_to) {
        const end = new Date(rule.active_to)
        if (now.getTime() > end.getTime()) {
          return 'expired'
        } else if (rule.active_from) {
          const start = new Date(rule.active_from)
          if (now.getTime() > start.getTime()) {
            return 'expiring'
          } else {
            return 'planned'
          }
        }
      } else {
        return 'live'
      }
    },

    formatDate (date) {
      return Utils.formatDate(date, false)
    },

    newDestination () {
      this.addingDestination = true
      // this.$router.push({ name: 'destination.new', params: { id: this.rule.id, rule: this.rule } })
    },

    showLocalTime (date) {
      const dateTime = DateTime.fromISO(date, { zone: this.form.timezone })
      return dateTime.toLocaleString(DateTime.DATETIME_SHORT)
    },

    convertToTimezone (date, time) {
      const d = new Date(date)
      const t = time.split(':')
      d.setHours(t[0], t[1])

      const dateTime = DateTime.fromISO(date, { zone: this.form.timezone }).set({ hour: t[0] }).set({ minutes: t[1] })
      return dateTime.toString()
    },

    async save () {
      if (this.form.id) {
        this.$refs['edit-rule-form'].validate()
      } else {
        this.$refs['rule-form'].validate()
      }
      if (!this.valid) {
        return
      }

      const form = JSON.parse(JSON.stringify(this.form))
      if (this.date.start) {
        form.active_from = this.convertToTimezone(this.date.start, this.time.start)
      }
      if (this.date.end) {
        form.active_to = this.convertToTimezone(this.date.end, this.time.end)
      }

      if (!form.campaigns) {
        form.campaigns = []
      }

      const includesAllCampaign = form.campaigns.findIndex((campaign) => {
        return campaign === null
      })
      if (!form.campaigns || (form.campaigns.length && includesAllCampaign > -1)) {
        form.campaigns = []
      }

      if (!this.form.id) {
        form.position = this.redirectionRules.length
      }

      this.loading = true
      if (this.rule.id) {
        try {
          const rule = await this.$store.dispatch('updateRedirectionRule', { form: form, destinations: this.rule.destinations })
          this.loading = false
          this.$emit('on-rule-created', rule)
        } catch (err) {
          this.loading = false
        }
      } else {
        try {
          const rule = await this.$store.dispatch('createRedirectionRule', form)
          this.newRule = JSON.parse(JSON.stringify(rule))
          this.step = 2
          this.loading = false
        } catch (err) {
          this.loading = false
        }
        // this.$emit('on-rule-created', rule)
      }
    },

    async loadDestinations () {
      await this.$store.dispatch('loadRuleDestinations', { ruleId: this.rule.id })
      this.$forceUpdate()
    },

    close () {
      this.step = 1
      this.publishing = false
      this.loading = false
      this.form = {
        active_from: null,
        active_to: null,
        campaigns: [],
        name: '',
        app_clip_enabled: false
      }
      this.$emit('on-close-dialog')
    }

  },
  created () {
    if (this.rule) {
      this.form = JSON.parse(JSON.stringify(this.rule))
      console.log('FORM', this.form)
      if (this.rule.active_from) {
        this.date.start = DateTime.fromISO(this.rule.active_from, { zone: this.form.timezone }).toISODate()
        this.time.start = DateTime.fromISO(this.rule.active_from, { zone: this.form.timezone }).toLocaleString(DateTime.TIME_24_SIMPLE)
      }
      if (this.rule.active_to) {
        this.date.end = DateTime.fromISO(this.rule.active_to, { zone: this.form.timezone }).toISODate()
        this.time.end = DateTime.fromISO(this.rule.active_to, { zone: this.form.timezone }).toLocaleString(DateTime.TIME_24_SIMPLE)
      }
      this.status = this.getRuleStatus(this.form)
      console.log('start / end', this.date)
      this.activePeriod = this.date.start || this.date.end ? 'HAS_TIMEFRAME' : 'ALWAYS_ON'
    }
    console.log('RULE ID', this.rule.id)
    if (this.rule.id) {
      this.loadDestinations()
    } else {
      this.isNewRule = true
    }
  }
}

</script>

<style lang="sass" scoped>

.no-shadow
  box-shadow: none

.destinations-ctn
  color: black
  .call-to-action
    width: 400px
    text-align: center
    margin-left: auto
    margin-right: auto
  .destination-list
    text-align: center

</style>
