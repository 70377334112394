<template>
  <v-card class="product-card">
    <card-label color="555555" content="products"></card-label>
    <v-row justify="center" align="center" class="ma-0 my-12 product-ctn">
      <v-icon size="48px" class="scan-icon">$product</v-icon>
      <div class="pl-3">
        <div class="number font-weight-bold">{{productCount}}</div>
        <div class="legend font-weight-bold">Products Scanned</div>
      </div>
    </v-row>
  </v-card>
</template>

<script>

import CardLabel from '@/components/CardLabel'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductCard',
  computed: {
    ...mapGetters(['scans']),

    productCount () {
      const products = []
      this.scans.forEach((scan) => {
        if (!~products.indexOf(scan.product_sku)) {
          products.push(scan.product_sku)
        }
      })
      return products.length
    }
  },
  components: {
    CardLabel
  }
}

</script>

<style lang="sass" scoped>

@import "@/variables.sass"

.product-card
  padding-top: 8px

.product-ctn
  color: $dark-grey
  .number
    line-height: 28px
    font-size: 36px
  .legend
    line-height: 15px

</style>
