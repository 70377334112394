<template>
  <v-card height="300px" class="scan-by-time-card">
    <card-label color="00aeef" content="scan by hour"></card-label>
    <apexchart width="100%" height="270px" type="heatmap" :options="options" :series="series"></apexchart>
  </v-card>
</template>

<script>

import CardLabel from '@/components/CardLabel'
import { mapGetters } from 'vuex'

export default {
  name: 'ScanByTimeCard',
  components: {
    CardLabel
  },
  computed: {
    ...mapGetters(['scans'])
  },
  watch: {
    scans () {
      this.series = this.buildSeriesArray()
      this.fillTimeHeatmap()
    }
  },
  methods: {

    getDayName (index) {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      return days[index]
    },

    buildSeriesArray () {
      const days = [0, 1, 2, 3, 4, 5, 6]
      const dayIndex = new Date().getDay()
      const orderedDays = days.splice(dayIndex + 1).concat(days.splice(0, dayIndex + 1))
      const seriesData = []

      orderedDays.forEach((day) => {
        const data = []
        for (let hour = 0; hour <= 23; hour++) {
          data.push({ x: `${hour}`, y: 0 })
        }
        seriesData.push({ index: day, name: this.getDayName(day), data: data })
      })

      return seriesData
    },

    getSeriesIndex (day) {
      return this.series.findIndex((d) => {
        return day === d.index
      })
    },

    fillTimeHeatmap () {
      this.scans.forEach((scan) => {
        const scanTime = new Date(scan.scan_timestamp)
        const seriesIndex = this.getSeriesIndex(scanTime.getDay())

        this.series[seriesIndex].data[scanTime.getHours()].y++
      })
    }
  },
  data () {
    return {
      options: {
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          },
          selection: {
            enabled: false
          },
          zoom: {
            enabled: false
          },
          dataLabels: {
            enabled: false
          }
        },
        legend: {
          show: false
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [{
                from: 0,
                to: 0,
                color: '#cccccc'
              }]
            }
          }
        },
        colors: ['#00aeef']
      },
      series: []
    }
  },
  created () {
    this.series = this.buildSeriesArray()
    this.fillTimeHeatmap()
  }
}

</script>

<style lang="sass" scoped>

@import "@/variables.sass"

.scan-by-time-card
  padding-top: 8px

.product-ctn
  color: $dark-grey
  .number
    line-height: 28px
    font-size: 36px
  .legend
    line-height: 15px

</style>
