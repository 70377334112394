import { default as axios } from '../utils/axios.instances' // eslint-disable-line

const axiosInstance = axios.getSTAxios() // eslint-disable-line

export default {

  async loadProducts (options) {
    const params = {
      limit: options.limit ? options.limit : 20,
      offset: options.offset ? options.offset : 0,
      is_archived: false,
      ordering: '-creation_date'
    }

    if (options.campaign) {
      params.campaign = options.campaign
    }

    if (options.sku) {
      params.sku__exact = options.sku
    }

    if (options.search) {
      params.search = options.search
    }

    return new Promise((resolve, reject) => {
      axiosInstance.get('/products/', { params }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  b64toFile (dataURI) {
    // convert the data URL to a byte string
    const byteString = atob(dataURI.split(',')[1])

    // pull out the mime type from the data URL
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // Convert to byte array
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    // Create a blob that looks like a file.
    const blob = new Blob([ab], { type: mimeString })
    blob.lastModifiedDate = (new Date()).toISOString()
    blob.name = 'file'

    // Figure out what extension the file should have
    switch (blob.type) {
      case 'image/jpeg':
        blob.name += '.jpg'
        break
      case 'image/png':
        blob.name += '.png'
        break
    }

    return blob
  },

  async updateProduct (product) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()

      if (product.image && typeof product.image === 'string' && product.image.indexOf('data:') === 0) {
        const fileObj = this.b64toFile(product.image)
        const extension = fileObj.type.substring(fileObj.type.indexOf('/') + 1)
        formData.append('image', fileObj, `blob.${extension}`)
      }

      formData.append('name', product.name)
      formData.append('sku', product.sku)

      if (product.client_url) {
        formData.append('client_url', product.client_url)
      }

      if (typeof product.campaign === 'number') {
        formData.append('campaign', product.campaign)
      }

      if (product.description) {
        formData.append('description', product.description)
      }

      if (product.brand) {
        if (typeof product.brand === 'object') {
          product.brand = product.brand.id
        }
        formData.append('brand', product.brand)
      }

      axiosInstance.patch(`/products/${product.id}/`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async loadProduct (id) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/products/${id}/`).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async createProduct (product) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()

      if (typeof product.image === 'string' && product.image.indexOf('data:') === 0) {
        const fileObj = this.b64toFile(product.image)
        const extension = fileObj.type.substring(fileObj.type.indexOf('/') + 1)
        formData.append('image', fileObj, `blob.${extension}`)
      }

      formData.append('name', product.name)
      formData.append('sku', product.sku)

      if (product.client_url) {
        formData.append('client_url', product.client_url)
      }

      if (typeof product.campaign === 'number') {
        formData.append('campaign', product.campaign)
      }

      if (product.description) {
        formData.append('description', product.description)
      }

      if (product.brand) {
        formData.append('brand', product.brand)
      }

      axiosInstance.post('/products/', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

}
