import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VTextField, {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.mask,
      expression: "mask"
    }],
    staticClass: "color-input ma-0 pa-0",
    attrs: {
      "value": _vm.color,
      "hide-details": "",
      "solo": ""
    },
    on: {
      "input": _vm.debounceUpdate
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c(VMenu, {
          attrs: {
            "top": "",
            "nudge-bottom": "105",
            "nudge-left": "16",
            "close-on-content-click": false
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('div', _vm._g({
                style: _vm.swatchStyle
              }, on))];
            }
          }]),
          model: {
            value: _vm.menu,
            callback: function callback($$v) {
              _vm.menu = $$v;
            },
            expression: "menu"
          }
        }, [_c(VCard, [_c(VCardText, {
          staticClass: "pa-0"
        }, [_c(VColorPicker, {
          staticClass: "no-alpha",
          attrs: {
            "value": _vm.color,
            "flat": ""
          },
          on: {
            "input": _vm.debounceUpdate
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }