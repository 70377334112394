<template>
  <div class="product-codes">
    <div class="d-flex align-center pt-5">
      <router-link to="/products">
        <v-btn class="ml-3" icon><v-icon color="black">mdi-arrow-left</v-icon></v-btn>
      </router-link>
      <div class="page-title mono pl-3">{{$t('product_qr_codes')}}</div>
      <v-spacer></v-spacer>
      <codes-quota class="mr-8"></codes-quota>
    </div>

    <div class="product-card my-4 mx-8" v-if="!loadingProduct">
      <v-row class="ma-0">
        <v-col class="product-picture flex-grow-0 pa-0" :style="{'background-image': 'url(' + getProductImage(product) + ')'}"></v-col>
        <v-col class="flex-grow-1 pa-0 pb-3 pl-3">
          <div class="product-title font-weight-medium">{{product.name}}</div>
          <div class="product-description">{{$t('product_sku')}}: {{product.sku}}</div>
          <div class="product-description">{{$t('product_description')}}: {{product.description || 'N/A'}}</div>
          <div class="product-description">
            {{$t('product_qr_links_to')}}:
            <span class="redirect-setting font-weight-bold" v-if="campaignRedirectionMode === 'url'">{{$t('url')}} <a :href="consumerOptions.consumer_url" target="_blank">{{consumerOptions.consumer_url}}</a></span>
            <span class="redirect-setting font-weight-bold" v-if="campaignRedirectionMode === 'stc'">{{$t('landing_page')}}</span>
            <span class="redirect-setting font-weight-bold" v-if="campaignRedirectionMode === 'elabel'">{{$t('elabel_landing_page')}}</span>
            <span class="redirect-setting font-weight-bold" v-if="campaignRedirectionMode === 'product'">{{$t('product_url')}} <a target="_blank" :href="product.client_url">{{product.client_url}}</a></span>
          </div>
          <div class="product-description">{{$t('product_update_redirection_settings')}}, <span @click="goToRedirectionSettings" class="link">{{$t('click_here')}}</span>.</div>
        </v-col>
        <div class="d-flex align-center ml-auto pr-9">
          <v-icon color="black" class="mr-2">mdi-qrcode</v-icon>
          <div class="code-count font-weight-light" v-if="codesLoaded">x {{filteredCodeCount}}</div>
          <v-btn class="ml-6" :disabled="!inactiveCodesLoaded" @click="handleDisplayQrCodeDialog(true)" :loading="isCreatingCode" raised color="primary"><v-icon class="mr-2">mdi-plus</v-icon> {{$t('product_new_qr_code')}}</v-btn>
        </div>
      </v-row>
    </div>
    <div v-if="loadingProduct" class="skeleton-ctn my-4 mx-8">
      <v-skeleton-loader width="100%" type="avatar, paragraph"></v-skeleton-loader>
    </div>
    <!--
    <div class="d-flex align-center mx-6 pt-2">
      <v-btn class="mx-2" :disabled="!inactiveCodesLoaded || creatingCode" @click="newCode" raised color="primary"><v-icon class="mr-2">mdi-plus</v-icon> New QR Code</v-btn>
      <download mode="product" :codes="codes" :product="product.id" :selectAll="true" :count="filteredCodeCount"></download>
    </div> -->

    <div v-if="sortedCodes.length && codesLoaded" class="d-flex align-center justify-space-between selection-settings mx-8">
      <div class="d-flex align-center flex-wrap py-1">
        <v-checkbox hide-details :value="selectAllEnabled" @click="toggleSelectAll()"></v-checkbox>
        <div class="search-label mr-4">{{$t('selected')}}: {{ selectAllEnabled ? filteredCodeCount : selectedCodesIndexes.length }}</div>
        <div v-if="codesLoaded === true" class="d-flex align-center">
          <div class="search-label mr-2">{{$t('bulk_actions')}}:</div>
          <code-tag-editor :isDisabled="!selectedCodes.length" @update-done="onUpdateDone" :count="filteredCodeCount" :tags="scmFields" edit-mode="bulk" :select-all="selectAllEnabled" :product="product.id" :codes="selectedCodes" :campaign="selectedCampaign.id"></code-tag-editor>
          <code-change-product :isDisabled="!selectedCodes.length" mode="bulk" :count="filteredCodeCount" v-on:code-transfered="onCodeTransfered" :select-all="selectAllEnabled" :product="product.id" :campaign="selectedCampaign.id" :codes="selectedCodes"></code-change-product>
          <download :isDisabled="!selectedCodes.length" mode="product" :codes="selectedCodes" :product="product.id" :select-all="selectAllEnabled" :count="filteredCodeCount"></download>
        </div>
      </div>
      <button @click="showUrl = !showUrl" class="d-flex show-url-btn">
        <v-img :src="visibilityImgUrl" />
        <span v-if="showUrl">{{$t('hide_url_prefixes')}}</span>
        <span v-if="!showUrl">{{$t('show_url_prefixes')}}</span>
      </button>
    </div>

    <div class="code-list-ctn mx-8" :class="{ 'no-border': !sortedCodes.length && codesLoaded}">
      <v-progress-linear v-show="!codesLoaded" indeterminate></v-progress-linear>
      <div v-show="codesLoaded">
        <div class="code-line py-1 d-flex align-center" :class="{ 'flash': code.isNew}" v-for="(code, codeIndex) in sortedCodes" :key="code.id">
          <div class="d-flex flex-wrap align-center">
            <v-checkbox hide-details color="#15bbf0" :value="selectedCodesIndexes.indexOf(codeIndex) >= 0" @click="toggleSelectCode(codeIndex)"></v-checkbox>
            <div class="mono">{{ showUrl ? (code.scm_data.scantrust_url_prefix ? code.scm_data.scantrust_url_prefix : systemPrefix) : ''}}{{code.message}}</div>
            <v-chip class="ml-2" v-show="value" color="#f1f1f1" text-color="#0789b2" :key="`code-${code.id}-tags-${name}-${value}`" v-for="(value, name) in filterScmData(code.scm_data)">{{value}}
            </v-chip>
          </div>
          <v-spacer></v-spacer>
          <div class="d-flex flex-shrink-0 align-center">
            <div class="date mono mr-2">{{code.activated_at ? formatDate(code.activated_at) : formatDate(code.creation_date) }}</div>
            <div class="icons-wrapper d-flex justify-space-between">
              <code-tag-editor :tags="scmFields" :codes="[code]" :campaign="selectedCampaign.id"></code-tag-editor>
              <code-edit-gs1-digital-link v-if="isGS1Code(code.message)" @open-edit-dialog="handleDisplayEditModal" :code="code"></code-edit-gs1-digital-link>
              <code-change-product v-else v-on:code-transfered="onCodeTransfered" :codes="[code]"></code-change-product>
              <download mode="single" :codes="[code]"></download>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!sortedCodes.length && codesLoaded" class="code-empty-state py-2">
        <img class="guide-arrow rotate-180" src="@/assets/guide_arrow.svg">
        {{ $t('product_no_codes_empty_state_1') }}
        <span class="link font-weight-bold" @click="handleDisplayQrCodeDialog(true)">{{ $t('product_no_codes_empty_state_2') }}</span>
        {{ $t('product_no_codes_empty_state_3')}}
      </div>
      <div class="pagination-ctn mt-5 mb-4">
        <v-pagination v-show="codesLoaded && filteredCodeCount" color="primary" :total-visible="7" v-model="page" :length="nbPages">
        </v-pagination>
      </div>
    </div>
    <!-- generate qr code dialog -->
    <generate-qr-code-dialog :display-dialog.sync="displayDialog" :selected-product="product"/>

    <!-- edit confirmation modal -->
    <edit-confirmation-modal :display-edit-modal.sync="displayEditModal" @open-dialog="handleDisplayQrCodeDialog" :selected-code="code"/>

  </div>
</template>

<script>
import CodeTagEditor from '@/components/CodeTagEditor'
import CodeChangeProduct from '@/components/CodeChangeProduct'
import CodesQuota from '@/components/CodesQuota'
import Download from '@/components/Download'
import Utils from '@/utils/utils'
import { mapGetters, mapMutations } from 'vuex'
import GenerateQrCodeDialog from '@/components/GenerateQrCodeDialog'
import CodeEditGs1DigitalLink from '@/components/CodeEditGS1DigitalLink'
import EditConfirmationModal from '@/components/EditConfirmationModal'
export default {
  name: 'ProductCodes',
  components: {
    CodeEditGs1DigitalLink,
    CodeTagEditor,
    CodeChangeProduct,
    CodesQuota,
    Download,
    GenerateQrCodeDialog,
    EditConfirmationModal
  },
  computed: {
    ...mapGetters(['scmFieldLoaded', 'selectedCampaign', 'codes', 'scmFields', 'inactiveCodesLoaded', 'inactiveCodes', 'filteredCodeCount', 'currentPlan', 'totalCodes', 'codesLoaded', 'prefixes', 'prefixesLoaded', 'systemPrefix', 'totalActiveCodesCount', 'campaignRedirectionMode', 'isAvailable', 'consumerOptions', 'profile', 'isCreatingCode', 'selectedProductCode', 'isCodeEditing']),

    nbPages () {
      return Math.ceil(this.filteredCodeCount / this.pageSize)
    },

    sortedCodes () {
      const sortedArray = this.codes
      return sortedArray.sort((a, b) => {
        return new Date(b.activated_at) - new Date(a.activated_at)
      })
    },

    visibilityImgUrl () {
      return this.showUrl ? require('../assets/visibility-off.svg') : require('../assets/visibility-on.svg')
    }
  },
  watch: {
    '$route.params.id' (newVal) {
      if (newVal && this.$route.name === 'products.codes') {
        if (!this.$route.params.product) {
          this.loadProduct(newVal)
        } else {
          this.product = this.$route.params.product
        }
        this.resetState()
        this.loadCodes()
        if (!this.scmFieldLoaded) {
          this.$store.dispatch('loadScmFields', this.selectedCampaign.id)
        }
      }
    },

    page (newVal) {
      this.selectAllEnabled = false
      this.selectedCodesIndexes = []
      this.loadCodes(newVal - 1)
    },

    selectedCodesIndexes (newVal) {
      this.selectedCodes = []
      newVal.forEach((index) => {
        this.selectedCodes.push(this.codes[index])
      })
    },

    displayDialog (newVal) {
      // always reset the selected code when the modal loses focus
      if (newVal === false) this.code = null
    }
  },
  data () {
    return {
      product: null,
      page: 1,
      pageSize: 20,
      loadingProduct: true,
      selectAllEnabled: false,
      selectedCodes: [],
      selectedCodesIndexes: [],
      displayDialog: false,
      displayEditModal: false,
      code: null,
      showUrl: true
    }
  },
  methods: {

    goToRedirectionSettings () {
      // Go to campaign > redirect settings
      if (this.profile.company && this.profile.company.intelligent_redirects_enabled) {
        parent.postMessage({ type: 'redirection', data: '#/redirections' }, '*')
      } else {
        parent.postMessage({ type: 'redirection', data: `#/campaigns/${this.selectedCampaign.id}/settings` }, '*')
      }
    },

    resetState () {
      this.selectedCodes = []
      this.selectedCodesIndexes = []
      this.selectAllEnabled = false
    },

    ...mapMutations({
      setTotalActiveCount: 'SET_TOTAL_ACTIVE_CODES_COUNT',
      setSelectedProductCode: 'SET_SELECTED_PRODUCT_CODE',
      setCodeEditing: 'SET_CODE_EDITING'
    }),

    onUpdateDone () {
      this.resetState()
      this.loadCodes()
    },

    formatDate (string) {
      return Utils.formatDate(string)
    },

    toggleSelectAll () {
      this.selectAllEnabled = !this.selectAllEnabled
      this.selectedCodesIndexes = []
      if (this.selectAllEnabled) {
        this.codes.forEach((code, index) => {
          this.selectedCodesIndexes.push(index)
        })
      }
    },

    toggleSelectCode (index) {
      if (this.selectedCodesIndexes.indexOf(index) >= 0) {
        if (this.selectAllEnabled) {
          this.selectAllEnabled = false
        }
        this.selectedCodesIndexes.splice(this.selectedCodesIndexes.indexOf(index), 1)
      } else {
        this.selectedCodesIndexes.push(index)
      }
    },

    getProductImage (product) {
      return product.image ? product.image : product.brand && product.brand.image ? product.brand.image : require('@/assets/product-placeholder.png')
    },

    async loadProduct (id) {
      this.product = await this.$store.dispatch('loadProduct', id)
    },

    onCodeTransfered (eventData) {
      this.resetState()
      this.$store.dispatch('loadProductCodeCount', { product: eventData.to })
      this.$store.dispatch('loadProductCodeCount', { product: this.product.id })
      this.loadCodes()
    },

    loadCodes (page) {
      this.$store.dispatch('loadCodes', { campaign: this.selectedCampaign.id, product: this.$route.params.id, offset: page ? page * this.pageSize : 0, noFilter: true })
    },
    handleDisplayQrCodeDialog (newCode = false) {
      // always set selectedProductCode to null and isCodeEditing to false anytime we are creating a new code.
      if (newCode) {
        if (this.selectedProductCode) this.setSelectedProductCode(null)
        if (this.isCodeEditing) this.setCodeEditing(false)
      }
      this.displayDialog = true
    },
    isGS1Code (code) {
      return Utils.isGS1Identifier(code)
    },
    handleDisplayEditModal (code) {
      this.code = code
      this.displayEditModal = true
    },
    filterScmData (scmData) {
      if (scmData) {
        const filteredData = {}
        Object.keys(scmData).forEach(key => {
          if (!Utils.excludedTagRegEx.test(key)) {
            filteredData[key] = scmData[key]
          }
        })
        return filteredData
      }
    }
  },
  created () {
    console.log('created')
    if (this.$route.params.product) {
      this.product = this.$route.params.product
      this.loadingProduct = false
    } else {
      this.loadProduct(this.$route.params.id)
    }

    if (!this.scmFieldLoaded) {
      this.$store.dispatch('loadScmFields', this.selectedCampaign.id)
    }

    if (!this.inactiveCodesLoaded) {
      this.$store.dispatch('loadInactiveCodes', this.selectedCampaign.id)
    }

    if (!this.prefixesLoaded) {
      this.$store.dispatch('loadPrefixes')
    }

    this.loadCodes()
  }
}

</script>

<style lang="sass" scoped>

@import '@/variables.sass'

.code-empty-state
  position: relative
  .guide-arrow
    transform: rotateY(180deg)
    position: absolute
    right: 100px
    top: -40px

.selection-settings
  height: 40px
  padding: 15px 0px 0px 0px
  .search-label, .show-url-btn span
    font-size: 16px
    color: #222222
    opacity: 0.5
    text-transform: uppercase
    letter-spacing: 0.8px
  .show-url-btn
    column-gap: 8px
    cursor: pointer
    span
      text-wrap: nowrap
      &:hover
        color: $primary

.selection-settings, .code-line
  .v-input--checkbox
    padding-top: 0 !important
    margin-top: 0 !important

.flash
  animation-name: flash
  animation-duration: 1.5s

.link
  text-decoration: underline
  cursor: pointer
  color: $primary

@keyframes flash
  0%
    background: white

  50%
    background: rgba(200, 235, 248)

  100%
    background: white

.product-codes
  overflow: auto
  height: calc(100vh - 63px)
  @include xl
    margin-left: 14%
    padding-right: 13%

.code-list-ctn
  position: relative
  border-top: 1px solid rgba(0, 0, 0, 0.3)
  &.no-border
    border-top: none
  margin-top: 10px

  .code-line
    border-bottom: 1px solid rgba(33, 33, 33, 0.08)

.product-card
  box-shadow: 1px 1px 2px rgba(0,0,0,0.35)

.search-label
  font-size: 16px
  color: #222222
  opacity: 0.5
  text-transform: uppercase

.date
  color: #999999

.redirect-setting
  color: black

.icons-wrapper
  width: 110px
</style>
