export default {
  st_config: {
    API_BASE_URL: process.env.VUE_APP_API_BASE_URL
  },
  editor_url: process.env.VUE_APP_STC_BASE_URL,
  elabel_editor_url: process.env.VUE_APP_ELABEL_EDITOR_BASE_URL,
  default_stc_url: process.env.VUE_APP_DEFAULT_STC_URL,
  default_elabel_url: process.env.VUE_APP_ELABEL_URL,
  remy_elabel_url: process.env.VUE_APP_REMY_ELABEL_URL,
  qr_service_url: process.env.VUE_APP_QR_SERVICE_URL,
  amplitude_key: process.env.VUE_APP_AMPLITUDE_KEY,
  iccube_url: process.env.VUE_APP_ICCUBE_URL,
  elabel_template_url: process.env.VUE_APP_ELABEL_TEMPLATE_URL
}
