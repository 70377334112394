import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm$getCampaign, _vm$simulationResults, _vm$simulationResults2, _vm$simulationResults3, _vm$simulationResults4, _vm$simulationResults5, _vm$simulationResults6, _vm$simulationResults7, _vm$simulationResults8, _vm$simulationResults9, _vm$simulationResults10, _vm$simulationResults11, _vm$selectedCode, _vm$selectedCode2, _vm$selectedCode2$pro, _vm$selectedCode3, _vm$selectedCode3$bra, _vm$getCampaign2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidebar-params"
  }, [_c('div', {
    staticClass: "d-flex sidebar-params align-stretch flex-column"
  }, [_c('div', [_c('div', {
    staticClass: "simulator-title font-weight-bold mb-3"
  }, [_vm._v("Scan destination simulator")]), _c('div', {
    staticClass: "description my-3"
  }, [_vm._v("Enter the QR code Extended ID and setup scanning parameters to find out where your codes are redirecting")]), _c(VBtn, {
    staticClass: "add-code-btn my-5",
    attrs: {
      "block": "",
      "raised": ""
    },
    on: {
      "click": function click($event) {
        _vm.settingsDialog = true;
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-plus")]), _vm._v(" code and parameters ")], 1)], 1), _vm.simulationResults.elapsed_ms ? _c('div', {
    staticClass: "my-3"
  }, [_c(VRow, {
    staticClass: "ma-0 mt-3 sidebar-title",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('span', [_vm._v("QR Code")]), _c(VBtn, {
    attrs: {
      "color": "primary",
      "small": "",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editSimulationParams('code');
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-square-edit-outline")])], 1)], 1), _vm.selectedCode && _vm.selectedCode.message ? _c('div', {
    staticClass: "sidebar-infos"
  }, [_c('div', [_vm._v("Extended ID: " + _vm._s(_vm.selectedCode.message))]), _c('div', [_vm._v("Product: " + _vm._s(_vm.selectedCode.product.name))]), _c('div', [_vm._v("Brand: " + _vm._s(_vm.selectedCode.brand.name))]), _c('div', [_vm._v("Campaign: " + _vm._s((_vm$getCampaign = _vm.getCampaign(_vm.selectedCode.campaign)) === null || _vm$getCampaign === void 0 ? void 0 : _vm$getCampaign.name))]), _vm._l(_vm.selectedCode.scm_data, function (value, key) {
    return _c('div', {
      key: "scm-".concat(key)
    }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.simulationResults.elapsed_ms ? _c('div', {
    staticClass: "my-1"
  }, [_c(VRow, {
    staticClass: "ma-0 mt-3 sidebar-title",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('span', [_vm._v("Scan parameters")]), _c(VBtn, {
    attrs: {
      "color": "primary",
      "small": "",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editSimulationParams('scan');
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-square-edit-outline")])], 1)], 1), _vm._l(_vm.simulationResults.params, function (value, key) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: key !== 'code_extended_id' && value,
        expression: "key !== 'code_extended_id' && value"
      }],
      key: "simulation-params-".concat(key),
      staticClass: "pa-0 ma-2"
    }, [_c('simulation-params', {
      attrs: {
        "name": _vm.getVariableName(key),
        "type": key,
        "value": value
      }
    })], 1);
  })], 2) : _vm._e(), _vm.simulationResults.elapsed_ms ? _c('div', {
    staticClass: "my-1 mb-auto"
  }, [_c('div', {
    staticClass: "mt-6 sidebar-title"
  }, [_vm._v("Final Destination")]), _c(VRow, {
    staticClass: "mt-2 mx-0"
  }, [(_vm$simulationResults = _vm.simulationResults) !== null && _vm$simulationResults !== void 0 && _vm$simulationResults.matched_rule ? _c('div', {
    staticClass: "flex-shrink-1 pill ml-2 selected"
  }, [_vm._v(_vm._s(_vm.simulationResults.matched_rule.name) + " - " + _vm._s((_vm$simulationResults2 = _vm.simulationResults.matched_destination) === null || _vm$simulationResults2 === void 0 ? void 0 : _vm$simulationResults2.name))]) : _vm._e()]), _c(VRow, {
    staticClass: "mx-0"
  }, [_vm.simulationResults.is_fallback && !((_vm$simulationResults3 = _vm.simulationResults) !== null && _vm$simulationResults3 !== void 0 && (_vm$simulationResults4 = _vm$simulationResults3.matched_destination) !== null && _vm$simulationResults4 !== void 0 && _vm$simulationResults4.id) ? _c('div', {
    staticClass: "ml-2 pill selected"
  }, [_vm.simulationResults.fallback_type === 'product_url' ? _c('span', [_vm._v("Product URL")]) : _vm._e(), _vm.simulationResults.fallback_type === 'brand_url' ? _c('span', [_vm._v("Brand URL")]) : _vm._e(), _vm.simulationResults.fallback_type === 'company_url' ? _c('span', [_vm._v("Company URL")]) : _vm._e()]) : (_vm$simulationResults5 = _vm.simulationResults) !== null && _vm$simulationResults5 !== void 0 && _vm$simulationResults5.matched_destination ? _c('div', {
    staticClass: "ml-2 pill selected",
    class: {
      'destination-warning': _vm.simulationResults.is_fallback
    }
  }, [_vm._v(" " + _vm._s(_vm.getDestinationInfos((_vm$simulationResults6 = _vm.simulationResults) === null || _vm$simulationResults6 === void 0 ? void 0 : _vm$simulationResults6.matched_destination).name) + " - "), _vm.simulationResults.is_fallback ? _c('span', [_vm.simulationResults.fallback_type === 'product_url' ? _c('span', [_vm._v("Product URL")]) : _vm._e(), _vm.simulationResults.fallback_type === 'brand_url' ? _c('span', [_vm._v("Brand URL")]) : _vm._e(), _vm.simulationResults.fallback_type === 'company_url' ? _c('span', [_vm._v("Company URL")]) : _vm._e()]) : _c('span', [_vm.getDestinationInfos((_vm$simulationResults7 = _vm.simulationResults) === null || _vm$simulationResults7 === void 0 ? void 0 : _vm$simulationResults7.matched_destination).type == 'product' ? _c('span', [_vm._v("Product URL")]) : _vm._e(), _vm.getDestinationInfos((_vm$simulationResults8 = _vm.simulationResults) === null || _vm$simulationResults8 === void 0 ? void 0 : _vm$simulationResults8.matched_destination).type == 'brand' ? _c('span', [_vm._v("Brand URL")]) : _vm._e(), _vm.getDestinationInfos((_vm$simulationResults9 = _vm.simulationResults) === null || _vm$simulationResults9 === void 0 ? void 0 : _vm$simulationResults9.matched_destination).type == 'stc' ? _c('span', [_vm._v("Landing Page")]) : _vm._e(), _vm.getDestinationInfos((_vm$simulationResults10 = _vm.simulationResults) === null || _vm$simulationResults10 === void 0 ? void 0 : _vm$simulationResults10.matched_destination).type == 'elabel' ? _c('span', [_vm._v("e-Label landing page")]) : _vm._e(), _vm.getDestinationInfos((_vm$simulationResults11 = _vm.simulationResults) === null || _vm$simulationResults11 === void 0 ? void 0 : _vm$simulationResults11.matched_destination).type == 'url' ? _c('span', [_vm._v("Custom URL")]) : _vm._e()])]) : _vm._e()])], 1) : _vm._e(), _vm.selectedCode.message ? _c('div', {
    staticClass: "mt-auto mb-2"
  }, [_c(VBtn, {
    attrs: {
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.simulate();
      }
    }
  }, [_vm._v("simulate")])], 1) : _vm._e()]), _c(VDialog, {
    attrs: {
      "width": "900",
      "scrollable": "",
      "persistent": true
    },
    model: {
      value: _vm.settingsDialog,
      callback: function callback($$v) {
        _vm.settingsDialog = $$v;
      },
      expression: "settingsDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "grey lighten-2"
  }, [_vm._v(" Settings ")]), _c(VCardText, {
    staticClass: "pa-0 ma-0"
  }, [_c(VStepper, {
    staticClass: "no-shadow",
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c(VStepperHeader, [_c(VStepperStep, {
    attrs: {
      "step": "1"
    }
  }, [_vm._v("QR Code")]), _c(VDivider), _c(VStepperStep, {
    attrs: {
      "step": "2"
    }
  }, [_vm._v("Scan Parameters")])], 1), _c(VStepperItems, [_c(VStepperContent, {
    staticClass: "pa-0",
    attrs: {
      "step": "1"
    }
  }, [_c(VForm, {
    ref: "code-form",
    staticClass: "code-form pa-5",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.searchCode.apply(null, arguments);
      }
    },
    model: {
      value: _vm.codeFormValid,
      callback: function callback($$v) {
        _vm.codeFormValid = $$v;
      },
      expression: "codeFormValid"
    }
  }, [_c(VRow, [_c(VCol, {
    staticClass: "flex-grow-1"
  }, [_c(VTextField, {
    attrs: {
      "required": "",
      "label": "Extended ID",
      "value": _vm.selectedCode.message,
      "placeholder": "Enter a code extended ID",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VCol, {
    staticClass: "flex-grow-0 flex-shrink-1"
  }, [_c(VBtn, {
    attrs: {
      "disabled": _vm.loadingCode,
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": _vm.searchCode
    }
  }, [_vm._v(" search ")])], 1)], 1), _vm.codes.length > 1 && !_vm.selectedCode.id ? _c('div', [_c('table', {
    staticClass: "codes-table"
  }, [_c('thead', [_c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Extended ID")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Product")])]), _c('tbody', _vm._l(_vm.codes, function (code) {
    return _c('tr', {
      key: "codes-".concat(code.id),
      on: {
        "click": function click($event) {
          return _vm.selectCode(code);
        }
      }
    }, [_c('td', [_vm._v(_vm._s(code.message))]), _c('td', [_vm._v(_vm._s(code.product.name) + " - " + _vm._s(code.product.sku))])]);
  }), 0)])]) : _vm._e(), _vm.selectedCode && _vm.selectedCode.message ? _c('div', {
    staticClass: "code-infos"
  }, [_c('div', [_vm._v("Extended ID: " + _vm._s((_vm$selectedCode = _vm.selectedCode) === null || _vm$selectedCode === void 0 ? void 0 : _vm$selectedCode.message))]), _c('div', [_vm._v("Product: " + _vm._s((_vm$selectedCode2 = _vm.selectedCode) === null || _vm$selectedCode2 === void 0 ? void 0 : (_vm$selectedCode2$pro = _vm$selectedCode2.product) === null || _vm$selectedCode2$pro === void 0 ? void 0 : _vm$selectedCode2$pro.name))]), _c('div', [_vm._v("Brand: " + _vm._s((_vm$selectedCode3 = _vm.selectedCode) === null || _vm$selectedCode3 === void 0 ? void 0 : (_vm$selectedCode3$bra = _vm$selectedCode3.brand) === null || _vm$selectedCode3$bra === void 0 ? void 0 : _vm$selectedCode3$bra.name))]), _c('div', [_vm._v("Campaign: " + _vm._s((_vm$getCampaign2 = _vm.getCampaign(_vm.selectedCode.campaign)) === null || _vm$getCampaign2 === void 0 ? void 0 : _vm$getCampaign2.name))]), _vm._l(_vm.selectedCode.scm_data, function (value, key) {
    return _c('div', {
      key: "scm-".concat(key)
    }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
  })], 2) : _vm._e()], 1)], 1), _c(VStepperContent, {
    attrs: {
      "step": "2"
    }
  }, [_c(VForm, {
    ref: "params-form",
    staticClass: "pa-2",
    model: {
      value: _vm.paramsFormValid,
      callback: function callback($$v) {
        _vm.paramsFormValid = $$v;
      },
      expression: "paramsFormValid"
    }
  }, [_c('div', [_c('div', {
    staticClass: "params-title pt-2"
  }, [_vm._v("Code Status")]), _c('div', {
    staticClass: "params-hint py-2"
  }, [_vm._v("For simulating the scan result only. This will not affect the actual code status.")]), _c('logic-value-input', {
    attrs: {
      "type": "scan_activation_status",
      "value": _vm.simulationParams[0].value,
      "operator": "is"
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.simulationParams[0], "value", $event);
      }
    }
  })], 1), _c('div', [_c('div', {
    staticClass: "params-title pt-2"
  }, [_vm._v("Scan Location")]), _c('div', {
    staticClass: "params-hint py-2"
  }, [_vm._v("Where you expect the user to scan this code.")]), _c('logic-value-input', {
    attrs: {
      "type": "location_country_code",
      "value": _vm.simulationParams[1].value,
      "operator": "is"
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.simulationParams[1], "value", $event);
      }
    }
  })], 1), _c('div', [_c('div', {
    staticClass: "params-title pt-2"
  }, [_vm._v("Others")]), _vm._l(_vm.simulationParams, function (param, index) {
    return _c('div', {
      key: "active-parameters-".concat(param.key)
    }, [param.key !== 'location_country_code' && param.key !== 'scan_activation_status' ? _c(VRow, {
      staticClass: "my-2 mx-0"
    }, [_c(VSelect, {
      staticClass: "mr-2",
      attrs: {
        "items": _vm.variables,
        "dense": "",
        "hide-details": "",
        "item-text": "description",
        "item-value": "value",
        "outlined": ""
      },
      model: {
        value: _vm.simulationParams[index].key,
        callback: function callback($$v) {
          _vm.$set(_vm.simulationParams[index], "key", $$v);
        },
        expression: "simulationParams[index].key"
      }
    }), _c('logic-value-input', {
      attrs: {
        "type": _vm.simulationParams[index].key,
        "value": _vm.simulationParams[index].value,
        "operator": "is"
      },
      on: {
        "update:value": function updateValue($event) {
          return _vm.$set(_vm.simulationParams[index], "value", $event);
        }
      }
    })], 1) : _vm._e()], 1);
  })], 2), _c(VBtn, {
    staticClass: "my-4",
    on: {
      "click": function click($event) {
        return _vm.addParameter();
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-plus")]), _vm._v(" Add Parameter ")], 1)], 1)], 1)], 1)], 1)], 1), _c(VCardActions, [_vm.step === 2 ? _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v(" back ")]) : _vm._e(), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" close ")]), _vm.step === 1 ? _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": "",
      "disabled": !_vm.selectedCode.id
    },
    on: {
      "click": function click($event) {
        _vm.step = 2;
      }
    }
  }, [_vm._v(" next ")]) : _vm._e(), _vm.step === 2 ? _c(VBtn, {
    attrs: {
      "color": "primary",
      "raised": ""
    },
    on: {
      "click": function click($event) {
        return _vm.simulate();
      }
    }
  }, [_vm._v(" simulate ")]) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }