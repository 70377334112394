import { default as axios } from '../utils/axios.instances' // eslint-disable-line

const axiosInstance = axios.getSTAxios() // eslint-disable-line

export default {

  async loadProfile () {
    return new Promise((resolve, reject) => {
      axiosInstance.get('/me/').then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

}
