var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iccube"
  }, [_vm.errorMessage ? _c('div', {
    staticClass: "alert"
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm._e(), _vm.loading ? _c('div', {
    staticClass: "loading"
  }, [_vm._m(0)]) : _vm._e(), _c('div', {
    attrs: {
      "id": "is3-dashboard"
    }
  })]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lds-ring"
  }, [_c('div'), _c('div'), _c('div'), _c('div')]);
}]

export { render, staticRenderFns }