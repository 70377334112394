import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VRow, {
    staticClass: "upgrade",
    attrs: {
      "align-content": "center",
      "justify": "center"
    }
  }, [_c(VCol, {
    staticClass: "upgrade-wrapper",
    attrs: {
      "cols": "12",
      "lg": "11",
      "xl": "8"
    }
  }, [_c('div', {
    staticClass: "title d-flex justify-center pa-5"
  }, [_vm._v(" Upgrade to Scantrust Enterprise ")]), _c('div', {
    staticClass: "d-flex justify-center pb-10"
  }, [_vm._v(" Get Connected Packaging solution used by Fortune 500 brands ")]), _c(VRow, {
    staticClass: "card-row d-flex justify-center flex-nowrap"
  }, [_c('div', {
    staticClass: "d-flex flex-column card"
  }, [_c('div', {
    staticClass: "title py-3"
  }, [_vm._v("Pro (Beta)*")]), _c('div', {
    staticClass: "description pb-2"
  }, [_vm._v("For growing brands who want to start engaging customers digitally")]), _c('div', {
    staticClass: "description py-2 mt-auto italic"
  }, [_vm._v("Current Plan")])]), _c('div', {
    staticClass: "d-flex flex-column card premium"
  }, [_c('div', {
    staticClass: "title py-3"
  }, [_vm._v("Enterprise")]), _c('div', {
    staticClass: "description pb-5"
  }, [_vm._v("For established breands with many SKUs and in multiple markets.")]), _c(VBtn, {
    staticClass: "mt-auto",
    attrs: {
      "raised": "",
      "primary": ""
    },
    on: {
      "click": _vm.openForm
    }
  }, [_vm._v("Contact Sales")])], 1)]), _c(VRow, {
    staticClass: "d-flex justify-center"
  }, [_c('div', {
    staticClass: "card d-flex flex-column feature-list"
  }, [_c('ul', {}, [_c('li', [_vm._v("Create and manage your Products")]), _c('li', [_vm._v("5 dynamic QR codes")]), _c('li', [_vm._v("Export Codes in different file formats")]), _c('li', [_vm._v("Intelligent Code redirection")]), _c('li', [_vm._v("Landing page editor")]), _c('li', [_vm._v("Scan dashboard & analytics")])]), _c('div', {
    staticClass: "disclaimer mt-auto"
  }, [_vm._v("*Limited time offer.")])]), _c('div', {
    staticClass: "card premium feature-list"
  }, [_c('ul', {}, [_c('li', [_vm._v("All Pro features")]), _c('li', [_vm._v("Unlimited Products")]), _c('li', [_vm._v("Unlimited Dynamic QR codes")]), _c('li', [_vm._v("Advanced QR code customisation")]), _c('li', [_vm._v("More product digital identifier options (NFC, datamatrix, 1D barcode etc.)")]), _c('li', [_vm._v("Anti-counterfeiting feature")]), _c('li', [_vm._v("Supply chain traceability feature")]), _c('li', [_vm._v("Advanced analytics")]), _c('li', [_vm._v("Users & Teams (roles)")]), _c('li', [_vm._v("Multiple Campaigns")]), _c('li', [_vm._v("API integration")]), _c('li', [_vm._v("Regional support & SLAs")])]), _c('div', {
    staticClass: "link font-weight-bold pl-2",
    on: {
      "click": function click($event) {
        return _vm.goToExternalLink('https://www.scantrust.com');
      }
    }
  }, [_vm._v("> Learn about Enterprise")])])])], 1), _c(VDialog, {
    attrs: {
      "width": "600"
    },
    model: {
      value: _vm.contactModal,
      callback: function callback($$v) {
        _vm.contactModal = $$v;
      },
      expression: "contactModal"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v("Contact Sales")]), _c(VCardText, [_c('div', {
    attrs: {
      "id": "hubspotForm"
    }
  })]), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.contactModal = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }