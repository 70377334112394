import { read, utils, write } from 'xlsx'

const getSheetNames = (file, callback) => {
  const reader = new FileReader()
  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result)
    const workbook = read(data, { type: 'array' })

    const sheetNames = workbook.SheetNames
    console.log('Sheet Names:', sheetNames)

    callback(sheetNames)
  }
  reader.readAsArrayBuffer(file)
}

const convertFileToCsv = (file, sheetIndex, callback) => {
  const reader = new FileReader()
  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result)
    const workbook = read(data, { type: 'array' })

    const sheetName = workbook.SheetNames[sheetIndex]
    const worksheet = workbook.Sheets[sheetName]

    const csv = utils.sheet_to_csv(worksheet)
    const lines = csv.split('\n')

    const filteredLines = lines.map((line) => {
      return line.replace(/,+$/, '')
    }).filter((line) => {
      return line.trim() !== '' && line.split(',').some(field => {
        return field.trim() !== ''
      })
    })

    const filteredCsvData = filteredLines.join('\n')

    console.log('---', filteredCsvData)
    callback(filteredCsvData)
  }
  reader.readAsArrayBuffer(file)
}

const downloadXlsxTemplate = (headers, callback) => {
  const wb = utils.book_new()
  const ws = utils.aoa_to_sheet([headers])

  utils.book_append_sheet(wb, ws, 'Template')

  const wbout = write(wb, { bookType: 'xlsx', type: 'binary' })

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF
    }
    return buf
  }

  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
  const url = URL.createObjectURL(blob)

  callback(url)
}

export { convertFileToCsv, downloadXlsxTemplate, getSheetNames }
