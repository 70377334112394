import * as types from '../mutations'
import DashboardService from '@/services/dashboard'

const state = {
  scans: [],
  campaign: {},
  summary: {},
  loaded: false,
  timeframe: '-1w',
  filters: []
}

const getters = {
  scans: (state) => state.scans,
  dashboardCampaign: (state) => state.campaign,
  scanSummary: (state) => state.summary,
  dashboardDataLoaded: (state) => state.loaded,
  timeframe: (state) => state.timeframe,
  orderedCountryByScans: (state) => {
    return Object.keys(state.summary.by_country).sort((a, b) => { return state.summary.by_country[b] - state.summary.by_country[a] })
  },
  dashboardFilters: (state) => state.filters
}

const actions = {

  async loadScans ({ commit, getters }, { campaign, count, cancelToken }) {
    const scans = await DashboardService.loadScans({ campaign, limit: count, timeframe: state.timeframe, filters: getters.dashboardFilters, cancelToken })
    commit(types.SET_SCANS_LIST, scans)
  },

  async loadSummary ({ commit, getters }, { campaign, cancelToken }) {
    const summary = await DashboardService.loadSummary({ campaign, timeframe: state.timeframe, filters: getters.dashboardFilters, cancelToken })
    commit(types.SET_SCAN_SUMMARY, summary)
  },

  async changeDashboardTimeframe ({ commit, dispatch }, timeframe) {
    commit(types.SET_DASHBOARD_TIMEFRAME, timeframe)
    await dispatch('loadSummary', { campaign: state.campaign })
    await dispatch('loadScans', { campaign: state.campaign, count: state.summary.meta.scans.total })
  },

  addDashboardFilter ({ commit, getters }, filter) {
    // Check if filter exist first
    const activeFilters = getters.dashboardFilters

    const match = activeFilters.find((f) => {
      return f.key === filter.key
    })

    if (match) {
      commit(types.UPDATE_DASHBOARD_FILTERS, filter)
    } else {
      commit(types.ADD_DASHBOARD_FILTER, filter)
    }
  }

}

const mutations = {
  [types.SET_SCANS_LIST] (state, scans) {
    state.scans = scans
  },

  [types.SET_DASHBOARD_CAMPAIGN] (state, campaign) {
    state.campaign = campaign
  },

  [types.SET_SCAN_SUMMARY] (state, summary) {
    state.summary = summary
  },

  [types.SET_DASHBOARD_LOAD_STATUS] (state, status) {
    state.loaded = status
  },

  [types.SET_DASHBOARD_TIMEFRAME] (state, timeframe) {
    state.timeframe = timeframe
  },

  [types.CLEAR_DASHBOARD_FILTERS] (state) {
    state.filters = []
  },

  [types.ADD_DASHBOARD_FILTER] (state, filter) {
    state.filters.push(filter)
  },

  [types.REMOVE_DASHBOARD_FILTER] (state, filter) {
    const index = state.filters.findIndex((f) => f.key === filter.key)
    if (~index) {
      state.filters.splice(index, 1)
    }
  },

  [types.UPDATE_DASHBOARD_FILTERS] (state, filter) {
    // const index = state.filters.findIndex((f) => f.key === filter.key)
    // if (~index) {
    //   state.filters[index] = filter
    // }
    const item = state.filters.find((f) => f.key === filter.key)
    if (item) {
      Object.assign(item, filter)
    }
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
