import axios from 'axios'
import Api from '@/api.js'

const STAxios = axios.create({
  baseURL: Api.st_config.API_BASE_URL
})

export default {

  getSTAxios: function () {
    return STAxios
  },

  setSTAuthToken: function (token) {
    STAxios.defaults.headers.Authorization = 'JWT ' + token
  },

  deleteSTAuthToken: function () {
    delete STAxios.defaults.headers.Authorization
  }

}
