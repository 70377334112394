import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard"
  }, [_c('div', {
    staticClass: "dashboard-ctn"
  }, [_c(VProgressLinear, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.dashboardDataLoaded,
      expression: "!dashboardDataLoaded"
    }],
    staticClass: "loader",
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }), _c(VRow, {
    staticClass: "ma-0 pt-3 title-section",
    attrs: {
      "align": "end"
    }
  }, [_c('div', {
    staticClass: "page-title mono ml-2 mr-3"
  }, [_vm._v(_vm._s(_vm.$t('dashboard_scan_report')))]), _vm.isAvailable('saas:dashboard:download') ? _c(VBtn, {
    staticClass: "ml-auto",
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.downloadScanData
    }
  }, [_c(VIcon, {
    staticClass: "pr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-download")]), _vm._v(" " + _vm._s(_vm.$t('download')) + " ")], 1) : _vm._e()], 1), _c(VRow, {
    staticClass: "ma-3 mb-0"
  }, [_c('search', {
    staticClass: "search",
    attrs: {
      "campaign": _vm.dashboardCampaign
    }
  })], 1), _c(VRow, {
    staticClass: "px-6 pt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "search-label mr-2 mono"
  }, [_vm._v(_vm._s(_vm.$t('dashboard_timeframe')) + ":")]), _vm.isAvailable('saas:dashboard:filters:timeframe') ? _c(VSelect, {
    staticClass: "timeframe-select description mono flex-grow-0",
    attrs: {
      "solo": "",
      "dense": "",
      "hide-details": "",
      "items": this.timeframes,
      "item-text": "description",
      "item-value": "value"
    },
    model: {
      value: _vm.timeframe,
      callback: function callback($$v) {
        _vm.timeframe = $$v;
      },
      expression: "timeframe"
    }
  }) : _vm._e(), _vm.dashboardFilters.length ? _c('div', {
    staticClass: "search-label ml-4 mr-2 mono"
  }, [_vm._v(_vm._s(_vm.$t('filters')))]) : _vm._e(), _vm._l(_vm.dashboardFilters, function (filter, index) {
    return _c(VChip, {
      key: "active-filters-".concat(index),
      staticClass: "ml-2",
      attrs: {
        "close": "",
        "color": "#f1f1f1",
        "text-color": "#0789b2"
      },
      on: {
        "click": function click($event) {
          return _vm.editActiveFilter(filter);
        },
        "click:close": function clickClose($event) {
          return _vm.removeFilter(filter);
        }
      }
    }, [_vm._v(" " + _vm._s(filter.name) + ": " + _vm._s(filter.value) + " ")]);
  })], 2), _vm.dashboardDataLoaded ? _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('summary-card')], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('unique-count-card')], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('product-card')], 1), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('scan-line-card')], 1), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('scan-by-time-card')], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('scan-heatmap-card')], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('sku-donut-card')], 1)], 1)], 1) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }