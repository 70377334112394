var render = function () {
  var _vm$getCountry, _vm$getLanguage, _vm$getScanActivation, _vm$getCalculatedAuth, _vm$getScanApplicatio, _vm$getCustomRegion;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "simulation-parameter"
  }, [_vm.type === 'location_country_code' ? _c('span', {
    staticClass: "param"
  }, [_vm._v(" " + _vm._s(_vm.name) + " - " + _vm._s((_vm$getCountry = _vm.getCountry(_vm.value)) === null || _vm$getCountry === void 0 ? void 0 : _vm$getCountry.name) + " ")]) : _vm.type === 'device_language' ? _c('span', {
    staticClass: "param"
  }, [_vm._v(" " + _vm._s(_vm.name) + " - " + _vm._s((_vm$getLanguage = _vm.getLanguage(_vm.value)) === null || _vm$getLanguage === void 0 ? void 0 : _vm$getLanguage.name) + " ")]) : _vm.type === 'scan_activation_status' ? _c('span', {
    staticClass: "param"
  }, [_vm._v(" " + _vm._s(_vm.name) + " - " + _vm._s((_vm$getScanActivation = _vm.getScanActivationStatus(_vm.value)) === null || _vm$getScanActivation === void 0 ? void 0 : _vm$getScanActivation.description) + " ")]) : _vm.type === 'calculated_auth_result' ? _c('span', {
    staticClass: "param"
  }, [_vm._v(" " + _vm._s(_vm.name) + " - " + _vm._s((_vm$getCalculatedAuth = _vm.getCalculatedAuthResult(_vm.value)) === null || _vm$getCalculatedAuth === void 0 ? void 0 : _vm$getCalculatedAuth.description) + " ")]) : _vm.type === 'scan_app_slug' ? _c('span', {
    staticClass: "param"
  }, [_vm._v(" " + _vm._s(_vm.name) + " - " + _vm._s((_vm$getScanApplicatio = _vm.getScanApplication(_vm.value)) === null || _vm$getScanApplicatio === void 0 ? void 0 : _vm$getScanApplicatio.name) + " ")]) : _vm.type === 'scan_region_key' ? _c('span', {
    staticClass: "param"
  }, [_vm._v(" " + _vm._s(_vm.name) + " - " + _vm._s((_vm$getCustomRegion = _vm.getCustomRegion(_vm.value)) === null || _vm$getCustomRegion === void 0 ? void 0 : _vm$getCustomRegion.name) + " ")]) : _c('span', {
    staticClass: "param"
  }, [_vm._v(" " + _vm._s(_vm.name) + " - " + _vm._s(_vm.value) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }