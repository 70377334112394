import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.icon ? _c(VBtn, {
    attrs: {
      "color": "accent",
      "icon": ""
    },
    on: {
      "click": _vm.selectFile
    }
  }, [_c(VIcon, [_vm._v("mdi-upload")])], 1) : _vm._e(), !_vm.icon ? _c(VBtn, {
    class: 'inset',
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.selectFile
    }
  }, [_vm._v(_vm._s(_vm.label ? _vm.label : _vm.$t('upload_image')))]) : _vm._e(), _c('input', {
    ref: "input-".concat(_vm.inputKey),
    attrs: {
      "id": _vm.inputKey,
      "type": "file",
      "name": "image",
      "accept": "image/png, image/jpeg, image/jpg, image/gif, image/tiff, image/tif, image/svg+xml"
    },
    on: {
      "click": _vm.resetFileSelector,
      "change": _vm.setImage
    }
  }), _c(VDialog, {
    attrs: {
      "scrollable": "",
      "width": "700"
    },
    model: {
      value: _vm.openModal,
      callback: function callback($$v) {
        _vm.openModal = $$v;
      },
      expression: "openModal"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t('image_upload')))]), _c(VCardText, [_c('vue-cropper', {
    ref: "cropper-".concat(_vm.inputKey),
    attrs: {
      "src": this.src,
      "viewMode": 1,
      "containerStyle": _vm.containerStyle,
      "zoomable": false,
      "aspect-ratio": _vm.ratio ? _vm.ratio : NaN
    }
  })], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('close')))]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(_vm._s(_vm.$t('save')))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }