import { default as axios } from '../utils/axios.instances' // eslint-disable-line

const axiosInstance = axios.getSTAxios() // eslint-disable-line

export default {

  async loadSTCConfigs (options) {
    const params = {
      limit: 20,
      offset: 0,
      is_archived: false,
      ordering: '-id',
      search: options?.search || null
    }

    return new Promise((resolve, reject) => {
      axiosInstance.get('/stc/configs/', { params }).then((res) => {
        resolve(res.data.results)
      }).catch((err) => {
        reject(err)
      })
    })
  }

}
