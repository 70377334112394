<template>
  <div class="tag-input d-flex align-center">
    <div class="label font-weight-bold">{{label}}</div>
    <v-text-field :hide-details="mode != 'new' && tagValue && tagValue.length <= 50" :counter="rules ? 50 : null" dense single-line outlined :value="tagValue" :rules="rules" @input="onInput"></v-text-field>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-3" v-bind="attrs" v-on="on" @click="deleteTag" icon>
          <v-icon color="red">mdi-minus</v-icon>
        </v-btn>
      </template>
      <span>{{$t('delete')}}</span>
    </v-tooltip>
  </div>
</template>

<script>

export default {
  name: 'TagInput',
  props: ['label', 'tagValue', 'rules', 'mode'],
  methods: {
    onInput (value) {
      this.$emit('update:tag-value', value)
    },

    deleteTag () {
      this.$emit('delete-tag')
    }
  }
}

</script>

<style lang="sass" scoped>

.label
  padding: 0px 10px
  color: black
  min-width: 140px

.tag-input
  padding: 6px 10px
  background: #f1f1f1
  .v-input--is-focused
    background: white

</style>
