export default {
  sections: [
    {
      name: 'Dashboard',
      features: [
        {
          name: 'Weekly Digest (Last 7 days)',
          availability: ['mdi-check', 'mdi-check', 'mdi-check', 'mdi-check']
        },
        {
          name: 'Data Filters',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-minus', 'mdi-check']
        },
        {
          name: 'Real-Time Data',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-minus', 'mdi-check']
        },
        {
          name: 'Data Download',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-check', 'mdi-check']
        }
      ]
    },
    {
      name: 'Product Data Management',
      features: [
        {
          name: 'Name, SKU, Description, Product Picture, Product URL',
          availability: ['mdi-check', 'mdi-check', 'mdi-check', 'mdi-check']
        }
      ]
    },
    {
      name: 'Intelligent Redirect',
      features: [
        {
          name: 'Landing Pages',
          availability: ['only 1', 'only 1', 'only 1', 'unlimited']
        },
        {
          name: 'Redirect to Your Company URL',
          availability: ['mdi-minus', 'only 1', 'only 1', 'unlimited']
        },
        {
          name: 'Different Redirect per Country',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-minus', 'mdi-check']
        }
      ]
    },
    {
      name: 'Landing Page Editor',
      features: [
        {
          name: 'Language',
          availability: ['any 1', 'unlimited', 'unlimited', 'unlimited']
        },
        {
          name: 'Custom Color Themes',
          availability: ['mdi-minus', 'mdi-check', 'mdi-check', 'mdi-check']
        },
        {
          name: 'Custom Page Title',
          availability: ['mdi-minus', 'mdi-check', 'mdi-check', 'mdi-check']
        },
        {
          name: 'Your Logo',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-check', 'mdi-check']
        },
        {
          name: 'Flexible Product Picture Mode',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-check', 'mdi-check']
        },
        {
          name: 'Personalized Scan Result Text',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-check', 'mdi-check']
        },
        {
          name: 'Remove Scantrust Brand Text',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-check', 'mdi-check']
        },
        {
          name: 'Display Serial Number',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-check', 'mdi-check']
        },
        {
          name: 'Content Tabs',
          availability: ['only 1', 'only 1', 'up to 4', 'up to 5']
        },
        {
          name: 'Arrange Elements',
          availability: ['mdi-check', 'mdi-check', 'mdi-check', 'mdi-check']
        },
        {
          name: 'Photo',
          availability: ['only 1', '10', 'unlimited', 'unlimited']
        },
        {
          name: 'Text',
          availability: ['mdi-minus', 'only 1', 'unlimited', 'unlimited']
        },
        {
          name: 'Call-to-Action Button',
          availability: ['mdi-minus', '2', 'unlimited', 'unlimited']
        },
        {
          name: 'Custom Typeform Questionnaires',
          availability: ['mdi-minus', 'mdi-check', 'mdi-check', 'mdi-check']
        },
        {
          name: 'Social Media Links',
          availability: ['only 1', '3', 'unlimited', 'unlimited']
        },
        {
          name: 'Dividers',
          availability: ['mdi-minus', 'mdi-minus', 'unlimited', 'unlimited']

        },
        {
          name: 'Video',
          availability: ['mdi-minus', 'mdi-minus', 'unlimited', 'unlimited']

        },
        {
          name: 'iFrame',
          availability: ['mdi-minus', 'mdi-minus', 'unlimited', 'unlimited']

        },
        {
          name: 'Consume Code',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-minus', 'mdi-check']

        },
        {
          name: 'Dynamic Fields (SCM excluded)',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-minus', 'mdi-check'],
          tooltip: 'Dynamic Fields are used in the Landing Page Editor to show contents or data that vary based on the product/code scanned. Our standard Dynamic Fields are: <strong>Scan Count, Serial Number, Product URL, Product SKU, Product Description.</strong>'
        }
      ]
    },
    {
      name: 'Supply Chain Awareness',
      features: [
        {
          name: 'Dynamic SCM Fields',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-minus', 'mdi-check'],
          tooltip: '<strong>SCM (Supply Chain Management) Fields</strong> such as Production Date, Expiration Date, or other custom fields can be created and auto-populated in the Landing Page Editor as Dynamic Fields.'
        }
      ]
    },
    {
      name: 'Advanced Analytics',
      features: [
        {
          name: 'Advanced Analytics',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-minus', 'mdi-check']
        }
      ]
    },
    {
      name: 'Active Brand Protection',
      features: [
        {
          name: 'Anti-counterfeiting',
          availability: ['mdi-minus', 'mdi-minus', 'mdi-minus', 'mdi-check']
        }
      ]
    }
  ]
}
