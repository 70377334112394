<template>
  <div class="qr-quota mono">
    <div class="d-flex align-center justify-end">
      <div class="quota-label font-weight-bold mono">
         {{ $t('qr_quota') }}
      </div>
    </div>
    <div class="font-weight-medium">
      <span class="activated">
        {{ totalActiveCodesCount | formatNumber }}
      </span>
      /
      <span> {{ totalActiveCodesCount + totalInactiveCodesCount | formatNumber }}
        {{ $t('total') }}:
      </span>
    </div>
    <div v-if="totalBlacklistedAssignedCodesCount" class="inactive-ctn">
      {{ totalBlacklistedAssignedCodesCount }} {{$t('blacklisted')}}
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'CodesQuota',
  computed: {
    ...mapGetters(['totalCodes', 'totalActiveCodesCount', 'totalInactiveCodesCount', 'totalBlacklistedAssignedCodesCount', 'currentPlan']),

    hasPlanLimit () {
      return this.currentPlan?.id === 'elabel-freemium' || this.currentPlan?.id.indexOf('business') > -1
    }
  },
  filters: {
    formatNumber (value) {
      return new Intl.NumberFormat().format(value)
    }
  },
  created () {
    if (!this.totalActiveCodesCount) {
      this.$store.dispatch('loadActiveCodeCount')
      this.$store.dispatch('loadBlacklistedCodeCount')
    }
  }
}

</script>

<style lang="sass">

.qr-quota
  display: flex
  flex-direction: column
  align-items: flex-end
  font-size: 14px
  .quota-label
    color: #999999
  .right
    text-align: right
  .activated
    color: #00aeef
    &.plus
      color: #3fc135
    &.premium
      color: #ff7f00

  .inactive-ctn
    color: #F22A42

</style>
