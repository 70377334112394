import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VCard, {
    staticClass: "heatmap-card",
    class: {
      'expand': _vm.expand
    }
  }, [_c(VRow, {
    staticClass: "ma-0"
  }, [_c(VCol, {
    staticClass: "pa-0"
  }, [_c('card-label', {
    attrs: {
      "color": "00aeef",
      "content": "scan by location"
    }
  })], 1), _c(VCol, {
    staticClass: "pa-0 flex-grow-0"
  }, [!_vm.isAvailable('saas:dashboard:map') ? _c('info-tooltip', {
    attrs: {
      "position": "top",
      "content": "<a href='#/upgrade'>Upgrade now</a> to access to the advanced map features"
    }
  }) : _vm._e(), _vm.isAvailable('saas:dashboard:map') ? _c(VBtn, {
    staticClass: "card-action-btn",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.resizeMap();
      }
    }
  }, [_vm.expand ? _c(VIcon, {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-close")]) : _vm._e(), !_vm.expand ? _c(VIcon, {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-arrow-expand")]) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "map",
    attrs: {
      "id": "mapContainer"
    }
  }), _c(VRow, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.scanSummary.by_country[_vm.orderedCountryByScans[0]] > 0,
      expression: "scanSummary.by_country[orderedCountryByScans[0]] > 0"
    }],
    staticClass: "ma-0 legend-ctn",
    attrs: {
      "justify": "end"
    }
  }, [_c('span', {
    staticClass: "px-2"
  }, [_vm._v("0")]), _c('div', {
    staticClass: "legend"
  }), _c('span', {
    staticClass: "px-2"
  }, [_vm._v(_vm._s(_vm.scanSummary.by_country[_vm.orderedCountryByScans[0]]) + " scans")])]), _vm.selectedCountry ? _c('div', {
    staticClass: "map-info-card"
  }, [_vm._v(" Country: " + _vm._s(_vm.selectedCountry.name)), _c('br'), _vm._v(" Scans: " + _vm._s(_vm.selectedCountry.scans) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }