<template>
  <div>
    <v-btn v-if="icon" @click="selectFile" color="accent" icon><v-icon>mdi-upload</v-icon></v-btn>
    <v-btn v-if="!icon" @click="selectFile" :class="'inset'" color="primary">{{ label ? label : $t('upload_image')}}</v-btn>
    <input
      :ref="`input-${inputKey}`"
      :id="inputKey"
      @click="resetFileSelector"
      @change="setImage"
      type="file"
      name="image"
      accept="image/png, image/jpeg, image/jpg, image/gif, image/tiff, image/tif, image/svg+xml">
    <v-dialog scrollable width="700" v-model="openModal">
      <v-card>
        <v-card-title class="headline">{{$t('image_upload')}}</v-card-title>
        <v-card-text>
          <vue-cropper
            :ref="`cropper-${inputKey}`"
            :src="this.src"
            :viewMode="1"
            :containerStyle="containerStyle"
            :zoomable="false"
            :aspect-ratio="ratio ? ratio : NaN"
          >
          </vue-cropper>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">{{$t('close')}}</v-btn>
          <v-btn text @click="save" color="primary">{{$t('save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'ImageCropper',
  props: ['src', 'icon', 'input-key', 'ratio', 'label'],
  components: {
    VueCropper
  },
  data () {
    return {
      openModal: false,
      type: null,
      croppedImg: null,
      containerStyle: {
        width: '100%',
        'max-height': '450px'
      }
    }
  },
  methods: {
    cropImage () {
      if (this.$refs[`cropper-${this.inputKey}`]) {
        this.croppedImg = this.$refs[`cropper-${this.inputKey}`].getCroppedCanvas().toDataURL(this.type)
      }
    },

    resetFileSelector () {
      this.$refs[`input-${this.inputKey}`].value = ''
    },

    async save () {
      this.cropImage()
      this.$emit('image-selected', this.croppedImg)
      this.close()
    },

    close () {
      this.$refs[`cropper-${this.inputKey}`].value = ''
      this.croppedImg = false
      this.openModal = false
    },

    setImage (e) {
      const file = e.target.files[0]
      if (!file) {
        return
      }
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      this.type = file.type
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result

          if (this.type !== 'image/svg+xml') {
            this.openModal = true
            setTimeout(() => {
              this.$refs[`cropper-${this.inputKey}`].replace(event.target.result)
            }, 200)
          } else {
            this.openModal = false
            this.$emit('image-selected', this.imgSrc)
          }
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },

    selectFile () {
      const input = document.getElementById(this.inputKey)

      input.click()
    }
  }
}
</script>

<style lang="sass" scoped>

input
  display: none

</style>
