var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M4.36439 0H0V4.36439H4.36439V0Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M4.36439 4.36438H0V8.72877H4.36439V4.36438Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.72877 0H4.36438V4.36439H8.72877V0Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M43.6356 0H39.2712V4.36439H43.6356V0Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M48 4.36438H43.6356V8.72877H48V4.36438Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M48 0H43.6356V4.36439H48V0Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M-0.000915527 43.6359L-0.000915527 48.0002L4.36347 48.0002L4.36347 43.6359L-0.000915527 43.6359Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M4.36267 43.6351L4.36267 47.9995L8.72706 47.9995L8.72706 43.6351L4.36267 43.6351Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M-0.00158691 39.2722L-0.00158691 43.6366L4.3628 43.6366L4.3628 39.2722L-0.00158691 39.2722Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M43.6368 48.0012L48.0012 48.0012L48.0012 43.6368L43.6368 43.6368L43.6368 48.0012Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M43.6368 43.6383L48.0012 43.6383L48.0012 39.2739L43.6368 39.2739L43.6368 43.6383Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M39.2739 48.0012L43.6383 48.0012L43.6383 43.6368L39.2739 43.6368L39.2739 48.0012Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M23.7177 31.0823L8.10236 19.3698L23.7177 7.65726L39.3331 19.3698L23.7177 31.0823Z",
      "fill": "#0789B3"
    }
  }), _c('path', {
    attrs: {
      "d": "M23.7177 35.7146L11.1892 26.3141L8.10236 28.6302L23.7177 40.3428L39.3331 28.6302L36.2463 26.3141L23.7177 35.7146Z",
      "fill": "#5AD2F8"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0"
    }
  }, [_c('rect', {
    attrs: {
      "width": "48",
      "height": "48",
      "fill": "white"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }