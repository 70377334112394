<template>
  <v-card class="scan-line-card">
    <card-label color="00aeef" content="scan by day"></card-label>
    <div>
      <apexchart width="100%" height="170px" type="line" :options="options" :series="series"></apexchart>
    </div>
  </v-card>
</template>

<script>

import CardLabel from '@/components/CardLabel'
import { mapGetters } from 'vuex'

export default {
  name: 'ScanLineCard',
  components: {
    CardLabel
  },
  computed: {
    ...mapGetters(['scanSummary', 'timeframe'])
  },
  watch: {
    scanSummary () {
      this.series[0].data = this.getScanCountLine()
      this.options = {
        xaxis: {
          categories: this.getXAxisLabel()
        }
      }
    }
  },
  methods: {
    getXAxisLabel () {
      if (this.timeframe === '-1w') {
        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        const dayIndex = new Date().getDay()

        return days.splice(dayIndex).concat(days.splice(0, dayIndex))
      } else {
        const labels = []
        this.scanSummary.by_date.forEach((timeframe) => {
          const date = new Date(timeframe.start)
          labels.push(`${date.toDateString().split(' ')[1]} ${date.toDateString().split(' ')[2]}`)
        })
        return labels
      }
    },

    getScanCountLine () {
      let lineData = []
      lineData = []
      this.scanSummary.by_date.forEach((data) => {
        const total = data.stats.failure + data.stats.genuine + data.stats['not-found'] + data.stats.verified
        lineData.push(total)
      })
      return lineData
    }
  },
  data () {
    return {
      options: {
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          },
          selection: {
            enabled: false
          },
          zoom: {
            enabled: false
          }
        },
        xaxis: {
          categories: []
        },
        colors: ['#00aeef']
      },
      series: [{
        name: 'Scan count',
        data: []
      }]
    }
  },
  created () {
    this.series[0].data = this.getScanCountLine()
    this.options.xaxis.categories = this.getXAxisLabel()
  }
}

</script>

<style lang="sass" scoped>

@import "@/variables.sass"

.scan-line-card
  padding-top: 8px

.product-ctn
  color: $dark-grey
  .number
    line-height: 28px
    font-size: 36px
  .legend
    line-height: 15px

</style>
