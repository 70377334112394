<template>
  <div>
    <div>
      <v-btn @click="open" fab>
        <v-icon color="primary">
          $csv
        </v-icon>
      </v-btn>
      <div  class="pt-4">{{$t('multiple_products')}}</div>
    </div>
    <v-dialog width="800" v-model="dialog">
      <div class="title">{{$t('update_products_by_file')}}</div>
      <v-stepper :value="step">
        <v-stepper-header>
          <v-stepper-step step="1">{{$t('prepare')}}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2">{{$t('upload')}}</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div class="content">
              <div class="important font-weight-bold">{{$t('important_notice')}}:</div>
              <v-layout>
                <v-flex class="infos font-weight-medium">
                  {{$t('file_header_must_contain')}}:<br><br>
                  - sku<br>
                  - name<br><br>

                  {{$t('other_supported_columns')}}:<br>
                  - url<br>
                  - description<br><br>
                  {{$t('file_format_hint_2')}}
                  <!-- Please use comma (,) as the delimiter in your CSV file. -->
                </v-flex>
                <v-flex>
                  <div class="file-upload-ctn">
                    <v-layout @click="clickOnFileInput($event, 'csv-file')" class="upload-btn" align-center justify-center column>
                      <div v-if="!file || !file.name" class="text-xs-center">
                        <v-icon x-large color="primary">mdi-cloud-upload</v-icon>
                        <div class="text-uppercase font-weight-bold">{{$t('choose_file')}}</div>
                        <div class="format-hint">
                          {{$t('supported_formats')}}:<br>
                          CSV, XLS, XLSX
                        </div>
                      </div>

                      <div v-if="file && file.name" class="text-xs-center">
                        <v-icon x-large color="primary">mdi-cloud-upload</v-icon>
                        <div class="text-uppercase font-weight-bold">{{$t('change_file')}}</div>
                        <div class="format-hint">
                          {{file.name}}
                        </div>
                      </div>

                    </v-layout>
                    <input v-show="false" accept=".csv,.xls,.xlsx" @change="onFileChange($event)" type="file" ref="csv-file" />
                  </div>
                </v-flex>
              </v-layout>
              <v-layout v-if="sheetNames.length > 1">
                <v-flex>
                  <v-select
                    dense
                    outlined
                    class="my-4"
                    hide-details
                    v-model="selectedSheet"
                    label="Select a sheet to upload"
                    :items="sheetNames"
                    @change="findSheetIndex"
                  >
                  </v-select>
                </v-flex>
              </v-layout>
            </div>
            <v-layout class="footer">
              <v-flex grow>
                <v-btn @click="downloadTemplate('csv')" text color="primary">{{$t('download_file_template', { format: 'CSV'})}}<v-icon class="ml-2">mdi-cloud-download</v-icon></v-btn>
                <v-btn @click="downloadTemplate('xlsx')" text color="primary">{{$t('download_file_template', { format: 'XLSX'})}}<v-icon class="ml-2">mdi-cloud-download</v-icon></v-btn>
              </v-flex>
              <v-flex shrink>
                <v-btn @click="close()" text color="primary">{{$t('cancel')}}</v-btn>
                <v-btn @click="parseCSV()" raised color="primary">{{$t('next')}}</v-btn>
              </v-flex>
            </v-layout>
          </v-stepper-content>

          <v-stepper-content class="custom-stepper" step="2">
            <div class="loading" v-show="!completed">
              <v-progress-linear v-show="!completed" :indeterminate="true"></v-progress-linear>
              <v-layout align-center justify-center row fill-height>
                <v-flex shrink class="text-center infos font-weight-bold">{{$t('uploading')}} ({{this.uploadCount}} / {{this.uploadData.length}})<br>
                <div v-if="this.throttling">{{$t('upload_in_progress')}}</div>
                </v-flex>
              </v-layout>
            </div>

            <div v-if="completed && uploadErrors.length" class="content">
              <div class="important font-weight-bold" >{{$t('errors')}}</div>

              <div class="infos font-weight-medium">{{$t('upload_file_error')}}:<br><br></div>
              <div v-for="(line, index) in uploadErrors" :key="`errors-${index}`" class="infos font-weight-medium">
                {{line}}
              </div>
              <div v-if="hasWarning" class="infos mt-5 font-weight-medium">{{$t('upload_error_proceed_warning')}}<br><br></div>
              <v-btn v-if="hasWarning" @click="forceUpload()" raised color="primary">{{$t('upload_anyways')}}</v-btn>
            </div>

            <div v-if="completed && !uploadErrors.length" class="content">
              <div class="important font-weight-bold">{{$t('upload_complete')}}</div>

              <div v-if="updateCount > 0" class="infos font-weight-medium">{{$t('product_count_updated', { count: this.updateCount})}}</div><br>
              <div v-if="createCount > 0" class="infos font-weight-medium">{{$t('product_count_created', { count: this.createCount})}}</div>
            </div>
            <v-layout class="footer" row>
              <v-flex grow>
                <v-btn @click="close" text color="primary">{{$t('close')}}</v-btn>
              </v-flex>
              <v-flex v-if="completed && !uploadErrors.length" shrink>
                <v-btn @click="goToCodesList" text color="primary">{{$t('create_codes')}}</v-btn>
              </v-flex>
            </v-layout>
          </v-stepper-content>
        </v-stepper-items>

      </v-stepper>
    </v-dialog>
  </div>
</template>

<script>
import * as Papa from 'papaparse'
import { mapGetters } from 'vuex'
import { convertFileToCsv, downloadXlsxTemplate, getSheetNames } from '../utils/convertToCsv'

export default {
  name: 'ProductCSV',
  data () {
    return {
      dialog: false,
      step: 1,
      headerLine: 'sku,name,url,description',
      mandatoryHeader: ['sku', 'name'],
      uploadErrors: [],
      uploadData: [],
      uploadCount: 0,
      updateCount: 0,
      createCount: 0,
      hasWarning: false,
      throttling: false,
      file: {},
      csvData: {},
      line: 0,
      completed: false,
      batchSize: 10,
      sheetNames: [],
      selectedSheetIndex: -1,
      selectedSheet: null
    }
  },
  computed: {
    ...mapGetters(['defaultBrand', 'selectedCampaign', 'brands'])
  },
  watch: {
    selectedSheetIndex (newVal) {
      convertFileToCsv(this.file, this.selectedSheetIndex, (data) => {
        this.csvData = data
      })
    }
  },
  methods: {
    findSheetIndex () {
      this.selectedSheetIndex = this.sheetNames.indexOf(this.selectedSheet)
    },
    close () {
      this.resetState()
      this.dialog = false
    },

    open () {
      this.dialog = true
      this.$emit('open-dialog')
    },

    goToCodesList () {
      this.close()
      this.$router.push({ name: 'codes', params: { openBulkCreation: true } })
    },

    resetState () {
      this.step = 1
      this.uploadErrors = []
      this.uploadData = []
      this.line = 0
      this.file = {}
      this.csvData = {}
      this.uploadCount = 0
      this.updateCount = 0
      this.createCount = 0
      this.completed = false
      this.$refs['csv-file'].value = ''
      this.sheetNames = []
    },

    async uploadProductBatch (index, isRetry) {
      const promiseExistsArray = []
      const promiseUpdatesArray = []

      // Take next BatchSize Products
      let array = JSON.parse(JSON.stringify(this.uploadData))
      array = array.splice(index, this.batchSize)

      if (!array.length) {
        this.completed = true
        this.$emit('upload-complete')
        return
      }

      array.forEach((product) => {
        promiseExistsArray.push(this.$store.dispatch('loadProductBySKU', product))
      })

      Promise.all(promiseExistsArray).then(async (res) => {
        // res.forEach(())
        this.throttling = false
        res.forEach(async (product, promiseIndex) => {
          // HERE DO NOT USE PRODUCT BUT USE ARRAY[INDEX] TO UPDATE/CREATE
          if (product && product.id) {
            array[promiseIndex].id = product.id
            array[promiseIndex].campaign = this.selectedCampaign.id
            if (array[promiseIndex].url) {
              array[promiseIndex].client_url = array[promiseIndex].url
            }
            if (!isRetry) {
              this.updateCount++
            }
            promiseUpdatesArray.push(this.$store.dispatch('updateProduct', { product: array[promiseIndex] }))
          } else {
            product.campaign = this.selectedCampaign.id
            if (!product.brand) {
              product.brand = this.defaultBrand.reference
            }
            if (product.url) {
              product.client_url = product.url
            }

            if (!isRetry) {
              this.createCount++
            }
            promiseUpdatesArray.push(this.$store.dispatch('createProduct', { product }))
          }
        })

        Promise.all(promiseUpdatesArray).then((res) => {
          this.uploadCount += promiseUpdatesArray.length
          this.uploadProductBatch(index + this.batchSize)
          this.throttling = false
        }).catch((err) => {
          if (err && err.status === 400) {
            if (err.data && err.data.brand) {
              this.uploadErrors.push(`Brand: "${err.request.brand}" was not found in your company.`)
            }
            this.completed = true
          }
          if (err && err.status === 429) {
            this.throttling = true
            console.log('Throttling')
            setTimeout(() => {
              this.uploadProductBatch(index, true)
            }, 10000)
          }
        })
      }).catch((err) => {
        if (err && err.response && err.response.status === 429) {
          this.throttling = true
          console.log('Throttling')
          setTimeout(() => {
            this.uploadProductBatch(index, true)
          }, 10000)
        }
      })
    },

    async startUpload () {
      this.uploadProductBatch(0)
    },

    isHeaderValid (fields) {
      const missingFields = JSON.parse(JSON.stringify(this.mandatoryHeader))
      this.mandatoryHeader.forEach((mandatoryField) => {
        const found = fields.find((field) => {
          return field === mandatoryField
        })

        if (found) {
          missingFields.splice(missingFields.indexOf(mandatoryField), 1)
        }
      })

      if (missingFields.length) {
        this.uploadErrors.push('Line: 1 Invalid file header.')
        this.uploadErrors.push('Missing fields: ' + missingFields.join(','))
      }
      return missingFields.length === 0
    },

    parseCSV () {
      if (!this.file.name) {
        this.clickOnFileInput(null, 'csv-file')
        return
      }
      this.step = 2
      Papa.parse(this.csvData, {
        skipEmptyLines: true,
        fastMode: true,
        header: true,
        step: (row, parser) => {
          let rowHasError = false
          if (this.line === 0 && !this.isHeaderValid(row.meta.fields)) {
            parser.abort()
          }

          if (row.errors.length) {
            row.errors.some((err) => {
              this.uploadErrors.push(`Line ${err.row + 1}: ${err.message}`)
              parser.abort()
            })
          }

          for (var key in row.data) {
            if ((key === 'sku' || key === 'name') && !row.data[key]) {
              this.uploadErrors.push(`Line ${this.line + 2}: No value found for column ${key}`)
              rowHasError = true
            }
          }

          if (!rowHasError) {
            this.uploadData.push(row.data)
          } else {
            this.hasWarning = true
          }
          this.line++
        },
        complete: (results) => {
          if (results.meta && results.meta.aborted) {
            this.completed = true
          } else {
            if (this.hasWarning) {
              this.completed = true
            } else if (this.uploadData.length) {
              this.startUpload()
            } else {
              this.uploadErrors.push('This file is empty.')
              this.completed = true
            }
          }
        }
      })
    },

    forceUpload () {
      this.completed = false
      this.hasWarning = false
      this.startUpload()
    },

    downloadTemplate (fileFormat) {
      const link = document.createElement('a')
      const allHeaders = this.headerLine.split(',')

      if (fileFormat === 'xlsx' || fileFormat === 'xls') {
        downloadXlsxTemplate(allHeaders, (url) => {
          link.href = url
        })
      } else {
        let content = 'data:text/csv;charset=utf-8,'
        content += this.headerLine
        const data = encodeURI(content)
        link.setAttribute('href', data)
      }

      link.setAttribute('download', `products_upload_template.${fileFormat}`)
      link.click()
    },

    clickOnFileInput (event, inputName) {
      if (event) {
        event.stopImmediatePropagation()
      }
      this.$refs[inputName].click()
    },

    onFileChange (event) {
      setTimeout(() => {
        this.file = event.target.files[0]
        const fileExtension = this.file.name.split('.')[1]

        if (fileExtension === 'xls' || fileExtension === 'xlsx') {
          getSheetNames(this.file, (sheetNames) => {
            this.sheetNames = sheetNames
            if (this.sheetNames && this.sheetNames.length === 1) {
              convertFileToCsv(this.file, 0, (data) => {
                this.csvData = data
              })
            }
          })
        } else {
          this.csvData = this.file
        }
      })
    }
  }
}

</script>

<style lang="sass" scoped>

.title
  padding: 25px
  background: white
  position: relative
  z-index: 99

.v-stepper
  position: relative
  top: -1px
  border-radius: 0px

.important
  font-size: 19px
  margin-bottom: 15px

.infos
  color: black
  opacity: 0.6
  font-size: 14px

.content
  padding-bottom: 40px

.upload-btn
  text-align: center
  width: 100%
  height: 100%
  background: #f1f1f1
  padding: 15px
  cursor: pointer
  .v-icon
    width: 35px

  .format-hint
    word-break: break-all
    margin-top: 15px

  .text-uppercase
    color: #00aeef
    font-size: 14px

.custom-stepper
  padding: 0px
  .v-progress-linear
    margin-top: 0px
  .footer
    margin: 0px 24px 16px 24px
  .content
    padding: 24px 16px
  .loading
    .infos
      padding: 50px 0px
      color: #00aeef
      font-size: 20px
      opacity: 1

.file-upload-ctn
  min-width: 250px

</style>
