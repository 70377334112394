import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VCard, {
    staticClass: "unique-count-card"
  }, [_c('card-label', {
    attrs: {
      "color": "ff7f00",
      "content": "unique count"
    }
  }), _c(VRow, {
    staticClass: "ma-0 my-6 unique-code-ctn",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c(VIcon, {
    staticClass: "scan-icon",
    attrs: {
      "size": "48px"
    }
  }, [_vm._v("$qr-code")]), _c('div', {
    staticClass: "pl-3"
  }, [_c('div', {
    staticClass: "number font-weight-bold"
  }, [_vm._v(_vm._s(_vm.scanSummary.meta.distinct_codes))]), _c('div', {
    staticClass: "legend font-weight-bold"
  }, [_vm._v("Unique Codes")])])], 1), _c(VRow, {
    staticClass: "ma-0 my-6 unique-users-ctn",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c(VIcon, {
    staticClass: "scan-icon",
    attrs: {
      "size": "48px"
    }
  }, [_vm._v("$user")]), _c('div', {
    staticClass: "pl-3"
  }, [_c('div', {
    staticClass: "number font-weight-bold"
  }, [_vm._v(_vm._s(_vm.scanSummary.users.total))]), _c('div', {
    staticClass: "legend font-weight-bold"
  }, [_vm._v("Unique Users")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }