import { default as axios } from '../utils/axios.instances' // eslint-disable-line

const axiosInstance = axios.getSTAxios() // eslint-disable-line

export default {

  async loadCampaigns () {
    const params = {
      limit: 200,
      offset: 0,
      is_archived: false,
      ordering: 'name'
    }

    return new Promise((resolve, reject) => {
      axiosInstance.get('/campaigns/', { params }).then((res) => {
        resolve(res.data.results)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async loadConsumerOptions (campaign) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/campaigns/${campaign}/consumer/`).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async loadScmFields (campaign) {
    const params = {
      is_archived: false
    }

    return new Promise((resolve, reject) => {
      axiosInstance.get(`/campaigns/${campaign}/scm-fields/`, { params }).then((res) => {
        // const filteredFields = []
        // res.data.forEach((field) => {
        //   if (!field.is_archived) {
        //     filteredFields.push(field)
        //   }
        // })
        // resolve(filteredFields)
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

}
