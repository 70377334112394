var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "expression-block-preview"
  }, _vm._l(_vm.conditions[Object.keys(_vm.conditions)[0]], function (condition, index) {
    return _c('div', {
      key: "preview-".concat(index)
    }, _vm._l(condition, function (expression, key) {
      return _c('div', {
        key: "preview-".concat(index, "-expression-").concat(key),
        staticClass: "expressions-ctn my-2 d-flex align-center"
      }, [_c('div', {
        staticClass: "flex-grow-0 flex-shrink-1 parameter"
      }, [_vm._v(_vm._s(_vm.getVariable(expression[0].var) ? _vm.getVariable(expression[0].var).description : "SCM Field: ".concat(expression[0].var.split('scm_')[1])))]), _c('div', {
        staticClass: "operator flex-shrink-0 mx-2"
      }, [_vm._v(_vm._s(_vm.getOperator(key).value))]), _c('logic-value-input', {
        attrs: {
          "mode": "preview",
          "operator": key,
          "type": expression[0].var,
          "value": expression[1]
        },
        on: {
          "update:value": function updateValue($event) {
            return _vm.$set(expression, 1, $event);
          }
        }
      })], 1);
    }), 0);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }