<template>
  <div class="landing-page">
    <v-progress-linear
      v-show="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <iframe id="stc-frame" v-if="loadIframe"  frameborder="0" :src="this.editorUrl"></iframe>
  </div>
</template>

<script>

import Api from '@/api'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'LandingPage',
  computed: {
    ...mapGetters(['selectedCampaign', 'scantrustAuthToken', 'landingPages', 'loadingCampaigns', 'campaigns', 'didProductUpdate', 'consumerOptions']),
    editorUrl () {
      if ((!this.config || !this.config.id) || (!this.selectedCampaign || !this.selectedCampaign.id)) {
        return
      }
      return Api.editor_url + '#/config/' + this.config.id + '/redirect?redirect=true&campaign=' + this.selectedCampaign.id + '&token=' + this.scantrustAuthToken
    }
  },
  data () {
    return {
      loadIframe: true,
      config: {},
      campaign: {},
      loading: true
    }
  },
  watch: {
    didProductUpdate () {
      if (this.didProductUpdate) {
        if (document.getElementById('stc-frame')) {
          document.getElementById('stc-frame').src = ''
          setTimeout(() => {
            document.getElementById('stc-frame').src = this.editorUrl
          }, 200)
        }
        this.setProductUpdateStatus(false)
      }
    },
    selectedCampaign (newVal) {
      if (newVal) {
        this.loadIframe = false
        setTimeout(() => {
          this.loadIframe = true
        }, 100)
      }
    },
    consumerOptions (newVal) {
      if (newVal && this.landingPages) {
        if (newVal.stc_config_shared) {
          this.config = this.landingPages.find((config) => {
            return (config.id === newVal.stc_config_shared)
          })
        }
      }
    }
  },
  methods: {

    ...mapMutations({
      setProductUpdateStatus: 'UPDATE_PRODUCT_UPDATE_STATUS'
    }),

    initEventHandler () {
      var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
      var eventer = window[eventMethod]
      var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'

      eventer(messageEvent, (e) => {
        if (e.data.type === 'editor.loaded') {
          this.loading = false
        } else if (e.data.type === 'editor.navigation') {
          if (~e.data.hash.indexOf('#')) {
            this.$router.push(e.data.hash.split('#')[1])
          }
        } else if (e.data.type === 'editor.update-redirection') {
          this.$store.dispatch('loadSelectedCampaignConsumerOptions', this.selectedCampaign.id)
        }
      })
    }
  },
  async created () {
    await this.$store.dispatch('loadLandingPages')
    if (!this.consumerOptions || !('stc_config_shared' in this.consumerOptions)) {
      await this.$store.dispatch('loadSelectedCampaignConsumerOptions', this.selectedCampaign.id)
    }
    if (this.consumerOptions && this.consumerOptions.stc_config_shared) {
      this.config = this.landingPages.find((config) => {
        return (config.id === this.consumerOptions.stc_config_shared)
      })
    } else if (this.landingPages.length) {
      this.config = this.landingPages[0]
    }
    if (this.didProductUpdate) {
      this.setProductUpdateStatus(false)
    }
    this.initEventHandler()
  }
}

</script>

<style lang="sass" scoped>

.landing-page
  font-family: 'Roboto mono'
  height: 100%
  width: 100%

iframe
  overflow: hidden
  width: 100%
  min-width: 100%
  min-height: 100%

</style>
