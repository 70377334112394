import { default as axios } from '../utils/axios.instances' // eslint-disable-line

const axiosInstance = axios.getSTAxios() // eslint-disable-line

export default {
  async loadCustomRegions () {
    return new Promise((resolve, reject) => {
      axiosInstance.get('/scm/region-entries/', { limit: 100, offset: 0 }).then((res) => {
        resolve(res.data.results)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
