export default [
  { key: 'AT', description: 'Austria', languages: ['de'] },
  { key: 'BE', description: 'Belgium', languages: ['fr', 'du'] },
  { key: 'BG', description: 'Bulgaria', languages: ['bg'] },
  { key: 'HR', description: 'Croatia', languages: ['hr'] },
  { key: 'CY', description: 'Cyprus', languages: ['el'] },
  { key: 'CZ', description: 'Czechia', languages: ['cs'] },
  { key: 'DK', description: 'Denmark', languages: ['da'] },
  { key: 'EE', description: 'Estonia', languages: ['et'] },
  { key: 'FI', description: 'Finland', languages: ['fi'] },
  { key: 'FR', description: 'France', languages: ['fr'] },
  { key: 'DE', description: 'Germany', languages: ['de'] },
  { key: 'GR', description: 'Greece', languages: ['el'] },
  { key: 'HU', description: 'Hungary', languages: ['hu'] },
  { key: 'IE', description: 'Ireland', languages: ['en'] },
  { key: 'IT', description: 'Italy', languages: ['it'] },
  { key: 'LV', description: 'Latvia', languages: ['lv'] },
  { key: 'LT', description: 'Lithuania', languages: ['lt'] },
  { key: 'LU', description: 'Luxembourg', languages: ['fr'] },
  { key: 'MT', description: 'Malta', languages: ['mt'] },
  { key: 'NL', description: 'Netherlands', languages: ['nl'] },
  { key: 'PL', description: 'Poland', languages: ['pl'] },
  { key: 'PT', description: 'Portugal', languages: ['pt'] },
  { key: 'RO', description: 'Romania', languages: ['ro'] },
  { key: 'SK', description: 'Slovakia', languages: ['sk'] },
  { key: 'SI', description: 'Slovenia', languages: ['sl'] },
  { key: 'ES', description: 'Spain', languages: ['es'] },
  { key: 'SE', description: 'Sweden', languages: ['sv'] }
]
