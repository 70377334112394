<template>
  <div class="expression-block-preview">
    <div v-for="(condition, index) in conditions[Object.keys(conditions)[0]]" :key="`preview-${index}`">
      <div v-for="(expression, key) in condition" :key="`preview-${index}-expression-${key}`" class="expressions-ctn my-2 d-flex align-center">
        <div class="flex-grow-0 flex-shrink-1 parameter">{{getVariable(expression[0].var) ? getVariable(expression[0].var).description : `SCM Field: ${expression[0].var.split('scm_')[1]}`}}</div>
        <div class="operator flex-shrink-0 mx-2">{{getOperator(key).value}}</div>
        <logic-value-input mode="preview" :operator="key" :type="expression[0].var" :value.sync="expression[1]"></logic-value-input>
        <!-- <div class="value">{{formatValue(expression[1], )}}</div> -->
        <!-- <div class="link-operator mx-2">{{Object.keys(conditions)[0]}}</div> -->
      </div>
    </div>
  </div>
</template>

<script>

import LogicValueInput from '@/components/redirection/LogicValueInput'
import { mapGetters } from 'vuex'

export default {
  name: 'ExpressionBlockPreview',
  props: ['conditions'],
  components: {
    LogicValueInput
  },
  computed: {
    ...mapGetters(['redirectionVariables']),
    variables () {
      // Add logic to combine redirection variables with company scm fields
      return this.redirectionVariables
    }
  },
  data () {
    return {
      destinations: [
        { key: 'stc', value: 'Landing Page' },
        { key: 'product', value: 'Product URL' },
        { key: 'brand', value: 'Brand URL' },
        { key: 'url', value: 'Custom URL' }
      ],
      conditionalOperators: [
        { key: 'and', value: 'and' },
        { key: 'or', value: 'or' }
      ],
      operators: [
        { key: '==', value: 'equals to' },
        { key: '!=', value: 'is not' },
        { key: 'in', value: 'is in' }
      ]
    }
  },
  methods: {
    getVariable (key) {
      return this.variables.find((item) => {
        return item.value === key
      })
    },

    getOperator (operator) {
      if (!operator) {
        return
      }
      return this.operators.find((op) => {
        return op.key === operator
      })
    }
  }
}

</script>

<style lang="sass" scoped>

.expressions-ctn
  .parameter, .value, .operator
    border: 1px solid rgba(0, 0, 0, 0.12)
    border-radius: 16px
    padding: 6px 10px

  .link-operator, .operator
    font-family: 'Roboto mono'

  .link-operator
    color: #999999

  .operator
    background-color: #e0f4fd
    color: black

</style>
