<template>
  <div class="logic-value-input">
    <v-autocomplete
      v-if="type === 'product_id'"
      :append-icon="mode === 'preview' ? null : '$dropdown'"
      :disabled="mode === 'preview'"
      v-model="autocompleteValue"
      item-value="id"
      item-text="sku"
      :multiple="operator === 'in'"
      deletable-chips
      :items="products"
      :search-input.sync="search"
      dense
      no-filter
      hide-details
      outlined
      persistent-placeholder
      placeholder="Select a product"
      @change="search=''"
    >
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs">
          <v-checkbox v-if="operator === 'in'" v-model="attrs.inputValue"></v-checkbox>
          <v-list-item-content>
            <v-list-item-title>
              {{item.sku}} - {{item.name}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:selection="{ item, attrs, selected, select }">
        <div>
          <div v-if="operator !== 'in'">
            {{item.sku}} - {{item.name}}
          </div>
          <v-chip
            :disabled="mode === 'preview'"
            v-else
            v-bind="attrs"
            :input-value="selected"
            :close="mode !== 'preview'"
            @click="select"
            @click:close="remove(item)"
          >
           {{ item.sku }} - {{ item.name }}
          </v-chip>
        </div>
      </template>
    </v-autocomplete>

    <v-autocomplete v-else-if="type === 'brand_id'"
      :append-icon="mode === 'preview' ? null : '$dropdown'"
      :disabled="mode === 'preview'"
      v-model="autocompleteValue"
      :chips="operator === 'in' "
      :multiple="operator === 'in'"
      deletable-chips
      :items="brands"
      :search-input.sync="search"
      item-text="name"
      item-value="id"
      dense
      hide-details
      outlined
      persistent-placeholder
      placeholder="Select a brand"
      @change="search=''"
    ></v-autocomplete>

    <v-autocomplete v-else-if="type === 'location_country_code'"
      :append-icon="mode === 'preview' ? null : '$dropdown'"
      :disabled="mode === 'preview'"
      v-model="autocompleteValue"
      :chips="operator === 'in' "
      :multiple="operator === 'in'"
      deletable-chips
      :items="countries"
      :search-input.sync="search"
      item-text="name"
      item-value="code"
      dense
      hide-details
      outlined
      persistent-placeholder
      placeholder="Select a country"
      @change="search=''"
    ></v-autocomplete>

    <v-autocomplete v-else-if="type === 'device_language'"
      :append-icon="mode === 'preview' ? null : '$dropdown'"
      :disabled="mode === 'preview'"
      v-model="autocompleteValue"
      :chips="operator === 'in' "
      :multiple="operator === 'in'"
      deletable-chips
      :items="languages"
      :search-input.sync="search"
      item-text="name"
      item-value="code"
      dense
      hide-details
      outlined
      persistent-placeholder
      placeholder="Select a device language"
      @change="search=''"
    ></v-autocomplete>

    <v-autocomplete v-else-if="type === 'scan_activation_status'"
      :append-icon="mode === 'preview' ? null : '$dropdown'"
      :disabled="mode === 'preview'"
      v-model="autocompleteValue"
      :chips="operator === 'in' "
      :multiple="operator === 'in'"
      deletable-chips
      :items="activationStatuses"
      :search-input.sync="search"
      item-text="description"
      item-value="value"
      dense
      hide-details
      outlined
      persistent-placeholder
      placeholder="Select a scan status"
      @change="search=''"
    ></v-autocomplete>

    <v-autocomplete v-else-if="type === 'scan_result'"
      :disabled="mode === 'preview'"
      :append-icon="mode === 'preview' ? null : '$dropdown'"
      v-model="autocompleteValue"
      :chips="operator === 'in' "
      :multiple="operator === 'in'"
      deletable-chips
      :items="scanResults"
      :search-input.sync="search"
      item-text="description"
      item-value="value"
      dense
      hide-details
      outlined
      persistent-placeholder
      placeholder="Select a scan result"
      @change="search=''"
    ></v-autocomplete>

    <v-autocomplete v-else-if="type === 'calculated_auth_result'"
      :append-icon="mode === 'preview' ? null : '$dropdown'"
      :disabled="mode === 'preview'"
      v-model="autocompleteValue"
      :chips="operator === 'in' "
      :multiple="operator === 'in'"
      deletable-chips
      :items="computedAuthResults"
      :search-input.sync="search"
      item-text="description"
      item-value="value"
      dense
      hide-details
      outlined
      persistent-placeholder
      placeholder="Select a scan result"
      @change="search=''"
    ></v-autocomplete>

    <v-autocomplete v-else-if="type === 'scan_app_slug'"
      :append-icon="mode === 'preview' ? null : '$dropdown'"
      :disabled="mode === 'preview'"
      v-model="autocompleteValue"
      :chips="operator === 'in' "
      :multiple="operator === 'in'"
      deletable-chips
      :items="apps"
      :search-input.sync="search"
      item-text="name"
      item-value="slug"
      dense
      hide-details
      outlined
      persistent-placeholder
      placeholder="Select a scan application"
      @change="search=''"
    ></v-autocomplete>

    <v-autocomplete v-else-if="type === 'scan_reason'"
      :append-icon="mode === 'preview' ? null : '$dropdown'"
      :disabled="mode === 'preview'"
      v-model="autocompleteValue"
      :chips="operator === 'in' "
      :multiple="operator === 'in'"
      deletable-chips
      :items="scanReasons"
      :search-input.sync="search"
      item-text="description"
      item-value="value"
      dense
      hide-details
      outlined
      persistent-placeholder
      placeholder="Select a scan reason"
      @change="search=''"
    ></v-autocomplete>

     <v-autocomplete v-else-if="type === 'scan_region_key'"
      :disabled="mode === 'preview'"
      :append-icon="mode === 'preview' ? null : '$dropdown'"
      v-model="autocompleteValue"
      :chips="operator === 'in' "
      :multiple="operator === 'in'"
      deletable-chips
      :items="customRegions"
      :search-input.sync="search"
      item-text="name"
      item-value="key"
      dense
      hide-details
      outlined
      persistent-placeholder
      placeholder="Select a custom region"
      @change="search=''"
    ></v-autocomplete>

    <v-combobox v-else-if="operator === 'in'"
      :disabled="mode === 'preview'"
      :append-icon="mode === 'preview' ? null : '$dropdown'"
      :value="this.value"
      v-model="comboBoxValue"
      chips
      multiple
      deletable-chips
      dense
      outlined
      hide-details
      @change="onComboChange"
    ></v-combobox>

    <v-text-field  :disabled="mode === 'preview'" outlined dense hide-details persistent-placeholder placeholder="Enter a value" v-else :value="this.value" @input="updateValue($event)"></v-text-field>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import Countries from '@/utils/countries'
import Languages from '@/utils/languages'

export default {
  name: 'LogicValueInput',
  props: ['type', 'value', 'operator', 'mode'],
  computed: {
    ...mapGetters(['activationStatuses', 'apps', 'customRegions', 'scanResults', 'computedAuthResults', 'scanReasons'])
  },
  watch: {

    type (newVal, oldVal) {
      if (this.mode === 'preview') {
        this.autocompleteValue = this.value
        this.comboBoxValue = this.value
      }
      this.search = ''
      if (this.operator === 'in') {
        this.$emit('update:value', [])
      } else {
        this.$emit('update:value', '')
      }
    },

    autocompleteValue (newVal) {
      this.$emit('update:value', newVal)
    },

    // autocompleteValue (newVal) {
    //   console.log('udp->', newVal, this.operator)
    //   if (this.operator !== 'in' && typeof newVal === 'object') {
    //     console.log('hack', newVal[newVal.length - 1])
    //     this.$emit('update:value', newVal[newVal.length - 1])
    //   } else {
    //     this.$emit('update:value', newVal)
    //   }
    //   this.$forceUpdate()
    // },

    value (newVal, oldVal) {
      this.autocompleteValue = newVal
      this.comboBoxValue = this.value
      // if (this.mode === 'preview') {
      //   this.autocompleteValue = newVal
      //   this.comboBoxValue = this.value
      // }
      this.$forceUpdate()
    },

    search () {
      if (this.type === 'product_id') {
        console.log(this.autocompleteValue, '-----')
        this.loadProducts(this.autocompleteValue)
      } else if (this.type === 'brand_id') {
        this.loadBrands()
      }
    }
  },

  data () {
    return {
      autocompleteValue: null,
      comboBoxValue: null,
      search: '',
      countries: Countries,
      languages: Languages,
      products: [],
      brands: []
    }
  },
  methods: {

    onComboChange () {
      this.$emit('update:value', this.comboBoxValue)
    },

    async loadProducts () {
      this.products = await this.$store.dispatch('searchProducts', { offset: 0, limit: 500, search: this.search })
    },

    async loadBrands () {
      this.brands = await this.$store.dispatch('searchBrands', { offset: 0, limit: 500, search: this.search })
    },

    updateValue (val) {
      this.$emit('update:value', val)
    },

    remove (item) {
      const index = this.autocompleteValue.indexOf(item.id)
      if (index >= 0) this.autocompleteValue.splice(index, 1)
    }
  },
  created () {
    if (!this.activationStatuses.length) {
      this.$store.dispatch('loadActivationStatuses')
    }

    if (!this.scanResults.length) {
      this.$store.dispatch('loadScanResults')
    }

    if (!this.apps.length) {
      this.$store.dispatch('loadAppsList')
    }

    if (!this.customRegions.length) {
      this.$store.dispatch('loadCustomRegions')
    }

    if (!this.computedAuthResults.length) {
      this.$store.dispatch('loadComputedAuthResults')
    }

    if (this.value) {
      // if (this.operator === 'in' && this.type !== 'location_country_code') {
      // } else {
      //   this.autocompleteValue = this.value
      // }
      if (this.operator !== 'in' && typeof this.value === 'object' && this.value.length) {
        this.$emit('update:value', this.value[0])
        this.autocompleteValue = this.value[0]
        this.comboBoxValue = this.value[0]
      } else {
        this.autocompleteValue = this.value
        this.comboBoxValue = this.value
      }
    }
  }
}

</script>

<style lang="sass" scoped>

</style>
