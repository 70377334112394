import * as types from '../mutations'
import codesService from '@/services/codes'

const state = {
  templates: []
}

const getters = {
  qrTemplates: state => state.templates
}

const actions = {

  async loadQrTemplates ({ commit }) {
    const templates = await codesService.loadQrTemplates()
    commit(types.SET_QR_TEMPLATES_LIST, templates)
  },

  async createQrTemplate ({ commit }, template) {
    const res = await codesService.createQrTemplate(template)
    commit(types.ADD_QR_TEMPLATE, res)
  },

  async updateQrTemplate ({ commit }, { id, template }) {
    const res = await codesService.updateQrTemplate(id, template)
    commit(types.UPDATE_QR_TEMPLATE, res)
  },

  async deleteQrTemplate ({ commit }, { id }) {
    await codesService.deleteQrTemplate(id)
    commit(types.DELETE_QR_TEMPLATE, { id: id })
  }
}

const mutations = {

  [types.SET_QR_TEMPLATES_LIST] (state, templates) {
    state.templates = templates
  },

  [types.ADD_QR_TEMPLATE] (state, template) {
    state.templates.unshift(template)
  },

  [types.UPDATE_QR_TEMPLATE] (state, template) {
    const index = state.templates.findIndex((t) => t.id === template.id)
    if (~index) {
      state.templates[index] = template
    }
  },

  [types.DELETE_QR_TEMPLATE] (state, { id }) {
    const index = state.templates.findIndex((t) => t.id === id)
    if (~index) {
      state.templates.splice(index, 1)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
