import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VCard, [_vm.form.id ? _c(VCardTitle, [_vm._v("Edit destination")]) : _vm._e(), !_vm.form.id && !_vm.hideHeader ? _c(VCardTitle, [_vm._v("Add destination")]) : _vm._e(), _c(VCardText, {
    staticClass: "destination-logic pa-0"
  }, [_c('div', {
    staticClass: "pa-5"
  }, [_c(VForm, {
    ref: "destination-form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('div', {
    staticClass: "destination-name-ctn my-3"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_vm._v(" Scan destination name: ")]), _c(VTextField, {
    attrs: {
      "rules": _vm.rules.name,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('div', {
    staticClass: "destination"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_vm._v(" Choose a scan destination: ")]), _c('div', {
    staticClass: "d-flex align-start py-3"
  }, [_c(VSelect, {
    staticClass: "flex-grow-0 py-0 mr-2",
    attrs: {
      "label": "Destination type",
      "items": _vm.destinations,
      "item-text": "value",
      "item-value": "key",
      "dense": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _vm.form.type === 'stc' ? _c('div', [_vm.form.type === 'stc' ? _c(VAutocomplete, {
    ref: "landingPageAutoComplete",
    attrs: {
      "items": _vm.filteredLandingPages,
      "loading": _vm.landingPagesLoading,
      "placeholder": "Landing page",
      "search-input": _vm.landingPageSearchText,
      "item-text": "name",
      "item-value": "id",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "no-filter": true,
      "no-data-text": _vm.landingPagesLoading ? 'Loading...' : 'No data found.'
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.landingPageSearchText = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.landingPageSearchText = $event;
      }
    },
    model: {
      value: _vm.form.stc_config,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "stc_config", $$v);
      },
      expression: "form.stc_config"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "section-hint"
  }, [_c('span', {
    staticClass: "my-2"
  }, [_c(VIcon, [_vm._v("mdi-information-outline")]), _vm._v(" To update the landing page content, go to: "), _c(VBtn, {
    staticClass: "my-2",
    attrs: {
      "color": "primary",
      "small": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goToLandingPage(_vm.form.stc_config);
      }
    }
  }, [_vm._v("Landing pages")])], 1)])], 1) : _vm._e(), _vm.form.type === 'url' ? _c(VTextField, {
    attrs: {
      "rules": _vm.rules.url,
      "validate-on-blur": "",
      "label": "URL",
      "outlined": "",
      "dense": ""
    },
    on: {
      "blur": _vm.onBlurUrl,
      "focus": _vm.onFocusUrl
    },
    model: {
      value: _vm.form.url,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "url", $$v);
      },
      expression: "form.url"
    }
  }) : _vm._e()], 1)])]), _c('div', {
    staticClass: "conditions mt-3"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_vm._v(" Conditions: ")]), _vm.form.conditions && (_vm.form.conditions.or && _vm.form.conditions.or.length || _vm.form.conditions.and && _vm.form.conditions.and.length) ? _c('div', {
    staticClass: "section-hint"
  }, [!_vm.deleteMode ? _c('div', {
    staticClass: "py-2"
  }, [_c(VIcon, [_vm._v("mdi-information-outline")]), _vm._v(" To delete conditions: "), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "red"
    },
    on: {
      "click": function click($event) {
        _vm.deleteMode = !_vm.deleteMode;
      }
    }
  }, [_vm._v(" click here ")])], 1) : _vm._e(), _vm.deleteMode ? _c(VBtn, {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.deleteMode = !_vm.deleteMode;
      }
    }
  }, [_vm._v(" Done deleting ")]) : _vm._e()], 1) : _vm._e(), !_vm.form.conditions || _vm.form.conditions && _vm.form.conditions['=='] ? _c('div', [_c('div', {
    staticClass: "description"
  }, [_vm._v(" No conditions set yet. All scans will be redirected to the selected destination. Start creating conditions for specific scans. ")]), _c('div', {
    staticClass: "d-flex my-2 justify-center align-center"
  }, [_c(VBtn, {
    attrs: {
      "large": "",
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.addFirstConditionBlock();
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-plus-circle-outline")]), _vm._v(" Create conditions ")], 1)], 1)]) : _c('div', [_vm._l(_vm.form.conditions, function (mainConditions, mainOperator) {
    return _c('div', {
      key: "main-".concat(mainOperator)
    }, _vm._l(_vm.form.conditions[mainOperator], function (blocks, index) {
      return _c('div', {
        key: "block-".concat(index)
      }, [_vm._l(blocks, function (conditions, operator) {
        return _c('div', {
          key: "block-".concat(index, "-conditions-").concat(operator)
        }, [operator === 'or' || operator === 'and' ? _c('div', {
          staticClass: "nested-conditions mt-4"
        }, [!_vm.deleteMode && conditions.length > 1 ? _c('div', {
          staticClass: "condition-header d-flex justify-center"
        }, [_c('div', {
          staticClass: "condition-header-ctn py-4 px-5 d-flex align-center"
        }, [_vm._v(" Expressions inside are connect by "), _c(VMenu, {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('button', _vm._g(_vm._b({
                staticClass: "d-flex ml-2 align-center justify-center font-mono conditional-operator"
              }, 'button', attrs, false), on), [_vm._v(" " + _vm._s(operator) + " "), _c(VIcon, {
                attrs: {
                  "color": "primary"
                }
              }, [_vm._v(" mdi-menu-down ")])], 1)];
            }
          }], null, true)
        }, [_c(VList, _vm._l(_vm.conditionalOperators, function (item) {
          return _c(VListItem, {
            key: item.key
          }, [_c(VListItemTitle, {
            on: {
              "click": function click($event) {
                return _vm.selectOperator(item.key, "".concat(mainOperator, ".").concat(index, ".").concat(operator));
              }
            }
          }, [_vm._v(_vm._s(item.value))])], 1);
        }), 1)], 1)], 1)]) : _vm._e(), _vm._l(conditions, function (nestedBlocks, nestedBlocksIndex) {
          return _c('div', {
            key: "block-".concat(index, "-conditions-").concat(operator, "-nested-blocks-").concat(nestedBlocksIndex)
          }, _vm._l(nestedBlocks, function (nestedConditions, nestedOperator) {
            return _c('div', {
              key: "block-".concat(index, "-conditions-").concat(operator, "-nested-blocks-").concat(nestedBlocksIndex, "-nested-op-").concat(nestedOperator)
            }, [_c('div', {
              staticClass: "d-inline-flex my-2 align-center"
            }, [_c(VBtn, {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: _vm.deleteMode,
                expression: "deleteMode"
              }],
              attrs: {
                "color": "red",
                "icon": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.deleteNestedConditionBlock(conditions, nestedBlocksIndex, _vm.form.conditions[mainOperator], index);
                }
              }
            }, [_c(VIcon, [_vm._v("mdi-minus-circle-outline")])], 1), _vm.getTypeof(nestedConditions[0].var) === 'object' || _vm.getTypeof(nestedConditions[0].var) === 'string' && nestedConditions[0].var.indexOf('scm') === -1 ? _c(VAutocomplete, {
              staticClass: "parameter-select mx-1",
              attrs: {
                "items": _vm.variables,
                "item-text": "description",
                "item-value": "value",
                "dense": "",
                "hide-details": "",
                "outlined": "",
                "placeholder": "Select a parameter"
              },
              on: {
                "change": function change($event) {
                  return _vm.onVariableChange(nestedConditions, nestedOperator);
                }
              },
              model: {
                value: nestedConditions[0].var,
                callback: function callback($$v) {
                  _vm.$set(nestedConditions[0], "var", $$v);
                },
                expression: "nestedConditions[0].var"
              }
            }) : _vm._e(), nestedConditions[0].var !== null && _vm.getTypeof(nestedConditions[0].var) === 'string' && nestedConditions[0].var.indexOf('scm') > -1 ? _c('div', {
              staticClass: "d-flex align-center outlined"
            }, [_vm._v(" SCM Field "), _c(VBtn, {
              staticClass: "ml-2",
              attrs: {
                "icon": ""
              },
              on: {
                "click": function click($event) {
                  nestedConditions[0].var = '';
                }
              }
            }, [_c(VIcon, [_vm._v("mdi-close")])], 1)], 1) : _vm._e(), _vm.getTypeof(nestedConditions[0].var) === 'string' && nestedConditions[0].var.indexOf('scm') >= 0 ? _c(VCombobox, {
              staticClass: "parameter-select mx-1",
              attrs: {
                "value": nestedConditions[0].var === 'scm' ? '' : nestedConditions[0].var,
                "label": "SCM field",
                "outlined": "",
                "hide-details": "",
                "dense": "",
                "item-text": "description",
                "item-value": "key",
                "return-object": false,
                "items": _vm.rulesScmFields
              },
              on: {
                "change": function change($event) {
                  return _vm.onScmChange($event, nestedConditions[0]);
                }
              },
              scopedSlots: _vm._u([{
                key: "selection",
                fn: function fn(data) {
                  return [_vm._v(" " + _vm._s(_vm.rulesScmFields.find(function (scm) {
                    return scm.key === data.item;
                  }) ? _vm.rulesScmFields.find(function (scm) {
                    return scm.key === data.item;
                  }).description : data.item.split('scm_')[1]) + " ")];
                }
              }], null, true),
              model: {
                value: nestedConditions[0].var,
                callback: function callback($$v) {
                  _vm.$set(nestedConditions[0], "var", $$v);
                },
                expression: "nestedConditions[0].var"
              }
            }) : _vm._e(), _c(VMenu, {
              attrs: {
                "offset-y": ""
              },
              scopedSlots: _vm._u([{
                key: "activator",
                fn: function fn(_ref2) {
                  var on = _ref2.on,
                    attrs = _ref2.attrs;
                  return [_c(VBtn, _vm._g(_vm._b({
                    staticClass: "operator-btn mx-1",
                    attrs: {
                      "outlined": ""
                    }
                  }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.getOperator(nestedOperator).value) + " "), _c(VIcon, {
                    attrs: {
                      "color": "primary"
                    }
                  }, [_vm._v(" mdi-menu-down ")])], 1)];
                }
              }], null, true)
            }, [_c(VList, _vm._l(_vm.operators, function (item) {
              return _c(VListItem, {
                key: item.key
              }, [_c(VListItemTitle, {
                on: {
                  "click": function click($event) {
                    return _vm.selectOperator(item.key, "".concat(mainOperator, ".").concat(index, ".").concat(operator, ".").concat(nestedBlocksIndex, ".").concat(nestedOperator));
                  }
                }
              }, [_vm._v(_vm._s(item.value))])], 1);
            }), 1)], 1), _c('div', {
              staticClass: "value-input mx-1"
            }, [_c('logic-value-input', {
              attrs: {
                "operator": nestedOperator,
                "type": nestedConditions[0].var,
                "value": nestedConditions[1]
              },
              on: {
                "update:value": function updateValue($event) {
                  return _vm.$set(nestedConditions, 1, $event);
                }
              }
            })], 1), nestedBlocksIndex < conditions.length - 1 ? _c('div', {
              staticClass: "flex-grow-1 mx-1 font-mono"
            }, [_vm._v(" " + _vm._s(operator) + " ")]) : _c(VBtn, {
              attrs: {
                "icon": "",
                "color": "primary"
              },
              on: {
                "click": function click($event) {
                  return _vm.addNestedConditionBlock("".concat(mainOperator, ".").concat(index, ".").concat(operator));
                }
              }
            }, [_c(VIcon, [_vm._v("mdi-plus-circle-outline")])], 1)], 1)]);
          }), 0);
        })], 2) : _c('div', [_c('div', {
          staticClass: "d-inline-flex"
        }, [_c(VSelect, {
          attrs: {
            "items": _vm.variables,
            "dense": "",
            "hide-details": "",
            "item-text": "description",
            "item-value": "value",
            "outlined": ""
          },
          on: {
            "change": function change($event) {
              return _vm.onVariableChange(conditions, operator);
            }
          },
          model: {
            value: conditions[0].var,
            callback: function callback($$v) {
              _vm.$set(conditions[0], "var", $$v);
            },
            expression: "conditions[0].var"
          }
        }), _c(VMenu, {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c(VBtn, _vm._g(_vm._b({
                attrs: {
                  "color": "primary",
                  "dark": ""
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(operator) + " ")])];
            }
          }], null, true)
        }, [_c(VList, _vm._l(_vm.operators, function (item) {
          return _c(VListItem, {
            key: item.key
          }, [_c(VListItemTitle, {
            staticClass: "pointer",
            on: {
              "click": function click($event) {
                return _vm.selectOperator(item.key, "".concat(mainOperator, ".").concat(index, ".").concat(operator));
              }
            }
          }, [_vm._v(_vm._s(item.value))])], 1);
        }), 1)], 1), _c('div', {
          staticClass: "value-input"
        }, [_c('logic-value-input', {
          attrs: {
            "operator": operator,
            "type": conditions[0].var,
            "value": conditions[1]
          },
          on: {
            "update:value": function updateValue($event) {
              return _vm.$set(conditions, 1, $event);
            }
          }
        })], 1)], 1)])]);
      }), index != _vm.form.conditions[mainOperator].length - 1 ? _c('div', {
        staticClass: "expression-connector"
      }, [_c('div', {
        staticClass: "connector"
      }), _c('div', {
        staticClass: "operator"
      }, [_vm._v(_vm._s(mainOperator))]), _c('div', {
        staticClass: "connector"
      })]) : _vm._e()], 2);
    }), 0);
  }), _c('div', {
    staticClass: "expression-connector"
  }, [_vm.form.conditions[Object.keys(_vm.form.conditions)[0]].length ? _c('div', {
    staticClass: "connector"
  }) : _vm._e(), _c('div', {
    staticClass: "add-block-ctn"
  }, [!_vm.form.conditions[Object.keys(_vm.form.conditions)[0]].length ? _c('div', {
    staticClass: "title"
  }, [_vm._v("Get started by adding a condition:")]) : _vm._e(), _c(VBtn, {
    staticClass: "my-2",
    attrs: {
      "large": "",
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.addConditionBlock(Object.keys(_vm.form.conditions)[0]);
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-plus-circle-outline")]), _vm._v(" Add Expression Block ")], 1), _c('br'), _vm.form.conditions[Object.keys(_vm.form.conditions)[0]].length ? _c('div', {
    staticClass: "d-flex align-content justify-center"
  }, [_c('span', {}, [_vm._v(" Connection expression blocks by: ")]), _c(VMenu, {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('button', _vm._g(_vm._b({
          staticClass: "d-flex ml-2 align-center justify-center conditional-operator"
        }, 'button', attrs, false), on), [_vm._v(" " + _vm._s(Object.keys(_vm.form.conditions)[0]) + " "), _c(VIcon, {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v(" mdi-menu-down ")])], 1)];
      }
    }], null, false, 2706536540)
  }, [_c(VList, _vm._l(_vm.conditionalOperators, function (item) {
    return _c(VListItem, {
      key: item.key
    }, [_c(VListItemTitle, {
      on: {
        "click": function click($event) {
          _vm.selectOperator(item.key, Object.keys(_vm.form.conditions)[0]);
        }
      }
    }, [_vm._v(_vm._s(item.value))])], 1);
  }), 1)], 1)], 1) : _vm._e()], 1), _vm.form.conditions[Object.keys(_vm.form.conditions)[0]].length ? _c('div', {
    staticClass: "connector"
  }) : _vm._e(), _vm.form.conditions[Object.keys(_vm.form.conditions)[0]].length ? _c('div', {
    staticClass: "operator"
  }, [_vm._v("end.")]) : _vm._e()])], 2)])], 1), _c(VDivider)], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v("Cancel")]), !_vm.deleteMode ? _c(VBtn, {
    attrs: {
      "loading": _vm.saving,
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("Save")]) : _vm._e(), _vm.deleteMode ? _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.deleteMode = !_vm.deleteMode;
      }
    }
  }, [_vm._v("Done Deleting")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }