import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import ArrowDown from '@/components/icons/ArrowDown'
import ArrowUp from '@/components/icons/ArrowUp'
import CSV from '@/components/icons/CSV'
import Product from '@/components/icons/Product'
import QrCode from '@/components/icons/QrCode'
import Scan from '@/components/icons/Scan'
import TetrisIcon from '@/components/icons/TetrisIcon'
import Redirection from '@/components/icons/SimulateRedirectionIcon'
import Upgrade from '@/components/icons/Upgrade'
import User from '@/components/icons/User'
import GS1EditIcon from '@/components/icons/GS1EditIcon'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      'arrow-up': {
        component: ArrowUp
      },
      'arrow-down': {
        component: ArrowDown
      },
      csv: {
        component: CSV
      },
      product: {
        component: Product
      },
      'qr-code': {
        component: QrCode
      },
      scan: {
        component: Scan
      },
      tetris: {
        component: TetrisIcon
      },
      user: {
        component: User
      },
      upgrade: {
        component: Upgrade
      },
      redirection: {
        component: Redirection
      },
      'gs1-edit-icon': {
        component: GS1EditIcon
      }
    }
  },
  theme: {
    themes: {
      light: {
        primary: '#00A1EC',
        secondary: '#15bbf0',
        accent: '#15bbf0'
      }
    }
  }
})
