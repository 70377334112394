export default {
  'epac-connect': {
    name: 'ePac Connect',
    features: {
      // Timeframe Filter
      'dashboard:filters:timeframe': {
        is_available: false
      },

      'dashboard:map': {
        is_available: false
      },

      // Data Download
      'dashboard:download': {
        is_available: false
      }
    }
  },

  'epac-connect-plus': {
    name: 'ePac Connect Plus',
    features: {
      // Timeframe Filter
      'dashboard:filters:timeframe': {
        is_available: true
      },

      // Data Download
      'dashboard:download': {
        is_available: false
      },

      'dashboard:map': {
        is_available: false
      }

    }
  },

  'epac-connect-premium': {
    name: 'ePac Connect Premium',
    features: {
      // Timeframe Filter
      'dashboard:filters:timeframe': {
        is_available: true
      },

      // Data Download
      'dashboard:download': {
        is_available: true
      },

      'dashboard:map': {
        is_available: true
      },

      'product:description': {
        is_available: true
      }
    }
  },

  'saas-free': {
    name: 'Scantrust Free Plan',
    features: {
      // Timeframe Filter
      'dashboard:filters:timeframe': {
        is_available: false
      },

      'dashboard:map': {
        is_available: false
      },

      // Data Download
      'dashboard:download': {
        is_available: false
      },

      'products:csv': {
        is_available: true
      },

      'product:update-redirection': {
        is_available: true
      },

      'codes:list': {
        is_available: true
      },

      tags: {
        is_available: true,
        has_limit: true,
        limit: 5
      }
    }
  },

  'full:qr-manager': {
    name: 'Scantrust Enterprise + QR',
    features: {
      // Timeframe Filter
      'dashboard:filters:timeframe': {
        is_available: true
      },

      'dashboard:map': {
        is_available: true
      },

      // Data Download
      'dashboard:download': {
        is_available: true
      },

      'products:csv': {
        is_available: true
      },

      'product:update-redirection': {
        is_available: false
      },

      'codes:list': {
        is_available: true
      },

      tags: {
        is_available: true,
        has_limit: false
      }
    }
  }
}
