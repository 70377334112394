import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm$simulationResults5, _vm$simulationResults6, _vm$simulationResults7, _vm$simulationResults8, _vm$simulationResults9;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "redirection"
  }, [!_vm.loaded ? _c('div', {
    staticClass: "d-flex my-6 align-center justify-center"
  }, [_c(VProgressCircular, {
    attrs: {
      "size": "50",
      "width": "3",
      "color": "primary",
      "indeterminate": ""
    }
  })], 1) : _vm._e(), _vm.loaded ? _c('div', {
    staticClass: "d-flex loaded-ctn"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.redirectionSimulator,
      expression: "!redirectionSimulator"
    }],
    staticClass: "rules-filter-ctn flex-column"
  }, [_c('rules-filters'), _c(VBtn, {
    staticClass: "mt-auto",
    attrs: {
      "raised": ""
    },
    on: {
      "click": function click($event) {
        return _vm.toggleSimulation();
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("$redirection")]), _vm._v(" Simulate scan ")], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.redirectionSimulator,
      expression: "redirectionSimulator"
    }],
    staticClass: "simulator-ctn flex-column"
  }, [_c('simulator-sidebar', {
    on: {
      "on-simulation-end": _vm.onSimulationEnd
    }
  }), _c(VBtn, {
    staticClass: "mt-auto",
    attrs: {
      "raised": ""
    },
    on: {
      "click": function click($event) {
        return _vm.toggleSimulation();
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-close")]), _vm._v(" Quit Simulator ")], 1)], 1), _c('div', {
    staticClass: "rule-wrapper"
  }, [_vm.loaded ? _c('div', {
    staticClass: "rules-ctn"
  }, [!_vm.redirectionRules.length && (_vm.ruleActiveFilters.is_archived === null || _vm.ruleActiveFilters.is_archived === false) ? _c('div', {
    staticClass: "no-rule-ctn"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("All scans are currently following the default rule.")]), _c('div', {
    staticClass: "description"
  }, [_vm._v("To redirect scans to other destinations, create your own rule below:")])]) : _vm._e(), !_vm.redirectionSimulator ? _c('div', {
    staticClass: "new-rule-ctn"
  }, [_c(VBtn, {
    staticClass: "my-2",
    attrs: {
      "color": "primary",
      "large": ""
    },
    on: {
      "click": function click($event) {
        return _vm.createRule();
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-plus-circle-outline")]), _vm._v(" Create a new Rule ")], 1), _c('div', {
    staticClass: "connector"
  })], 1) : _vm._e(), _c('draggable', {
    staticClass: "drop-area",
    class: {
      'mt-10': _vm.redirectionSimulator
    },
    attrs: {
      "group": "a"
    },
    model: {
      value: _vm.redirectionRules,
      callback: function callback($$v) {
        _vm.redirectionRules = $$v;
      },
      expression: "redirectionRules"
    }
  }, _vm._l(_vm.redirectionRules, function (rule) {
    var _vm$simulationResults, _vm$simulationResults2, _vm$simulationResults3, _vm$simulationResults4;
    return _c('div', {
      key: "rule-".concat(rule.id),
      class: {
        'simulator': _vm.redirectionSimulator
      },
      attrs: {
        "id": "rule-".concat(rule.id)
      }
    }, [_c('div', {
      staticClass: "rule d-flex align-center py-3 px-1",
      class: [{
        'selected': !_vm.simulating && _vm.redirectionSimulator && ((_vm$simulationResults = _vm.simulationResults) === null || _vm$simulationResults === void 0 ? void 0 : (_vm$simulationResults2 = _vm$simulationResults.matched_rule) === null || _vm$simulationResults2 === void 0 ? void 0 : _vm$simulationResults2.id) === rule.id,
        'expanded': rule.id === _vm.selectedRuleId
      }, _vm.getRuleStatus(rule)],
      on: {
        "click": function click($event) {
          return _vm.toggleRule(rule);
        }
      }
    }, [_c(VIcon, {
      staticClass: "grab"
    }, [_vm._v("mdi-drag-vertical")]), _c('div', {
      staticClass: "flex-grow-1"
    }, [_c('div', [_vm._v(_vm._s(rule.name))]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: rule.id !== _vm.selectedRuleId,
        expression: "rule.id !== selectedRuleId"
      }]
    }, _vm._l(rule.campaigns, function (campaign, index) {
      var _vm$getCampaign, _vm$getCampaign2;
      return _c('span', {
        key: "rule-".concat(rule.id, "-campaign-").concat(campaign)
      }, [_vm._v(" " + _vm._s((_vm$getCampaign = _vm.getCampaign(campaign)) !== null && _vm$getCampaign !== void 0 && _vm$getCampaign.name ? (_vm$getCampaign2 = _vm.getCampaign(campaign)) === null || _vm$getCampaign2 === void 0 ? void 0 : _vm$getCampaign2.name : "Archived Campaign (ID ".concat(campaign, ")")) + _vm._s(index < rule.campaigns.length - 1 ? ',' : '') + " ")]);
    }), 0)]), _c('div', {
      staticClass: "rule-status pr-2 font-weight-bold"
    }, [_vm.getRuleStatus(rule) === 'archived' ? _c('div', [_vm._v("Archived")]) : _vm._e(), _vm.getRuleStatus(rule) === 'live' ? _c('div', [_vm._v("Live now")]) : _vm._e(), _vm.getRuleStatus(rule) === 'paused' ? _c('div', [_vm._v("Paused")]) : _vm._e(), _vm.getRuleStatus(rule) === 'expired' ? _c('div', [_vm._v("Expired on " + _vm._s(_vm.formatDate(rule.active_to)))]) : _vm._e(), _vm.getRuleStatus(rule) === 'expiring' ? _c('div', [_vm._v("Expiring on " + _vm._s(_vm.formatDate(rule.active_to)))]) : _vm._e(), _vm.getRuleStatus(rule) === 'planned' ? _c('div', [_vm._v("Going live on " + _vm._s(_vm.formatDate(rule.active_from)))]) : _vm._e()]), _c(VBtn, {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: rule.id !== _vm.selectedRuleId,
        expression: "rule.id !== selectedRuleId"
      }],
      attrs: {
        "icon": ""
      }
    }, [_c(VIcon, [_vm._v("mdi-unfold-more-horizontal")])], 1), _c(VBtn, {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: rule.id === _vm.selectedRuleId,
        expression: "rule.id === selectedRuleId"
      }],
      attrs: {
        "icon": ""
      }
    }, [_c(VIcon, [_vm._v("mdi-unfold-less-horizontal")])], 1), _vm.redirectionSimulator ? _c('div', [_vm.simulating ? _c(VProgressCircular, {
      attrs: {
        "indeterminate": "",
        "color": "primary",
        "size": "16",
        "width": "2"
      }
    }) : _vm.getRuleSimulationStatus(rule) === 'fail' ? _c(VIcon, {
      attrs: {
        "color": "red"
      }
    }, [_vm._v("mdi-close")]) : _vm.getRuleSimulationStatus(rule) === 'success' ? _c(VIcon, {
      attrs: {
        "color": "green"
      }
    }, [_vm._v("mdi-flag")]) : _vm._e()], 1) : _vm._e(), !_vm.redirectionSimulator ? _c(VMenu, {
      attrs: {
        "offset-x": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c(VBtn, _vm._g(_vm._b({
            attrs: {
              "icon": ""
            }
          }, 'v-btn', attrs, false), on), [_c(VIcon, [_vm._v("mdi-dots-vertical")])], 1)];
        }
      }], null, true)
    }, [_c(VList, [_c(VListItem, {
      on: {
        "click": function click($event) {
          return _vm.editRule(rule);
        }
      }
    }, [_c(VListItemTitle, [_vm._v("Edit")])], 1), rule.is_paused && !rule.is_archived ? _c(VListItem, {
      on: {
        "click": function click($event) {
          return _vm.startRule(rule);
        }
      }
    }, [_c(VListItemTitle, [_vm._v("Go Live")])], 1) : _vm._e(), !rule.is_paused && !rule.is_archived ? _c(VListItem, {
      on: {
        "click": function click($event) {
          return _vm.pauseRule(rule);
        }
      }
    }, [_c(VListItemTitle, [_vm._v("Pause")])], 1) : _vm._e(), _c(VListItem, {
      on: {
        "click": function click($event) {
          return _vm.toggleArchiveRule(rule);
        }
      }
    }, [rule.is_archived ? _c(VListItemTitle, [_vm._v("Unarchive")]) : _c(VListItemTitle, [_vm._v("Archive")])], 1)], 1)], 1) : _vm._e()], 1), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.selectedRuleId === rule.id,
        expression: "selectedRuleId === rule.id"
      }],
      staticClass: "rule-content",
      class: {
        'selected': !_vm.simulating && _vm.redirectionSimulator && ((_vm$simulationResults3 = _vm.simulationResults) === null || _vm$simulationResults3 === void 0 ? void 0 : (_vm$simulationResults4 = _vm$simulationResults3.matched_rule) === null || _vm$simulationResults4 === void 0 ? void 0 : _vm$simulationResults4.id) === rule.id
      }
    }, [_c('rule-preview', {
      attrs: {
        "rule": rule
      }
    })], 1), _c('div', {
      staticClass: "connector"
    })]);
  }), 0), _c('div', {
    staticClass: "default-rule-ctn mb-5"
  }, [_c('div', {
    staticClass: "default-rule-header",
    class: {
      'simulator': _vm.redirectionSimulator,
      'selected': ((_vm$simulationResults5 = _vm.simulationResults) === null || _vm$simulationResults5 === void 0 ? void 0 : _vm$simulationResults5.is_fallback) && !((_vm$simulationResults6 = _vm.simulationResults) !== null && _vm$simulationResults6 !== void 0 && (_vm$simulationResults7 = _vm$simulationResults6.matched_destination) !== null && _vm$simulationResults7 !== void 0 && _vm$simulationResults7.id)
    }
  }, [_vm._v(" Default rule ")]), _c('div', {
    staticClass: "default-rule-content"
  }, [_c('div', {
    staticClass: "d-flex align-center justify-center"
  }, [_c('div', {
    staticClass: "outlined-pill"
  }, [_vm._v("If none of the above")]), _c(VIcon, [_vm._v("mdi-arrow-right")]), !_vm.redirectionSimulator || !_vm.simulationResults.elapsed_ms || (_vm$simulationResults8 = _vm.simulationResults) !== null && _vm$simulationResults8 !== void 0 && (_vm$simulationResults9 = _vm$simulationResults8.matched_destination) !== null && _vm$simulationResults9 !== void 0 && _vm$simulationResults9.id ? _c('div', {
    staticClass: "outlined-pill"
  }, [_vm._v("Product URL")]) : _vm.simulationResults.elapsed_ms ? _c('div', {
    staticClass: "outlined-pill selected"
  }, [_vm.simulationResults.fallback_type === 'product_url' ? _c('span', [_vm._v("Product URL")]) : _vm._e(), _vm.simulationResults.fallback_type === 'brand_url' ? _c('span', [_vm._v("Brand URL")]) : _vm._e(), _vm.simulationResults.fallback_type === 'company_url' ? _c('span', [_vm._v("Company URL")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex mt-5 align-start justify-center"
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-information-outline")]), _vm._m(0)], 1)])])], 1) : _vm._e()])]) : _vm._e(), _c(VDialog, {
    attrs: {
      "width": "900",
      "persistent": true
    },
    model: {
      value: _vm.ruleDialog,
      callback: function callback($$v) {
        _vm.ruleDialog = $$v;
      },
      expression: "ruleDialog"
    }
  }, [_c('rule-form', {
    attrs: {
      "rule": this.$route.params && this.$route.params.rule ? this.$route.params.rule : {},
      "rule-id": this.$route.params && this.$route.params.id ? this.$route.params.id : null
    },
    on: {
      "on-rule-created": _vm.onRuleCreated,
      "on-close-dialog": function onCloseDialog($event) {
        return _vm.handleCloseDialog();
      }
    }
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hint"
  }, [_vm._v(" If the Product URL is empty, the "), _c('span', {
    staticClass: "font-medium"
  }, [_vm._v("Brand URL")]), _vm._v(" will be used."), _c('br'), _vm._v(" If the Brand URL is empty, the "), _c('span', {
    staticClass: "font-medium"
  }, [_vm._v("Company URL")]), _vm._v(" will be used."), _c('br'), _vm._v(" If the company URL is empty, the final destination will be "), _c('span', {
    staticClass: "font-medium"
  }, [_vm._v("www.scantrust.com")]), _c('br')]);
}]

export { render, staticRenderFns }