<template>
  <div class="destination-card">
    <div class="card-header d-flex align-center justify-space-between" :class="{ 'selected': selected }">
      <v-icon>mdi-drag-vertical</v-icon>
      <div class="destination-name font-weight-medium mr-auto">{{destination.name}}</div>
      <div  v-if="!redirectionSimulator" class="actions-ctn">
        <v-btn @click="editDestination()" color="primary" icon>
          <v-icon>mdi-circle-edit-outline</v-icon>
        </v-btn>
        <v-btn @click="deleteDialog = true" color="red" icon>
          <v-icon>mdi-minus-circle-outline</v-icon>
        </v-btn>
      </div>
      <div v-else class="pa-2">
        <v-progress-circular v-if="simulating" indeterminate color="primary" size="16" width="2"></v-progress-circular>
        <v-icon v-else-if="getDestinationSimulationStatus(destination) === 'fail'" color="red">mdi-close</v-icon>
        <v-icon v-else-if="getDestinationSimulationStatus(destination) === 'success'" color="green">mdi-flag</v-icon>
      </div>
    </div>
    <div class="card-content pa-3">
      <div v-if="computedConditions.length">
        <div class="summary-ctn">
          <div class="expression d-flex justify-center align-center my-0" v-for="(condition, index) in computedConditions" :key="`summary-${index}`">
            <expression-block-preview :conditions="condition"></expression-block-preview>
          </div>
        </div>
        <v-icon>mdi-arrow-down</v-icon>
        <div class="redirection mx-2">
          <div v-if="destination.type === 'product'">
            Product URL
          </div>

          <div v-else-if="destination.type === 'brand'">
            Brand URL
          </div>

          <div v-else-if="destination.type === 'stc'">
            Landing page
          </div>

          <div v-else-if="destination.type === 'elabel'">
            e-label landing page
          </div>

          <div v-else-if="destination.type === 'url'">
            URL: {{destination.url}}
          </div>
        </div>
        <div v-if="redirectionSimulator && hasRedirectionFallback(destination)" >
          Fallback !
        </div>
      </div>
      <div v-else class="d-flex align-center justify-center">
        <div v-if="!isOnlyDestination">If none of the above, redirect to</div>
        <div v-else>Always redirects to</div>
        <span class="redirection mx-2">
          <div v-if="destination.type === 'product'">
            Product URL
          </div>

          <div v-else-if="destination.type === 'brand'">
            Brand URL
          </div>

          <div v-else-if="destination.type === 'stc'">
            Landing page: <span class="font-weight-bold">{{getLandingPage(destination.stc_config).name}}</span>
          </div>

          <div v-else-if="destination.type === 'elabel'">
            e-label Landing page
          </div>

          <div v-else-if="destination.type === 'url'">
            URL: {{destination.url}}
          </div>
        </span>
      </div>
      <div class="text-red" v-if="redirectionSimulator && hasRedirectionFallback(destination)" >
        <span>
          <span v-if="destination.type === 'product'">
            Product URL is not set for this product
          </span>

          <span v-else-if="destination.type === 'brand'">
            Brand URL is not set for this brand.
          </span>

          <span v-else-if="destination.type === 'stc'">
            Landing page: <span class="font-weight-bold">{{getLandingPage(destination.stc_config).name}}</span>
          </span>

          <span v-else-if="destination.type === 'elabel'">
            e-label Landing page is not published
          </span>
          <span v-else-if="destination.type === 'url'">
            URL: {{destination.url}}
          </span>
        </span>
        <br>
         Will be redirected to:
        <span v-if="simulationResults.fallback_type === 'product_url'">Product URL</span>
        <span v-if="simulationResults.fallback_type === 'brand_url'">Brand URL</span>
        <span v-if="simulationResults.fallback_type === 'company_url'">Company Website</span>
        instead
      </div>
    </div>

    <v-dialog v-model="deleteDialog" width="600">
      <v-card>
        <v-card-title>Delete destination</v-card-title>
        <v-card-text class="pa-2">
          Do you really want to delete the destination: {{ destination.name }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteDialog = false" text >cancel</v-btn>
          <v-btn @click="deleteDestination" text :loading="deleting" color="red">confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="destinationDialog" width="900" scrollable :persistent="true">
      <destination-logic
        :rule="rule"
        :destination="destination"
        @cancel="onDestinationCancel"
        @destination-updated="onDestinationUpdated"
        >
      </destination-logic>
    </v-dialog>
  </div>
</template>

<script>

import ExpressionBlockPreview from '@/components/redirection/ExpressionBlockPreview'
import { mapGetters } from 'vuex'
import DestinationLogic from '@/components/redirection/DestinationLogic'

export default {
  name: 'DestinationCard',
  props: ['destination', 'rule', 'isOnlyDestination', 'selected'],
  components: {
    ExpressionBlockPreview,
    DestinationLogic
  },
  computed: {
    ...mapGetters(['getLandingPage', 'redirectionSimulator', 'simulating', 'simulationResults']),

    computedConditions () {
      if (this.destination.conditions && this.destination.conditions['==']) {
        return []
      } else if (Object.keys(this.destination.conditions).length > 0 && this.destination.conditions[Object.keys(this.destination.conditions)[0]]) {
        return this.destination.conditions[Object.keys(this.destination.conditions)[0]]
      }
      return []
    }
  },
  data () {
    return {
      destinationDialog: false,
      deleting: false,
      deleteDialog: false,
      bracket: require('@/assets/curly-bracket.svg')
    }
  },
  methods: {

    hasRedirectionFallback (destination) {
      return this.simulationResults.matched_destination && this.simulationResults.matched_destination.id === destination.id && this.simulationResults.is_fallback
    },

    getDestinationSimulationStatus (destination) {
      if (this.simulationResults.matched_destination) {
        if (destination.id === this.simulationResults.matched_destination.id) {
          return 'success'
        }
        if (destination.position <= this.simulationResults.matched_destination.position) {
          return 'fail'
        }
      }
    },

    onDestinationCancel () {
      this.destinationDialog = false
    },

    onDestinationUpdated (destination) {
      this.destinationDialog = false
      this.$emit('destination-updated', destination)
    },

    editDestination () {
      this.destinationDialog = true
      // this.$router.push({ name: 'destination.edit', params: { destinationId: this.destination.id, id: this.rule.id, destination: this.destination, rule: this.rule } })
    },

    async deleteDestination () {
      this.deleting = true
      await this.$store.dispatch('deleteRuleDestination', { ruleId: this.rule.id, destinationId: this.destination.id })
      this.deleting = false
      this.deleteDialog = false
    }
  }
}

</script>

<style lang="sass" scoped>

.destination-card
  border-radius: 6px
  border: 1px solid rgba(0, 0, 0, 0.12)

.card-header
  background: #f1f1f1
  .destination-name
    text-transform: uppercase
    color: #777777
    letter-spacing: 1.5px

.selected
  background: #b9f0ff

.card-content, .expression
  margin-left: auto
  margin-right: auto
  text-align: center

.summary-ctn
  .reverse
    transform: rotateZ(180deg)

.redirection
  border-radius: 16px
  padding: 5px 12px
  border: 1px solid rgba(0, 0, 0, 0.12)

.text-red
  color: red

</style>
