import { default as axios } from '../utils/axios.instances' // eslint-disable-line

const axiosInstance = axios.getSTAxios() // eslint-disable-line

export default {

  async loadScans (options) {
    const params = {
      limit: options.limit ? options.limit : 500,
      offset: 0,
      is_archived: false,
      timeframe: options.timeframe,
      campaign: options.campaign.id
    }

    if (options.filters) {
      options.filters.forEach((filter) => {
        params[filter.searchKey] = filter.searchValue
      })
    }

    return new Promise((resolve, reject) => {
      axiosInstance.get('/dashboard/scans/', { params, cancelToken: options.cancelToken ? options.cancelToken.token : null }).then((res) => {
        resolve(res.data.results)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async loadSummary (options) {
    const params = {
      timeframe: options.timeframe,
      campaign: options.campaign.id
    }

    if (options.filters) {
      options.filters.forEach((filter) => {
        params[filter.searchKey] = filter.searchValue
      })
    }

    return new Promise((resolve, reject) => {
      axiosInstance.get('/dashboard/summary/', { params, cancelToken: options.cancelToken ? options.cancelToken.token : null }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

}
