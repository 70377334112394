import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "code-tag-editor"
  }, [_c(VTooltip, {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "disabled": _vm.isDisabled,
            "color": "primary",
            "icon": ""
          },
          on: {
            "click": _vm.openDialog
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, [_vm._v("mdi-swap-horizontal")])], 1)];
      }
    }])
  }, [_vm.codes.length === 1 ? _c('span', [_vm._v(_vm._s(_vm.$t('transfer_code')))]) : _vm._e(), _vm.codes.length > 1 ? _c('span', [_vm._v(_vm._s(_vm.$t('transfer_codes')))]) : _vm._e()]), !_vm.isDisabled ? _c(VDialog, {
    attrs: {
      "scrollable": "",
      "width": "600",
      "persistent": ""
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm.codes.length === 1 ? _c('span', [_vm._v(_vm._s(_vm.$t('transfer_code')))]) : _vm._e(), _vm.codes.length > 1 ? _c('span', [_vm._v(_vm._s(_vm.$t('transfer_codes_count', {
    count: _vm.count
  })))]) : _vm._e()]), _c(VCardText, {
    staticClass: "py-5"
  }, [_c('div', {
    staticClass: "info-ctn"
  }, [_c('div', {
    staticClass: "transfer font-weight-bold mb-2"
  }, [_vm._v(_vm._s(_vm.$t('current_code_info')) + ":")]), _c('div', {
    staticClass: "info-card pa-3"
  }, [_vm.codes.length > 1 ? _c('div', {
    staticClass: "label font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('code_ids')))]) : _vm._e(), _vm.codes.length === 1 ? _c('div', {
    staticClass: "label font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('code_id')))]) : _vm._e(), _vm._l(_vm.codes, function (code) {
    return _c('div', {
      key: code.message
    }, [_vm._v(_vm._s(code.message))]);
  }), _vm.count >= 20 ? _c('div', [_c('br'), _vm._v(_vm._s(_vm.$t('and_more_codes', {
    count: _vm.count - _vm.codes.length
  }))), _c('br'), _c('br')]) : _vm._e(), _c('div', {
    staticClass: "label font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('current_product')))]), _c('div', [_vm._v(" " + _vm._s(_vm.codes[0].product.name) + " - " + _vm._s(_vm.codes[0].product.sku) + " ")])], 2)]), _c('div', {
    staticClass: "text-center my-4"
  }, [_c(VIcon, {
    staticClass: "transfer-arrow",
    attrs: {
      "size": "45"
    }
  }, [_vm._v("mdi-transfer-down")])], 1), _c('div', {
    staticClass: "info-ctn mt-4"
  }, [_c('div', {
    staticClass: "transfer font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('transfering_to')) + ":")]), _c(VAutocomplete, {
    attrs: {
      "items": _vm.products,
      "loading": _vm.loading,
      "placeholder": "Select a product",
      "search-input": _vm.searchText,
      "item-text": "name",
      "return-object": "",
      "no-filter": true
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchText = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchText = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(data) {
        return [_c('div', _vm._b({}, 'div', data.attrs, false), [_vm._v(" " + _vm._s(data.item.name) + " - " + _vm._s(data.item.sku) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_c('div', _vm._b({}, 'div', data.attrs, false), [_vm._v(" " + _vm._s(data.item.name) + " - " + _vm._s(data.item.sku) + " ")])];
      }
    }], null, false, 198270716),
    model: {
      value: _vm.selectedProduct,
      callback: function callback($$v) {
        _vm.selectedProduct = $$v;
      },
      expression: "selectedProduct"
    }
  })], 1)]), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('close')))]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": !_vm.selectedProduct || !_vm.selectedProduct.id,
      "loading": _vm.saving
    },
    on: {
      "click": _vm.moveCode
    }
  }, [_vm._v(_vm._s(_vm.$t('transfer')))])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }