import { default as axios } from '../utils/axios.instances' // eslint-disable-line

const axiosInstance = axios.getSTAxios() // eslint-disable-line

export default {

  async loadElabel (options) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/products/${options.id}/elabel/`).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async publishELabel (options) {
    return new Promise(function (resolve, reject) {
      axiosInstance.post(`products/${options.id}/elabel/`, { name: options.name, data: options.data, is_published: true }).then((res) => {
        resolve(res.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  },

  async updateELabel (options) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`products/${options.id}/elabel/`, { name: options.name, data: options.data, is_published: options.is_published }).then((res) => {
        resolve(res.data)
      }).catch(function (error) {
        reject(error.response)
      })
    })
  }
}
