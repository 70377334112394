<template>
  <v-app>
    <router-view v-if="!navbarHidden" name="navigation"></router-view>
    <v-main>
      <div class="main-ctn" :class="{ 'hide-nav': navbarHidden }">
        <router-multi-view class="wrapper" view-name="tab"></router-multi-view>
      </div>
    </v-main>
    <v-snackbar v-model="snackBar.show" bottom>
      {{ snackBar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" color="primary" text @click="dissmissSnackBar()">
          {{$t('dismiss')}}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>

import { mapMutations, mapGetters } from 'vuex'
import amplitude from 'amplitude-js'
import Api from '@/api'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['campaigns', 'snackBar', 'currentPlan'])
  },
  components: {
  },
  data () {
    return {
      navbarHidden: false
    }
  },
  methods: {
    ...mapMutations({
      setSelectedCampaign: 'SET_SELECTED_CAMPAIGN',
      dissmissSnackBar: 'DISSMISS_SNACK_BAR'
    }),

    initAmplitude (user) {
      const identity = new amplitude.Identify().set('plan', this.currentPlan.id).set('email', user.email)
      amplitude.getInstance().setUserId(user.id)
      amplitude.getInstance().identify(identity)
    }
  },
  async created () {
    if (Api.amplitude_key) {
      amplitude.getInstance().init(Api.amplitude_key)
      amplitude.getInstance().options.apiEndpoint = 'api2.amplitude.com'
    }
    if (this.$route.query.hidenav) {
      this.navbarHidden = true
    }
    if (this.$route.query.token) {
      this.$store.dispatch('setScantrustAuthToken', this.$route.query.token)
      const user = await this.$store.dispatch('loadUserProfile')
      this.initAmplitude(user)
      await this.$store.dispatch('loadCampaigns')
      this.setSelectedCampaign(this.campaigns[0])
    }
  }
}
</script>

<style lang="sass">

@import '@/variables.sass'

main
  background: $light-bg

.main-ctn
  margin-left: auto
  margin-right: auto
  margin-top: 63px
  height: calc(100% - 63px)
  width: 100%
  background: $light-bg
  &.hide-nav
    margin-top: 0px

.wrapper
  height: 100%
  position: relative

</style>
