var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "#000",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M45.675 10.65V10.575L24 2.25L2.325 10.65H2.25V10.725V37.425L24 45.75L45.75 37.425L45.675 10.65ZM24 3.9L31.35 6.525L11.25 14.1L4.5 11.475L24 3.9ZM23.25 43.875L15.075 40.65L10.95 39.15L3.75 36.375V12.9L10.875 15.675V21.525L11.475 21.375L11.925 21.975L12.525 21.825L13.125 22.425L13.65 22.275L14.475 23.1V17.1L15.075 17.325L23.25 20.4V43.875ZM24 19.05L16.05 15.975L35.625 8.175L43.725 11.4L24 19.05ZM44.175 36.375L24.75 43.875V20.4L44.175 12.9V36.375Z",
      "fill": "#555555"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }