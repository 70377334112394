<template>
  <div class="elabel-editor">
    <v-progress-linear
      v-show="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <div class="d-flex align-center pt-5 pb-3">
      <router-link to="/products">
        <v-btn class="ml-3" icon><v-icon color="black">mdi-arrow-left</v-icon></v-btn>
      </router-link>
      <div class="page-title mono pl-3">{{$t('elabel_management_tool')}}</div>
      <v-spacer></v-spacer>
    </div>
    <iframe id="elabel-editor-frame" v-if="loadIframe" frameborder="0" :src="this.editorUrl"></iframe>
  </div>
</template>

<script>

import Api from '@/api'
import { mapGetters } from 'vuex'

export default {
  name: 'LandingPage',
  computed: {
    ...mapGetters(['scantrustAuthToken', 'didProductUpdate']),
    editorUrl () {
      return Api.elabel_editor_url + '#/edit/' + this.$route.params.id + '/form?&token=' + this.scantrustAuthToken
    }
  },
  watch: {
    '$route.params.id' (newVal) {
      if (newVal) {
        if (document.getElementById('elabel-editor-frame')) {
          this.loadIframe = false
          this.$nextTick(() => {
            this.loadIframe = true
          })
        }
      }
    }
  },
  data () {
    return {
      loadIframe: true,
      config: {},
      campaign: {},
      loading: true
    }
  },
  methods: {
    initEventHandler () {
      var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
      var eventer = window[eventMethod]
      var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'

      eventer(messageEvent, (e) => {
        if (e.data.type === 'editor.loaded') {
          this.loading = false
        } else if (e.data.type === 'editor.navigation') {
          if (~e.data.hash.indexOf('#')) {
            this.$router.push(e.data.hash.split('#')[1])
          }
        }
      })
    }
  },
  async created () {
    this.initEventHandler()
  }
}

</script>

<style lang="sass" scoped>

.elabel-editor
  font-family: 'Roboto mono'
  height: 100%
  width: 100%

iframe
  overflow: hidden
  width: 100%
  height: 100%
  min-width: 100%
  min-height: 100%
</style>
