import { default as axios } from '../utils/axios.instances' // eslint-disable-line

const axiosInstance = axios.getSTAxios() // eslint-disable-line

export default {

  async getLookupData (name) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/system/lookups/${name}/`).then((res) => {
        resolve(res.data.values)
      }).catch((err) => {
        if (name === 'redirection-conditions') {
          resolve([
            { value: 'code_extended_id', description: 'Code Extended ID' },
            { value: 'scan_region_key', description: 'Custom Region Key' },
            { value: 'location_country_code', description: 'Location Country Code' },
            { value: 'location_city', description: 'Location City' },
            { value: 'device_language', description: 'Device Language' },
            { value: 'scan_app_slug', description: 'Scan Application Slug' },
            { value: 'product_id', description: 'Product ID' },
            { value: 'brand_id', description: 'Brand ID' },
            { value: 'scan_result', description: 'Scan Result' },
            { value: 'scan_activation_status', description: 'Activation Status' }
          ])
        } else {
          reject(err)
        }
      })
    })
  },

  async getAppList () {
    return new Promise((resolve, reject) => {
      axiosInstance.get('/sdk-apps/', { offset: 0, limit: 100 }).then((res) => {
        resolve(res.data.results)
      }).catch((err) => {
        reject(err)
      })
    })
  }

}
