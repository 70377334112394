<template>
  <div :style="{background: background}" class="card-label" >
    <v-icon class="icon flex-grow-0" :style="{ fill: `#${color}`}">$tetris</v-icon>
    <span :style="{ color: `#${color}`}" class="mono text flex-grow-0 font-weight-bold">{{content}}</span>
  </div>
</template>

<script>

export default {
  name: 'CardLabel',
  props: ['color', 'content'],
  computed: {
    background () {
      return `#${this.color}33`
    }
  }
}

</script>

<style lang="sass" scoped>

.card-label
  display: inline-block
  border-radius: 0px !important

.icon
  width: 12px
  margin-top: -2px !important

.text
  padding: 0px 8px 0px 5px

</style>
