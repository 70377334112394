<template>
  <v-card scrollable>
    <v-row class="pa-5 ma-0">
      <v-col class="flex-grow-0 pa-0 ma-0">
        <div class="image-upload-ctn pr-5">
          <div class="d-flex flex-row align-center mb-3" v-if="productImage">
            <img :src="productImage">
          </div>
          <ImageCropper input-key="logo" @image-selected="onImageSelect"></ImageCropper>
        </div>

        <div class="font-weight-light hint pt-1">
          {{$t('product_supported_format')}}<br>
          {{$t('product_file_size')}}
        </div>
      </v-col>
      <v-col class="py-0">
        <v-form v-model="valid" ref="product-form">
          <v-row>
            <v-col :cols="product.id ? 6 : 12" class="pt-0">
              <v-text-field v-model="productForm.name" name="name" persistent-hint :label="$t('product_name')" :hint="$t('product_name_hint')" @focus="clearErrors('name')" :rules="rules.name" :error-messages="errorMessages.name" required></v-text-field>
            </v-col>
            <v-col :cols="product.id ? 6 : 12" class="pt-0">
              <v-text-field v-model="productForm.sku" name="sku" persistent-hint :label="$t('product_sku')" :hint="$t('product_sku_hint')" :rules="rules.sku" @focus="clearErrors('sku')" :error-messages="errorMessages.sku" required></v-text-field>
            </v-col>
            <v-col cols="11" class="pt-0">
              <v-text-field ref="product-url" validate-on-blur name="url" @blur="onBlurUrl" @focus="onFocusUrl" v-model="productForm.client_url" persistent-hint :rules="rules.client_url" :hint="$t('product_url_hint')" :label="$t('product_url')"></v-text-field>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-autocomplete v-model="productForm.brand"
                :items="brands"
                item-text="name"
                item-value="id"
                label="Brand"
                :rules="rules.brand"
              >
              <template v-slot:selection="{ item }">
                <span>
                  [{{ item.reference }}] {{ item.name }}
                </span>
              </template>

              <template v-slot:item="{ item }">
                <span>
                  [{{ item.reference }}] {{ item.name }}
                </span>
              </template>
              </v-autocomplete>
            </v-col>

            <v-col v-if="isAvailable('qrmanager:product:description') && product.id" cols="12" class="pt-0">
              <v-textarea v-model="productForm.description" counter="500" :label="$t('description')" persistent-hint :hint="$t('description_hint')" :rules="rules.description"></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="handleClose()">{{$t('close')}}</v-btn>
      <v-btn text color="primary" @click="handleSave()" :loading="savingProduct" >{{$t('save')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import ImageCropper from '@/components/utils/ImageCropper'
import { mapGetters, mapMutations } from 'vuex'
import amplitude from 'amplitude-js'

export default {
  name: 'ProductForm',
  props: ['product', 'campaign', 'productId'],
  data () {
    return {
      valid: false,
      savingProduct: false,
      errorMessages: {
        name: [],
        sku: []
      },
      productForm: {
        name: '',
        sku: '',
        client_url: '',
        description: ''
      },
      rules: {
        name: [
          v => !!v || 'Product name is required'
        ],
        sku: [
          v => !!v || 'SKU is required'
        ],
        brand: [
          v => !!v || 'Brand is required'
        ],
        client_url: [
          v => (/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(v) || !v || !v.length) || 'URL must be valid. Make sure it starts with https://'
        ],
        description: [
          v => (v || '').length <= 200 || 'Description must be 500 characters or less'
        ]
      }
    }
  },
  components: {
    ImageCropper
  },
  computed: {
    ...mapGetters(['campaigns', 'isAvailable', 'filteredProductCount', 'defaultBrand', 'brands']),
    productImage () {
      return this.productForm.image ? this.productForm.image : this.productForm.brand && this.productForm.brand.image ? this.productForm.brand.image : require('@/assets/product-placeholder.png')
    }
  },
  watch: {
    product (newVal) {
      if (newVal && newVal.sku !== this.productForm.sku) {
        this.productForm = {
          ...newVal
        }
      }
    },

    'productForm.client_url' (newVal) {
      if (newVal) {
        const tmp = newVal.substr(8)
        if (tmp && tmp.indexOf('https://') === 0) {
          this.productForm.client_url = tmp
        }
      }
    },

    productId (newVal) {
      if (!newVal) {
        this.productForm = {
          name: '',
          sku: '',
          client_url: '',
          description: ''
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setProductUpdateStatus: 'UPDATE_PRODUCT_UPDATE_STATUS',
      setProductCount: 'SET_PRODUCT_COUNT'
    }),

    onFocusUrl () {
      if (!this.productForm.client_url) {
        this.productForm.client_url = 'https://'
      }
    },

    onBlurUrl () {
      if (this.productForm.client_url && this.productForm.client_url === 'https://') {
        this.productForm.client_url = ''
      }
    },

    clearErrors (key) {
      if (key) {
        this.errorMessages[key] = []
      } else {
        this.errorMessages = {
          name: [],
          sku: []
        }
      }
    },

    handleClose () {
      this.$refs['product-form'].reset()
      this.savingProduct = false
      this.productForm.image = null
      this.$emit('on-close-dialog')
    },

    async handleSave () {
      this.$refs['product-form'].validate()
      if (!this.valid) {
        return
      }
      this.savingProduct = true
      if (this.productForm.id) {
        try {
          await this.$store.dispatch('updateProduct', { product: this.productForm })
          amplitude.getInstance().logEvent('product save')
          this.setProductUpdateStatus(true)
          this.handleClose()
        } catch (err) {
          for (const key in err.data) {
            this.errorMessages[key] = err.data[key]
          }
          this.savingProduct = false
        }
      } else {
        if (!this.productForm.campaign) {
          this.productForm.campaign = this.campaign
        }
        try {
          if (this.defaultBrand && !this.productForm.brand) {
            this.productForm.brand = this.defaultBrand.reference
          }
          const product = await this.$store.dispatch('createProduct', { product: this.productForm })
          amplitude.getInstance().logEvent('product create')
          this.setProductCount(this.filteredProductCount + 1)
          this.setProductUpdateStatus(true)
          this.handleClose()
          this.$emit('on-product-created', product)
        } catch (err) {
          for (const key in err.data) {
            this.errorMessages[key] = err.data[key]
          }
          this.savingProduct = false
        }
      }
    },

    onImageSelect (image) {
      this.productForm = Object.assign({}, this.productForm, { image: image })
    }
  },
  created () {
    if (this.product && this.product.id) {
      this.productForm = {
        ...this.product
      }
      this.productForm.brand = this.productForm.brand.id
    } else {
      this.productForm.campaign = this.campaign
    }
  }
}

</script>

<style lang="sass" scoped>

.image-upload-ctn
  text-align: center
  img
    max-width: 200px
    max-height: 300px
    margin-left: auto
    margin-right: auto

.hint
  font-size: 10px
  text-align: center
  margin-right: 20px

</style>
