<template>
  <v-card class="summary-card">
    <card-label color="0789b2" content="summary"></card-label>
    <v-row justify="center" align="center" class="ma-0 my-12 scan-count-ctn">
      <v-icon size="48px" class="scan-icon">$scan</v-icon>
      <div class="pl-3">
        <div class="scan-number font-weight-bold">{{scanSummary.meta.scans.total}}</div>
        <div class="legend font-weight-bold">Scans</div>
      </div>
    </v-row>
    <!-- <v-row class="pl-5 ma-0 scan-variation-ctn">
      <v-icon>$arrow-up</v-icon>
      <div class="pl-2 increase">25% vs average week (+5)</div>
    </v-row>
    <v-row class="pl-5 ma-0 scan-variation-ctn">
      <v-icon>$arrow-down</v-icon>
      <div class="pl-2 decrease">25% vs last week (+40)</div>
    </v-row> -->
  </v-card>
</template>

<script>

import CardLabel from '@/components/CardLabel'
import { mapGetters } from 'vuex'

export default {
  name: 'SummaryCard',
  computed: {
    ...mapGetters(['scanSummary'])
  },
  components: {
    CardLabel
  }
}

</script>

<style lang="sass" scoped>

@import "@/variables.sass"

.summary-card
  padding-top: 8px

.scan-count-ctn
  .scan-number
    color: $dark-blue
    line-height: 28px
    font-size: 36px
  .legend
    color: $dark-blue
    line-height: 15px

.scan-variation-ctn
  .increase
    color: $green
  .decrease
    color: $red

</style>
