<template>
  <v-row class="upgrade" align-content="center" justify="center">
    <v-col cols="12" lg="11" xl="8" class="upgrade-wrapper">
      <div class="title d-flex justify-center pa-5">
        Upgrade to Scantrust Enterprise
      </div>
      <div class="d-flex justify-center pb-10">
        Get Connected Packaging solution used by Fortune 500 brands
      </div>
      <v-row class="card-row d-flex justify-center flex-nowrap">
        <div class="d-flex flex-column card">
          <div class="title py-3">Pro (Beta)*</div>
          <div class="description pb-2">For growing brands who want to start engaging customers digitally</div>
          <div class="description py-2 mt-auto italic">Current Plan</div>
        </div>
        <div class="d-flex flex-column card premium">
          <div class="title py-3">Enterprise</div>
          <div class="description pb-5">For established breands with many SKUs and in multiple markets.</div>
          <v-btn class="mt-auto" @click="openForm" raised primary>Contact Sales</v-btn>
        </div>
      </v-row>
      <v-row class="d-flex justify-center">
        <div class="card d-flex flex-column feature-list">
          <!-- <div class="description font-weight-bold pb-3">Run basic QR code campaigns:</div> -->
          <ul class="">
            <li>Create and manage your Products</li>
            <li>5 dynamic QR codes</li>
            <li>Export Codes in different file formats</li>
            <li>Intelligent Code redirection</li>
            <li>Landing page editor</li>
            <li>Scan dashboard & analytics</li>
          </ul>
          <div class="disclaimer mt-auto">*Limited time offer.</div>
        </div>
        <div class="card premium feature-list">
          <!-- <div class="description font-weight-bold pb-3">Run high-conversion QR code campaigns across many products:</div> -->
          <ul class="">
            <li>All Pro features</li>
            <li>Unlimited Products</li>
            <li>Unlimited Dynamic QR codes</li>
            <li>Advanced QR code customisation</li>
            <li>More product digital identifier options (NFC, datamatrix, 1D barcode etc.)</li>
            <li>Anti-counterfeiting feature</li>
            <li>Supply chain traceability feature</li>
            <li>Advanced analytics</li>
            <li>Users & Teams (roles)</li>
            <li>Multiple Campaigns</li>
            <li>API integration</li>
            <li>Regional support & SLAs</li>
          </ul>
          <div @click="goToExternalLink('https://www.scantrust.com')" class="link font-weight-bold pl-2">> Learn about Enterprise</div>
        </div>
      </v-row>
    </v-col>

    <v-dialog v-model="contactModal" width="600">
      <v-card>
        <v-card-title>Contact Sales</v-card-title>
        <v-card-text>
          <div id="hubspotForm"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="contactModal = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>

import Plans from '@/const/plans'
import { mapGetters } from 'vuex'

export default {
  name: 'Upgrade',
  computed: {
    ...mapGetters(['currentPlan'])
  },
  data () {
    return {
      plans: Plans,
      contactModal: false,
      createdScript: false
    }
  },
  methods: {
    openContactModal () {
      this.contactModal = true
    },

    goToExternalLink (url) {
      parent.postMessage({ type: 'external-link', data: url }, '*')
    },

    openForm () {
      this.contactModal = true
      setTimeout(() => {
        if (this.createdScript) {
          return
        }
        const script = document.createElement('script')
        script.src = 'https://js.hsforms.net/forms/v2.js'
        document.body.appendChild(script)
        script.addEventListener('load', () => {
          console.log('load')
          if (window.hbspt) {
            console.log('hbsp exist')
            window.hbspt.forms.create({
              region: 'na1',
              portalId: '4646774',
              formId: '07d60ac8-360d-4da2-8b84-0ec5bc96e8f5',
              target: '#hubspotForm'
            })
            this.createdScript = true
          }
        })
      }, 200)
    }
  }
}

</script>

<style lang="sass" scoped>

@import '@/variables.sass'

.upgrade
  overflow: auto
  height: calc(100vh - 63px)

.link
  color: $primary
  cursor: pointer

.title
  font-size: 1.5rem !important

.upgrade-wrapper
  height: 100%
  overflow: auto
  padding-bottom: 30px

.card
  padding: 15px
  text-align: center
  width: 280px
  border: 1px solid rgba(0,0,0,0.1)

.feature-list
  border-top: none
  text-align: left
  li
    padding-bottom: 8px

.italic
  font-style: italic

.premium
  background: #ecf9ffff
  border-left: none

.disclaimer
  font-size: 11px
  color: #555555

#hubspotForm
  margin-top: 15px

</style>
