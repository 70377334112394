import Api from '@/api'
import * as types from '../mutations'
import ProductService from '@/services/products'
import BrandService from '@/services/brands'
import campaignService from '@/services/campaigns'

const state = {
  products: [],
  fullProductList: [],
  selectedCampaign: {},
  filteredProductCount: 0,
  loading: true,
  brands: [],
  defaultBrand: {},
  consumerOptions: {}
}

const getters = {
  brands: (state) => state.brands,
  defaultBrand: (state) => state.defaultBrand,
  products: (state) => state.products,
  fullProductList: (state) => state.fullProductList,
  selectedCampaign: (state) => state.selectedCampaign,
  loadingProducts: (state) => state.loading,
  filteredProductCount: (state) => state.filteredProductCount,
  consumerOptions: (state) => state.consumerOptions,
  campaignRedirectionMode: (state) => {
    if (state.consumerOptions && state.consumerOptions.is_enabled && state.consumerOptions.consumer_url) {
      if (state.consumerOptions.consumer_url.indexOf(Api.default_stc_url) > -1) {
        return 'stc'
      } else if (state.consumerOptions.consumer_url.indexOf(Api.default_elabel_url) > -1 ||
        state.consumerOptions.consumer_url.indexOf(Api.remy_elabel_url) > -1) {
        return 'elabel'
      } else {
        return 'url'
      }
    } else if (state.consumerOptions && !state.consumerOptions.is_enabled) {
      return 'product'
    } else {
      return 'url'
    }
  }
}

const actions = {

  async searchBrands ({ commit }, { search }) {
    const res = await BrandService.loadBrands()
    return res
  },

  async loadBrands ({ commit }) {
    const brands = await BrandService.loadBrands()
    commit(types.SET_BRAND_LIST, brands)
    commit(types.SET_DEFAULT_BRAND, brands[0])
  },

  async loadFullProductList ({ commit, dispatch }, offset) {
    const res = await ProductService.loadProducts({ limit: 500, offset: offset || 0 })
    if (res.count > 500 && res.results.length === 500) {
      await dispatch('loadFullProductList', offset ? offset + 500 : 500)
    }
    commit(types.SET_FULL_PRODUCT_LIST, res.results)
  },

  async loadSelectedCampaignConsumerOptions ({ commit }, campaign) {
    const res = await campaignService.loadConsumerOptions(campaign)
    commit(types.SET_CAMPAIGN_CONSUMER_OPTIONS, res)
  },

  async searchProducts ({ commit }, { campaign, offset, limit, search }) {
    const res = await ProductService.loadProducts({ campaign, offset, limit, search })
    return res.results
  },

  async loadProducts ({ commit }, { campaign, offset, limit, search }) {
    commit(types.SET_PRODUCT_LOADING, true)
    const res = await ProductService.loadProducts({ campaign, offset, limit, search })
    if (res.results) {
      res.results.forEach((product) => {
        product.code_count = 0
      })
    }
    commit(types.SET_PRODUCT_LIST, { products: res.results, count: res.count })
    commit(types.SET_PRODUCT_LOADING, false)
  },

  async loadProductBySKU ({ commit }, product) {
    try {
      const res = await ProductService.loadProducts({ sku: product.sku })
      if (res && res.results && res.results[0]) {
        return Promise.resolve(res.results[0])
      } else {
        return Promise.resolve(product)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },

  async loadProduct ({ commit }, id) {
    const product = await ProductService.loadProduct(id)
    return product
  },

  async createProduct ({ commit }, { product }) {
    try {
      const res = await ProductService.createProduct(product)
      commit(types.CREATE_PRODUCT, res)
      return Promise.resolve(res)
    } catch (err) {
      err.response.request = product
      return Promise.reject(err.response)
    }
  },

  async updateProduct ({ commit }, { product }) {
    try {
      const res = await ProductService.updateProduct(product)
      // Keep old code count to prevent re-fetch
      res.code_count = product.code_count
      commit(types.UPDATE_PRODUCT, res)
      return Promise.resolve(res)
    } catch (err) {
      err.response.request = product
      return Promise.reject(err.response)
    }
  }

}

const mutations = {
  [types.SET_PRODUCT_LIST] (state, { products, count }) {
    state.products = products
    state.filteredProductCount = count
  },

  [types.SET_FULL_PRODUCT_LIST] (state, products) {
    state.fullProductList = products
  },

  [types.SET_PRODUCT_LOADING] (state, loading) {
    state.loading = loading
  },

  [types.SET_SELECTED_CAMPAIGN] (state, campaign) {
    state.selectedCampaign = campaign
  },

  [types.SET_CAMPAIGN_CONSUMER_OPTIONS] (state, options) {
    state.consumerOptions = options
  },

  [types.CREATE_PRODUCT] (state, product) {
    product.code_count = 0
    state.products.unshift(product)
  },

  [types.INCREASE_PRODUCT_CODE_COUNT] (state, product) {
    const index = state.products.findIndex((e) => e.id === product)
    if (~index) {
      state.products[index].code_count += 1
    }
  },

  [types.UPDATE_PRODUCT] (state, product) {
    const index = state.products.findIndex((e) => e.id === product.id)
    if (~index) {
      if (product.campaign && product.campaign.id !== state.selectedCampaign.id) {
        state.products.splice(index, 1)
      } else {
        state.products[index] = { ...product }
      }
    }
  },

  [types.SET_PRODUCT_CODE_COUNT] (state, { product, count }) {
    const index = state.products.findIndex((e) => e.id === product)
    if (~index) {
      state.products[index].code_count = count
    }
  },

  [types.SET_BRAND_LIST] (state, brands) {
    state.brands = brands
  },

  [types.SET_DEFAULT_BRAND] (state, brand) {
    state.defaultBrand = brand
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
