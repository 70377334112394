<template>
  <div class="sidebar-params">
    <div class="d-flex sidebar-params align-stretch flex-column">

      <div>
        <div class="simulator-title font-weight-bold mb-3">Scan destination simulator</div>
        <div class="description my-3">Enter the QR code Extended ID and setup scanning parameters to find out where your codes are redirecting</div>
        <v-btn @click="settingsDialog = true" block class="add-code-btn my-5" raised>
          <v-icon>mdi-plus</v-icon>
          code and parameters
        </v-btn>
      </div>

      <div class="my-3" v-if="simulationResults.elapsed_ms">
        <v-row align="center" justify="space-between" class="ma-0 mt-3 sidebar-title">
          <span>QR Code</span>
          <v-btn @click="editSimulationParams('code')" color="primary" small icon><v-icon>mdi-square-edit-outline</v-icon></v-btn>
        </v-row>
        <div class="sidebar-infos" v-if="selectedCode && selectedCode.message">
          <div>Extended ID: {{selectedCode.message}}</div>
          <div>Product: {{selectedCode.product.name}}</div>
          <div>Brand: {{selectedCode.brand.name}}</div>
          <div>Campaign: {{getCampaign(selectedCode.campaign)?.name}}</div>
          <div v-for="(value, key) of selectedCode.scm_data" :key="`scm-${key}`">
            {{key}}: {{value}}
          </div>
        </div>
      </div>
      <div class="my-1" v-if="simulationResults.elapsed_ms">
        <v-row align="center" justify="space-between" class="ma-0 mt-3 sidebar-title">
          <span>Scan parameters</span>
          <v-btn @click="editSimulationParams('scan')" color="primary" small icon><v-icon>mdi-square-edit-outline</v-icon></v-btn>
        </v-row>
        <div class="pa-0 ma-2" v-show="key !== 'code_extended_id' && value" :key="`simulation-params-${key}`" v-for="(value, key) of simulationResults.params">
          <simulation-params :name="getVariableName(key)" :type="key" :value="value"></simulation-params>
        </div>
      </div>
      <div class="my-1 mb-auto" v-if="simulationResults.elapsed_ms">
        <div class="mt-6 sidebar-title">Final Destination</div>
        <v-row class="mt-2 mx-0">
          <div class="flex-shrink-1 pill ml-2 selected" v-if="simulationResults?.matched_rule">{{simulationResults.matched_rule.name}} - {{simulationResults.matched_destination?.name}}</div>
        </v-row>
        <v-row class="mx-0">
          <div class="ml-2 pill selected" v-if="simulationResults.is_fallback && !simulationResults?.matched_destination?.id">
            <span v-if="simulationResults.fallback_type === 'product_url'">Product URL</span>
            <span v-if="simulationResults.fallback_type === 'brand_url'">Brand URL</span>
            <span v-if="simulationResults.fallback_type === 'company_url'">Company URL</span>
          </div>
          <div class="ml-2 pill selected" :class="{ 'destination-warning': simulationResults.is_fallback }" v-else-if="simulationResults?.matched_destination">
            {{getDestinationInfos(simulationResults?.matched_destination).name}} -
            <span v-if="simulationResults.is_fallback">
              <span v-if="simulationResults.fallback_type === 'product_url'">Product URL</span>
              <span v-if="simulationResults.fallback_type === 'brand_url'">Brand URL</span>
              <span v-if="simulationResults.fallback_type === 'company_url'">Company URL</span>
            </span>
            <span v-else>
              <span v-if="getDestinationInfos(simulationResults?.matched_destination).type == 'product'">Product URL</span>
              <span v-if="getDestinationInfos(simulationResults?.matched_destination).type == 'brand'">Brand URL</span>
              <span v-if="getDestinationInfos(simulationResults?.matched_destination).type == 'stc'">Landing Page</span>
              <span v-if="getDestinationInfos(simulationResults?.matched_destination).type == 'elabel'">e-Label landing page</span>
              <span v-if="getDestinationInfos(simulationResults?.matched_destination).type == 'url'">Custom URL</span>
            </span>
          </div>
        </v-row>
      </div>
      <div v-if="selectedCode.message" class="mt-auto mb-2">
        <v-btn @click="simulate()" block>simulate</v-btn>
      </div>
    </div>
    <v-dialog v-model="settingsDialog" width="900" scrollable :persistent="true">
      <v-card>
        <v-card-title class="grey lighten-2">
          Settings
        </v-card-title>

        <v-card-text class="pa-0 ma-0">
          <v-stepper class="no-shadow" v-model="step">
            <v-stepper-header>
              <v-stepper-step step="1">QR Code</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2">Scan Parameters</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1" class="pa-0">
                <v-form class="code-form pa-5" @submit.prevent="searchCode" v-model="codeFormValid" ref="code-form">
                  <v-row>
                    <v-col class="flex-grow-1">
                      <v-text-field required label="Extended ID" :value="selectedCode.message" placeholder="Enter a code extended ID" outlined dense hide-details v-model="search">
                      </v-text-field>
                    </v-col>
                    <v-col class="flex-grow-0 flex-shrink-1">
                      <v-btn :disabled="loadingCode" raised color="primary" @click="searchCode">
                        search
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div v-if="codes.length > 1 && !selectedCode.id">
                    <table class="codes-table">
                      <thead>
                        <td class="font-weight-bold">Extended ID</td>
                        <td class="font-weight-bold">Product</td>
                      </thead>
                      <tbody>
                        <tr @click="selectCode(code)" v-for="code in codes" :key="`codes-${code.id}`">
                          <td>{{code.message}}</td>
                          <td>{{code.product.name}} - {{code.product.sku}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="selectedCode && selectedCode.message" class="code-infos">
                    <div>Extended ID: {{selectedCode?.message}}</div>
                    <div>Product: {{selectedCode?.product?.name}}</div>
                    <div>Brand: {{selectedCode?.brand?.name}}</div>
                    <div>Campaign: {{getCampaign(selectedCode.campaign)?.name}}</div>
                    <div v-for="(value, key) of selectedCode.scm_data" :key="`scm-${key}`">
                      {{key}}: {{value}}
                    </div>
                  </div>
                </v-form>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-form v-model="paramsFormValid" ref="params-form" class="pa-2">
                  <div>
                    <div class="params-title pt-2">Code Status</div>
                    <div class="params-hint py-2">For simulating the scan result only. This will not affect the actual code status.</div>
                    <logic-value-input type="scan_activation_status" :value.sync="simulationParams[0].value" operator="is"></logic-value-input>
                  </div>

                  <div>
                    <div class="params-title pt-2">Scan Location</div>
                    <div class="params-hint py-2">Where you expect the user to scan this code.</div>
                    <logic-value-input type="location_country_code" :value.sync="simulationParams[1].value" operator="is"></logic-value-input>
                  </div>

                  <div>
                    <div class="params-title pt-2">Others</div>
                    <div v-for="(param, index) in simulationParams" :key="`active-parameters-${param.key}`">
                      <v-row class="my-2 mx-0" v-if="param.key !== 'location_country_code' && param.key !== 'scan_activation_status'">
                        <v-select
                          v-model="simulationParams[index].key"
                          :items="variables"
                          dense
                          hide-details
                          item-text="description"
                          item-value="value"
                          outlined
                          class="mr-2"
                        ></v-select>
                        <logic-value-input :type="simulationParams[index].key" :value.sync="simulationParams[index].value" operator="is"></logic-value-input>
                      </v-row>
                    </div>
                  </div>
                  <v-btn class="my-4" @click="addParameter()">
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    Add Parameter
                  </v-btn>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text v-if="step === 2" @click="step = 1">
            back
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            close
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!selectedCode.id"
            @click="step = 2"
            v-if="step === 1"
          >
            next
          </v-btn>
          <v-btn
            color="primary"
            raised
            @click="simulate()"
            v-if="step === 2"
          >
            simulate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import CodeService from '@/services/codes'
import LogicValueInput from '@/components/redirection/LogicValueInput'
import SimulationParams from '@/components/redirection/SimulationParams'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SimulatorSidebar',
  components: { LogicValueInput, SimulationParams },
  computed: {
    ...mapGetters(['redirectionVariables', 'campaigns', 'simulationResults', 'redirectionRuleDestinations']),
    variables () {
      const sortedVariables = JSON.parse(JSON.stringify(this.redirectionVariables)).filter((variable) => {
        return variable.value !== 'scm' && variable.value !== 'location_country_code' && variable.value !== 'scan_activation_status' && variable.value !== 'product_id' && variable.value !== 'brand_id' && variable.value !== 'code_extended_id'
      })
      sortedVariables.sort((a, b) => {
        if (a.description > b.description) {
          return 1
        } else if (a.description < b.description) {
          return -1
        }
        return 0
      })
      return sortedVariables
    }
  },
  data () {
    return {
      settingsDialog: false,
      loadingCode: false,
      search: '',
      step: 1,
      codeFormValid: false,
      paramsFormValid: false,
      selectedCode: {},
      codes: [],
      simulationParams: [
        { key: 'scan_activation_status', value: '' },
        { key: 'location_country_code', value: '' }
      ]
    }
  },
  methods: {

    ...mapMutations({
      setSimulationCode: 'SET_SIMULATION_CODE'
    }),

    getDestinationInfos (matchedDestination) {
      if (!matchedDestination.id) {
        return
      }
      const destinations = this.redirectionRuleDestinations(matchedDestination.rule_id)
      return destinations.find((destination) => {
        return destination.id === matchedDestination.id
      })
    },

    editSimulationParams (mode) {
      if (mode === 'scan') {
        this.step = 2
      } else if (mode === 'code') {
        this.step = 1
      }
      this.settingsDialog = true
    },

    getVariableName (key) {
      const found = this.redirectionVariables.find((variable) => {
        return variable.value === key
      })

      if (found) {
        return found.description
      }
    },

    getCampaign (id) {
      return this.campaigns.find((c) => c.id === id)
    },

    async simulate () {
      this.settingsDialog = false
      const params = { code_extended_id: this.selectedCode.message }
      this.simulationParams.forEach((param) => {
        if (param.value) {
          params[param.key] = param.value
        }
      })
      this.setSimulationCode(this.selectedCode)
      await this.$store.dispatch('simulateRedirection', { params })
      this.$emit('on-simulation-end')
    },

    close () {
      this.settingsDialog = false
    },

    selectCode (code) {
      this.selectedCode = code
      this.search = code.message
      this.simulationParams[0].value = this.selectedCode.activation_status.toString()
    },

    addParameter () {
      for (const index in this.variables) {
        const found = this.simulationParams.findIndex((param) => {
          return param.key === this.variables[index].value
        })
        if (!~found) {
          this.simulationParams.push({ key: this.variables[index].value, value: '' })
          return
        }
      }
    },

    deleteParameter (key) {
      delete this.form[key]
    },

    async searchCode () {
      this.selectedCode = {}
      this.loadingCode = true
      const res = await CodeService.findCode(this.search)
      this.loadingCode = false
      this.codes = res.results
      if (this.codes.length === 1) {
        this.selectedCode = this.codes[0]
      }
    }
  }
}

</script>

<style lang="sass" scoped>

@import "@/variables.sass"

.sidebar-params
  height: 100%

.simulation-param-name
  font-size: 12px

.code-form
  margin-left: auto
  margin-right: auto

.simulator-title
  font-size: 19px

.add-code-btn
  margin-left: auto
  margin-right: auto

.codes-table
  background-color: #fafafa
  width: 100%
  tbody
    tr
      cursor: pointer
      &:hover
        background: #d5faff

.code-infos
  border: 2px solid $primary
  margin: 10px 0px
  padding: 10px

.sidebar-title
  color: #222
  font-family: Roboto
  font-size: 12px
  font-style: normal
  font-weight: 500
  line-height: 16px
  letter-spacing: 1.5px
  text-transform: uppercase

.sidebar-infos
  font-size: 14px

.pill
  margin-top: 5px
  background: white
  border-radius: 17px
  padding: 4px 8px
  text-align: left
  font-size: 13px
  border: 1px solid rgba(0, 0, 0, 0.5)

.selected
  border: 2px solid #77C61A

.destination-warning
  border: 2px solid #ff911b

.params-title
  color: #777
  font-family: Roboto
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: 16px
  letter-spacing: 1.5px
  text-transform: uppercase

.params-hint
  color: #777
  font-family: Roboto
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 20px
  letter-spacing: 0.25px

</style>
