import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rule-filters"
  }, [_c('div', {
    staticClass: "mb-6 d-flex align-center justify-space-between"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Filters")]), _c(VBtn, {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.clearFilters
    }
  }, [_c(VIcon, [_vm._v("mdi-close")])], 1)], 1), _c(VAutocomplete, {
    attrs: {
      "chips": "",
      "multiple": "",
      "deletable-chips": "",
      "items": _vm.allCampaigns,
      "search-input": _vm.search,
      "item-text": "name",
      "item-value": "id",
      "hide-details": "",
      "outlined": "",
      "persistent-placeholder": "",
      "label": "Campaigns"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event;
      }
    },
    model: {
      value: _vm.ruleActiveFilters.campaigns,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleActiveFilters, "campaigns", $$v);
      },
      expression: "ruleActiveFilters.campaigns"
    }
  }), _c(VSelect, {
    staticClass: "my-4",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "label": "Status",
      "items": _vm.statusDropdown,
      "item-text": "description",
      "item-value": "key"
    },
    model: {
      value: _vm.ruleActiveFilters.is_paused,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleActiveFilters, "is_paused", $$v);
      },
      expression: "ruleActiveFilters.is_paused"
    }
  }), _c(VSelect, {
    staticClass: "my-4",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "label": "Archived",
      "items": _vm.yesNoDropdown,
      "item-text": "description",
      "item-value": "key"
    },
    model: {
      value: _vm.ruleActiveFilters.is_archived,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleActiveFilters, "is_archived", $$v);
      },
      expression: "ruleActiveFilters.is_archived"
    }
  }), _c(VSelect, {
    staticClass: "my-4",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "label": "App clip/Instant app",
      "items": _vm.yesNoDropdown,
      "item-text": "description",
      "item-value": "key"
    },
    model: {
      value: _vm.ruleActiveFilters.app_clip_enabled,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleActiveFilters, "app_clip_enabled", $$v);
      },
      expression: "ruleActiveFilters.app_clip_enabled"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }