<template>
  <div class="code-tag-editor">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn :disabled="isDisabled" v-bind="attrs" v-on="on" @click="openDialog" color="primary" icon><v-icon>mdi-swap-horizontal</v-icon></v-btn>
      </template>
      <span v-if="codes.length === 1">{{$t('transfer_code')}}</span>
      <span v-if="codes.length > 1">{{$t('transfer_codes')}}</span>
    </v-tooltip>
    <v-dialog v-if="!isDisabled" scrollable width="600" persistent v-model="dialog">
      <v-card>
        <v-card-title>
          <span v-if="codes.length === 1">{{$t('transfer_code')}}</span>
          <span v-if="codes.length > 1">{{$t('transfer_codes_count', { count: count })}}</span>
        </v-card-title>
        <v-card-text class="py-5">

          <div class="info-ctn">
            <div class="transfer font-weight-bold mb-2">{{$t('current_code_info')}}:</div>
            <div class="info-card pa-3">
              <div class="label font-weight-bold" v-if="codes.length > 1">{{$t('code_ids')}}</div>
              <div class="label font-weight-bold" v-if="codes.length === 1">{{$t('code_id')}}</div>
              <div v-for="code in codes" :key="code.message" >{{code.message}}</div>
              <div v-if="count >= 20"><br>{{$t('and_more_codes', { count: count - codes.length })}}<br><br></div>
              <div class="label font-weight-bold">{{$t('current_product')}}</div>
              <div>
                {{codes[0].product.name}} - {{codes[0].product.sku}}
              </div>
            </div>
          </div>

          <div class="text-center my-4">
            <v-icon size="45" class="transfer-arrow">mdi-transfer-down</v-icon>
          </div>

          <div class="info-ctn mt-4">
            <div class="transfer font-weight-bold">{{$t('transfering_to')}}:</div>
            <v-autocomplete
              v-model="selectedProduct"
              :items="products"
              :loading="loading"
              placeholder="Select a product"
              :search-input.sync="searchText"
              item-text="name"
              return-object
              :no-filter="true"
            >
              <template v-slot:selection="data">
                <div v-bind="data.attrs">
                  {{data.item.name}} - {{data.item.sku}}
                </div>
              </template>
              <template v-slot:item="data">
                <div v-bind="data.attrs">
                  {{data.item.name}} - {{data.item.sku}}
                </div>
              </template>
            </v-autocomplete>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">{{$t('close')}}</v-btn>
          <v-btn text color="primary" @click="moveCode" :disabled="!selectedProduct || !selectedProduct.id" :loading="saving">{{$t('transfer')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import ProductService from '@/services/products'
import amplitude from 'amplitude-js'

export default {
  name: 'CodeChangeProduct',
  props: ['codes', 'mode', 'count', 'product', 'campaign', 'selectAll', 'isDisabled'],
  data () {
    return {
      dialog: false,
      saving: false,
      loading: false,
      selectedProduct: null,
      searchText: '',
      products: [],
      allCodes: [],
      batchSize: 20
    }
  },
  watch: {
    searchText () {
      this.loadProducts()
    }
  },
  methods: {
    openDialog () {
      if (!this.products.length) {
        this.loadProducts()
      }
      amplitude.getInstance().logEvent('click transfer code')
      this.dialog = true
    },

    async loadProducts () {
      this.loading = true
      const { results } = await ProductService.loadProducts({ search: this.searchText })
      setTimeout(() => {
        this.products = results.filter((p) => {
          return p.sku !== 'default'
        })
      })
      this.loading = false
    },

    close () {
      this.dialog = false
    },

    async transferBatch (index, retry) {
      let array
      if (this.allCodes.length) {
        array = JSON.parse(JSON.stringify(this.allCodes))
      } else {
        array = JSON.parse(JSON.stringify(this.codes))
      }
      array = array.splice(index, this.batchSize)

      if (!array.length) {
        this.saving = false
        this.close()
        this.$emit('code-transfered', { to: this.selectedProduct.id })
        return
      }

      try {
        // delete batch to not set it on multiple codes
        await this.$store.dispatch('bulkTransferAndUpdate', { codes: array, product: this.selectedProduct.sku })
        this.transferBatch(index + this.batchSize)
      } catch (err) {
        console.log('ER')
        console.log(err)
        if (err && err.response && err.response.status === 429) {
          this.throttling = true
          console.log('Throttling')
          setTimeout(() => {
            this.transferBatch(index, true)
          }, 10000)
        }
      }
    },

    async moveCode () {
      this.saving = true
      amplitude.getInstance().logEvent('click transfered code')
      if (this.mode === 'bulk') {
        if (this.selectAll && this.count > 20) {
          console.log('more than 20 codes selected, loading all')
          this.allCodes = await this.$store.dispatch('loadAllCodes', { count: this.count ? this.count : 0, campaign: this.campaign, product: this.product })
          this.transferBatch(0)
        } else {
          console.log('less than 20')
          this.transferBatch(0)
        }
      } else {
        const codesAffected = await this.$store.dispatch('transferCode', { code: this.codes[0], product: this.selectedProduct.sku })
        if (codesAffected) {
          // Emit force reload event
          this.$emit('code-transfered', { to: this.selectedProduct.id })
        }
        this.saving = false
        this.close()
      }
    }
  }
}

</script>

<style lang="sass" scoped>

.info-ctn
  .transfer
    color: black
  .info-card
    border-radius: 5px
    background: #f1f1f1
    .label
      text-transform: uppercase
      color: #777

</style>
