<template>
  <v-card class="unique-count-card">
    <card-label color="ff7f00" content="unique count"></card-label>
    <v-row justify="center" align="center" class="ma-0 my-6 unique-code-ctn">
      <v-icon size="48px" class="scan-icon">$qr-code</v-icon>
      <div class="pl-3">
        <div class="number font-weight-bold">{{scanSummary.meta.distinct_codes}}</div>
        <div class="legend font-weight-bold">Unique Codes</div>
      </div>
    </v-row>
    <v-row justify="center" align="center" class="ma-0 my-6 unique-users-ctn">
      <v-icon size="48px" class="scan-icon">$user</v-icon>
      <div class="pl-3">
        <div class="number font-weight-bold">{{scanSummary.users.total}}</div>
        <div class="legend font-weight-bold">Unique Users</div>
      </div>
    </v-row>
  </v-card>
</template>

<script>

import CardLabel from '@/components/CardLabel'
import { mapGetters } from 'vuex'

export default {
  name: 'UniqueCountCard',
  computed: {
    ...mapGetters(['scanSummary'])
  },
  components: {
    CardLabel
  }
}

</script>

<style lang="sass" scoped>

@import "@/variables.sass"

.unique-count-card
  padding-top: 8px

.unique-code-ctn, .unique-users-ctn
  .number
    line-height: 28px
    font-size: 36px
  .legend
    line-height: 15px

.unique-code-ctn
  color: $orange

.unique-users-ctn
  color: rgba($orange, 0.8)

</style>
