import * as types from '../mutations'
import CampaignService from '@/services/campaigns'

const state = {
  campaigns: [],
  loading: true
}

const getters = {
  campaigns: (state) => state.campaigns,
  loadingCampaigns: (state) => state.loading
}

const actions = {

  async loadCampaigns ({ commit }) {
    commit(types.SET_CAMPAIGN_LOADING, true)
    const campaigns = await CampaignService.loadCampaigns()
    commit(types.SET_CAMPAIGN_LOADING, false)
    commit(types.SET_CAMPAIGN_LIST, campaigns)
    if (campaigns.length) {
      return campaigns[0]
    }
  }

}

const mutations = {
  [types.SET_CAMPAIGN_LIST] (state, campaigns) {
    state.campaigns = campaigns
  },

  [types.SET_CAMPAIGN_LOADING] (state, loading) {
    state.loading = loading
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
