<template>
  <v-card>
    <v-card-title v-if="form.id">Edit destination</v-card-title>
    <v-card-title v-if="!form.id && !hideHeader">Add destination</v-card-title>
    <v-card-text class="destination-logic pa-0">
      <div class="pa-5">
        <v-form ref="destination-form" v-model="valid">
          <div class="destination-name-ctn my-3">
            <div class="section-title">
              Scan destination name:
            </div>
            <v-text-field :rules="rules.name" v-model="form.name" outlined dense></v-text-field>
          </div>
          <div class="destination">
            <div class="section-title">
              Choose a scan destination:
            </div>
            <div class="d-flex align-start py-3">
              <v-select
                label="Destination type"
                v-model="form.type"
                :items="destinations"
                item-text="value"
                item-value="key"
                dense
                hide-details
                outlined
                class="flex-grow-0 py-0 mr-2"
              ></v-select>
              <div v-if="form.type === 'stc' ">
                <v-autocomplete
                  v-if="form.type === 'stc'"
                  v-model="form.stc_config"
                  :items="filteredLandingPages"
                  :loading="landingPagesLoading"
                  placeholder="Landing page"
                  :search-input.sync="landingPageSearchText"
                  item-text="name"
                  item-value="id"
                  dense
                  hide-details
                  outlined
                  :no-filter="true"
                  :no-data-text="landingPagesLoading ? 'Loading...' : 'No data found.'"
                  ref="landingPageAutoComplete"
                >
                </v-autocomplete>
                <div class="section-hint" >
                  <span class="my-2">
                    <v-icon>mdi-information-outline</v-icon>
                    To update the landing page content, go to: <v-btn class="my-2" color="primary" small text @click="goToLandingPage(form.stc_config)">Landing pages</v-btn>
                  </span>
                </div>
              </div>
              <v-text-field :rules="rules.url" validate-on-blur @blur="onBlurUrl" @focus="onFocusUrl" label="URL" v-if="form.type === 'url'" v-model="form.url" outlined dense></v-text-field>
            </div>
            <!-- <div v-if="form.type == 'url'" class="">

            </div> -->
          </div>
        </v-form>
        <div class="conditions mt-3">
          <div class="section-title">
            Conditions:
          </div>
          <div v-if="form.conditions && ((form.conditions.or && form.conditions.or.length) || (form.conditions.and && form.conditions.and.length))" class="section-hint">
            <div class="py-2" v-if="!deleteMode">
              <v-icon>mdi-information-outline</v-icon>
              To delete conditions:
              <v-btn text color="red" @click="deleteMode = !deleteMode">
                click here
              </v-btn>
            </div>
            <v-btn v-if="deleteMode" color="error" @click="deleteMode = !deleteMode">
              Done deleting
            </v-btn>
          </div>
          <!-- JSON LOGIC -->
          <div v-if="!form.conditions || (form.conditions && form.conditions['=='])">
            <div class="description">
              No conditions set yet. All scans will be redirected to the selected destination. Start creating conditions for specific scans.
            </div>
            <div class="d-flex my-2 justify-center align-center">
              <v-btn large text color="primary" @click="addFirstConditionBlock()">
                <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
                Create conditions
              </v-btn>
            </div>

          </div>
          <div v-else>
            <div v-for="(mainConditions, mainOperator) in form.conditions" :key="`main-${mainOperator}`">
              <div v-for="(blocks, index) in form.conditions[mainOperator]" :key="`block-${index}`">
                <div v-for="(conditions, operator) in blocks" :key="`block-${index}-conditions-${operator}`">
                  <div class="nested-conditions mt-4" v-if="operator === 'or' || operator === 'and'">
                    <div v-if="!deleteMode && conditions.length > 1" class="condition-header d-flex justify-center">
                      <!-- Conditional operator -->
                      <div class="condition-header-ctn py-4 px-5 d-flex align-center">
                        Expressions inside are connect by
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <button v-bind="attrs" v-on="on" class="d-flex ml-2 align-center justify-center font-mono conditional-operator">
                              {{operator}}
                              <v-icon color="primary">
                                mdi-menu-down
                              </v-icon>
                            </button>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="item in conditionalOperators"
                              :key="item.key"
                            >
                              <v-list-item-title @click="selectOperator(item.key, `${mainOperator}.${index}.${operator}`)">{{ item.value }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </div>
                    <div v-for="(nestedBlocks, nestedBlocksIndex) in conditions" :key="`block-${index}-conditions-${operator}-nested-blocks-${nestedBlocksIndex}`">
                      <div v-for="(nestedConditions, nestedOperator) in nestedBlocks" :key="`block-${index}-conditions-${operator}-nested-blocks-${nestedBlocksIndex}-nested-op-${nestedOperator}`">
                        <div class="d-inline-flex my-2 align-center">

                          <v-btn @click="deleteNestedConditionBlock(conditions, nestedBlocksIndex, form.conditions[mainOperator], index)" v-show="deleteMode" color="red" icon>
                            <v-icon>mdi-minus-circle-outline</v-icon>
                          </v-btn>
                          <v-autocomplete
                            v-if="(getTypeof(nestedConditions[0].var) === 'object' || (getTypeof(nestedConditions[0].var) === 'string' && nestedConditions[0].var.indexOf('scm') === -1))"
                            v-model="nestedConditions[0].var"
                            :items="variables"
                            item-text="description"
                            item-value="value"
                            dense
                            hide-details
                            outlined
                            placeholder="Select a parameter"
                            class="parameter-select mx-1"
                            @change="onVariableChange(nestedConditions, nestedOperator)"
                          ></v-autocomplete>
                          <div v-if="nestedConditions[0].var !== null && getTypeof(nestedConditions[0].var) === 'string' && nestedConditions[0].var.indexOf('scm') > -1" class="d-flex align-center outlined" >
                            SCM Field
                            <v-btn class="ml-2" @click="nestedConditions[0].var = ''" icon>
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </div>

                          <v-combobox
                            v-if="getTypeof(nestedConditions[0].var) === 'string' && nestedConditions[0].var.indexOf('scm') >= 0"
                            v-model="nestedConditions[0].var"
                            :value="nestedConditions[0].var === 'scm' ? '' : nestedConditions[0].var"
                            @change="onScmChange($event, nestedConditions[0])"
                            label="SCM field"
                            outlined
                            hide-details
                            dense
                            item-text="description"
                            item-value="key"
                            :return-object="false"
                            :items="rulesScmFields"
                            class="parameter-select mx-1"
                          >
                            <template v-slot:selection="data">
                              {{ rulesScmFields.find((scm) => scm.key === data.item) ? rulesScmFields.find((scm) => scm.key === data.item).description : data.item.split('scm_')[1] }}
                            </template>
                          </v-combobox>

                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="operator-btn mx-1"
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{getOperator(nestedOperator).value}}
                                <v-icon color="primary">
                                  mdi-menu-down
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="item in operators"
                                :key="item.key"
                              >
                                <v-list-item-title @click="selectOperator(item.key, `${mainOperator}.${index}.${operator}.${nestedBlocksIndex}.${nestedOperator}`)">{{ item.value }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>

                          <div class="value-input mx-1">
                            <logic-value-input :operator="nestedOperator" :type="nestedConditions[0].var" :value.sync="nestedConditions[1]"></logic-value-input>
                          </div>

                          <div v-if="nestedBlocksIndex < conditions.length - 1" class="flex-grow-1 mx-1 font-mono">
                            {{operator}}
                          </div>
                          <v-btn @click="addNestedConditionBlock(`${mainOperator}.${index}.${operator}`)" icon color="primary" v-else>
                            <v-icon>mdi-plus-circle-outline</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="d-inline-flex">
                      <v-select
                        v-model="conditions[0].var"
                        :items="variables"
                        dense
                        hide-details
                        item-text="description"
                        item-value="value"
                        outlined
                        @change="onVariableChange(conditions, operator)"
                      ></v-select>

                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{operator}}
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="item in operators"
                            :key="item.key"
                          >
                            <v-list-item-title class="pointer" @click="selectOperator(item.key, `${mainOperator}.${index}.${operator}`)">{{ item.value }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>

                      <div class="value-input">
                        <logic-value-input :operator="operator" :type="conditions[0].var" :value.sync="conditions[1]"></logic-value-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="index != form.conditions[mainOperator].length - 1" class="expression-connector">
                  <div class="connector"></div>
                  <div class="operator">{{mainOperator}}</div>
                  <div class="connector"></div>
                </div>
              </div>
            </div>
            <div class="expression-connector">
              <div class="connector" v-if="form.conditions[Object.keys(form.conditions)[0]].length"></div>
              <div class="add-block-ctn">
                <div v-if="!form.conditions[Object.keys(form.conditions)[0]].length" class="title">Get started by adding a condition:</div>
                <v-btn class="my-2" large text color="primary" @click="addConditionBlock(Object.keys(form.conditions)[0])">
                  <v-icon color="primary" class="mr-2">mdi-plus-circle-outline</v-icon>
                  Add Expression Block
                </v-btn><br>
                <div v-if="form.conditions[Object.keys(form.conditions)[0]].length" class="d-flex align-content justify-center">
                  <span class="">
                    Connection expression blocks by:
                  </span>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <button v-bind="attrs" v-on="on" class="d-flex ml-2 align-center justify-center conditional-operator">
                        {{Object.keys(form.conditions)[0]}}
                        <v-icon color="primary">
                          mdi-menu-down
                        </v-icon>
                      </button>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="item in conditionalOperators"
                        :key="item.key"
                      >
                        <v-list-item-title @click="selectOperator(item.key, Object.keys(form.conditions)[0])">{{ item.value }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <div v-if="form.conditions[Object.keys(form.conditions)[0]].length" class="connector"></div>
              <div v-if="form.conditions[Object.keys(form.conditions)[0]].length" class="operator">end.</div>
            </div>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()" text color="primary">Cancel</v-btn>
        <v-btn v-if="!deleteMode" @click="save()" :loading="saving" text color="primary">Save</v-btn>
        <v-btn text v-if="deleteMode" color="primary" @click="deleteMode = !deleteMode">Done Deleting</v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>

import { mapGetters } from 'vuex'
import Api from '@/api'
import LogicValueInput from '@/components/redirection/LogicValueInput'

export default {
  name: 'destination-logic',
  props: ['rule', 'destination', 'hideHeader'],
  components: {
    LogicValueInput
  },
  computed: {
    ...mapGetters(['redirectionVariables', 'rulesScmFields', 'products', 'landingPages', 'isRemyCompany', 'redirectionRuleDestinations']),
    variables () {
      const sortedVariables = JSON.parse(JSON.stringify(this.redirectionVariables))
      sortedVariables.sort((a, b) => {
        if (a.description > b.description) {
          return 1
        } else if (a.description < b.description) {
          return -1
        }
        return 0
      })
      return sortedVariables
    }
  },
  data () {
    return {
      deleteMode: false,
      saving: false,
      valid: false,
      landingPageSearchText: '',
      landingPagesLoading: '',
      filteredLandingPages: [],
      search: {
      },
      rules: {
        url: [
          v => (!v || !v.length || /^({|(ftp|http|https):\/\/)[^ "]+$/.test(v)) || 'URL must be valid. Make sure it starts with https://',
          v => !!v || 'This field is required'
        ],
        name: [
          v => !!v || 'This field is required'
        ],
        landingPage: [
          v => !!v || 'This field is required'
        ]
      },
      form: {
        type: 'product',
        conditions: {
          '==': [1, 1]
        },
        url: '',
        position: 0,
        stc_config: null
      },
      destinations: [
        { key: 'product', value: 'Product URL' },
        { key: 'stc', value: 'Landing Page' },
        { key: 'elabel', value: 'e-label Landing page' },
        { key: 'brand', value: 'Brand URL' },
        { key: 'url', value: 'Custom URL' }
      ],
      conditionalOperators: [
        { key: 'and', value: 'and' },
        { key: 'or', value: 'or' }
      ],
      operators: [
        { key: '==', value: 'equals to' },
        { key: '!=', value: 'is not' },
        { key: 'in', value: 'is in' }
      ]
    }
  },
  watch: {
    'form.type' (newVal) {
      if (newVal === 'stc') {
        this.form.url = Api.default_stc_url
      } else if (newVal === 'elabel') {
        if (this.isRemyCompany) {
          this.form.url = Api.remy_elabel_url
        } else {
          this.form.url = Api.default_elabel_url
        }
      } else if (newVal === 'url' && [Api.remy_elabel_url, Api.default_elabel_url, Api.default_stc_url].includes(this.form.url)) {
        this.form.url = ''
      }
    },
    async landingPageSearchText () {
      this.landingPagesLoading = true
      this.filteredLandingPages = await this.$store.dispatch('searchLandingPages', { search: this.landingPageSearchText })
      this.landingPagesLoading = false
    }
  },
  methods: {

    getTypeof (param) {
      return typeof param
    },

    onVariableChange (conditions, operator) {
      if (operator === 'in') {
        conditions[1] = []
      } else {
        conditions[1] = ''
      }
    },

    async save () {
      this.$refs['destination-form'].validate()
      if (!this.valid) {
        return
      }
      this.saving = true
      if (!this.form.type) {
        this.form.type = 'product'
      }
      if (this.form.id) {
        const destination = await this.$store.dispatch('updateRuleDestination', { ruleId: this.rule.id, destination: this.form })
        this.$emit('destination-updated', destination)
      } else {
        this.form.position = this.redirectionRuleDestinations(this.rule.id).length
        const destination = await this.$store.dispatch('createRuleDestination', { ruleId: this.rule.id, destination: this.form })
        this.$emit('destination-created', destination)
      }
      if (!this.destination.id) {
        this.resetForm()
      }
      this.saving = false
    },

    resetForm () {
      this.$set(this, 'form', {
        type: 'product',
        conditions: {
          '==': [1, 1]
        },
        url: '',
        position: 0,
        stc_config: null
      })
      this.$refs['destination-form'].reset()
      this.$forceUpdate()
    },

    onScmChange (newVal, varObj) {
      if (varObj && varObj.var === 'scm') {
        varObj.var = newVal.key
      }
      if (newVal === null) {
        varObj.var = 'scm_'
      }
      if (newVal && newVal.length && newVal.indexOf('scm_') === -1) {
        varObj.var = 'scm_' + newVal
      }
    },

    onFocusUrl () {
      if (this.form.type === 'url' && !this.form.url) {
        this.form.url = 'https://'
      }
    },

    onBlurUrl () {
      if (this.form.ype === 'url' && this.form.url && this.form.url === 'https://') {
        this.form.url = ''
      }
    },

    goToLandingPage (config) {
      this.$router.push({ name: 'landing-page' })
    },

    cancel () {
      this.$emit('cancel')
      // this.$router.push({ name: 'redirection' })
    },

    getOperator (operator) {
      if (!operator) {
        return
      }
      return this.operators.find((op) => {
        return op.key === operator
      })
    },

    addConditionBlock (operator) {
      this.form.conditions[operator].push({
        and: [
          { '==': [{ var: 'code_extended_id' }, ''] }
        ]
      })
      this.$forceUpdate()
    },

    deleteNestedConditionBlock (conditions, index, parent, parentIndex) {
      conditions.splice(index, 1)
      if (!conditions.length) {
        parent.splice(parentIndex, 1)
      }
      this.$forceUpdate()
      if (!parent.length) {
        // Reset to an always true condition
        this.form.conditions = { '==': [1, 1] }
      }
    },

    addFirstConditionBlock () {
      this.form.conditions = {
        or: [
          { and: [{ '==': [{ var: 'code_extended_id' }, ''] }] }
        ]
      }
    },

    addNestedConditionBlock (ref) {
      const path = ref.split('.')
      let temp = this.form.conditions
      for (let i = 0; i < path.length; i++) {
        temp = temp[path[i]]
      }
      temp.push({
        '==': [{ var: 'product_name' }, '']
      })
      this.$forceUpdate()
    },

    selectOperator (operator, ref) {
      const path = ref.split('.')

      let temp = this.form.conditions
      if (path && path.length > 1) {
        for (let i = 0; i < path.length - 1; i++) {
          temp = temp[path[i]]
        }
      }
      // convert value to array if "is in" keyword selected
      if (operator === 'in') {
        if (typeof temp[Object.keys(temp)[0]][1] !== 'object') {
          if (temp[Object.keys(temp)[0]][1]) {
            temp[Object.keys(temp)[0]][1] = [temp[Object.keys(temp)[0]][1]]
          } else {
            temp[Object.keys(temp)[0]][1] = []
          }
        }
      } else if (operator !== 'in' && typeof temp[Object.keys(temp)[0]][1] === 'object' && path.length > 1) {
        temp[Object.keys(temp)[0]][1] = temp[Object.keys(temp)[0]][1][0]
      }
      temp[operator] = temp[Object.keys(temp)[0]]
      if (operator !== Object.keys(temp)[0]) {
        delete temp[Object.keys(temp)[0]]
      }
      this.$forceUpdate()
    }
  },
  created () {
    if (this.destination && this.destination.id) {
      this.form = JSON.parse(JSON.stringify(this.destination))
    } else {
      this.form.position = this.rule.destination?.length ? this.rule.destination?.length : 0
    }
    if (!this.landingPages.length) {
      this.$store.dispatch('loadLandingPages')
    }
    this.filteredLandingPages = this.landingPages
  }
}

</script>

<style lang="sass" scoped>

@import "@/variables.sass"

.pointer
  cursor: pointer

.destination-logic
  height: 80%

.outlined
  border: 1px solid rgba(0, 0, 0, 0.28)
  border-radius: 4px
  padding: 1px 5px 1px 15px
  margin-left: 5px
  margin-right: 5px

.nested-conditions
  padding: 8px 10px
  border: 1px solid rgba(0, 0, 0, 0.12)
  border-radius: 4px
  position: relative

.condition-header
  position: relative
  top: -26px
  height: 20px
  margin-left: auto
  margin-right: auto
  .condition-header-ctn
    border: 1px solid rgba(0, 0, 0, 0.12)
    border-radius: 4px
    background: #fafafa

.conditional-operator
  color: $primary
  border: 2px solid $primary
  font-family: 'Roboto mono'
  border-radius: 16px
  padding: 0px 5px
  font-weight: bold

.parameter-select
  width: 255px

.expression-connector
  text-align: center
  .connector
    margin-left: auto
    margin-right: auto
    width: 1px
    background-color: rgba(0, 0, 0, 0.12)
    height: 40px
  .operator
    border: 1px solid rgba(0, 0, 0, 0.12)
    font-family: 'Roboto mono'
    border-radius: 4px
    padding: 5px
    width: 50px
    margin-left: auto
    margin-right: auto

.operator-btn
  background: #e0f4fd
  font-family: 'Roboto mono'
  text-transform: initial
  border: 1px solid rgba(0, 0, 0, 0.12)
  color: rgba(0, 0, 0, 0.87)

.font-mono
  font-family: 'Roboto mono'

</style>
