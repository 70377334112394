import * as types from '../mutations'
import ConfigService from '@/services/stc-configs'

const state = {
  configs: [],
  loading: true,
  didProductUpdate: false,
  loaded: false
}

const getters = {
  landingPages: (state) => state.configs,
  landingPagesLoaded: (state) => state.loaded,
  loadingLandingPages: (state) => state.loading,
  didProductUpdate: (state) => state.didProductUpdate,
  getLandingPage: (state) => (id) => {
    if (!state.configs) {
      return null
    }
    return state.configs.find((c) => {
      return c.id === id
    })
  }
}

const actions = {

  async searchLandingPages ({ commit }, { search }) {
    const res = await ConfigService.loadSTCConfigs({ search: search })
    return res
  },

  async loadLandingPages ({ commit }) {
    commit(types.SET_LANDING_PAGE_LOADING, true)
    const configs = await ConfigService.loadSTCConfigs()
    commit(types.SET_LANDING_PAGE_LOADING, false)
    commit(types.SET_LANDING_PAGE_LOADED, true)
    commit(types.SET_LANDING_PAGE_LIST, configs)
    if (configs.length) {
      return configs[0]
    }
  }

}

const mutations = {
  [types.SET_LANDING_PAGE_LIST] (state, configs) {
    state.configs = configs
  },

  [types.SET_LANDING_PAGE_LOADED] (state, loaded) {
    state.loaded = loaded
  },

  [types.SET_LANDING_PAGE_LOADING] (state, loading) {
    state.loading = loading
  },

  [types.UPDATE_PRODUCT_UPDATE_STATUS] (state, status) {
    state.didProductUpdate = status
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
