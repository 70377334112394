import * as types from '../mutations'
import prefixesService from '@/services/prefixes'

const state = {
  loaded: false,
  prefixes: [],
  systemPrefix: ''
}

const getters = {
  prefixes: (state) => state.prefixes,
  prefixesLoaded: (state) => state.loaded,
  systemPrefix: (state) => state.systemPrefix
}

const actions = {
  async loadPrefixes ({ commit }) {
    try {
      const res = await prefixesService.loadPrefixes()

      commit(types.SET_PREFIXES, res)
      commit(types.SET_SYSTEM_PREFIX, res)
      commit(types.SET_PREFIXES_LOADED, true)
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

const mutations = {

  [types.SET_PREFIXES] (state, prefixes) {
    state.prefixes = prefixes
  },

  [types.SET_PREFIXES_LOADED] (state, status) {
    state.loaded = status
  },

  [types.SET_SYSTEM_PREFIX] (state, prefixes) {
    const systemPrefixes = prefixes.filter((prefix) => prefix.type === 'system')
    if (!systemPrefixes.length) return

    // e.g. prefers 'https://<code_space>.st4.ch/' over 'https://st4.ch/Q/'
    const systemPrefixWithSubdomain = systemPrefixes.find((prefix) =>
      /^https:\/\/.+\.st4\.ch\/$/.test(prefix.prefix) ||
      /^https:\/\/.+\.qr1\.ch\/$/.test(prefix.prefix) ||
      /^https:\/\/.+\.qr2\.ch\/$/.test(prefix.prefix)
    )
    state.systemPrefix = systemPrefixWithSubdomain ? systemPrefixWithSubdomain.prefix : systemPrefixes[0].prefix
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
