<template>
  <v-dialog v-model="dialog" width="759px">
    <v-card>
      <v-card-title class="headline">{{ modalTitle }}</v-card-title>

      <!-- modal body -->
      <div class="ma-4 mx-8 mb-0">

        <!-- Qr code type selection -->
        <div v-if="!hideQrCodeTypeSelection && !isCodeEditing">
          <div class="title font-bold mb-2 text-uppercase">
            {{ $t("qrcode_select_type") }}
          </div>
          <v-row class="pb-0">
            <v-col class="mb-0 pb-0">
              <v-select small dense :items="codeTypeOptions" v-model="qrCodeType" items-text="text" items-value="value"
                outlined />
            </v-col>
          </v-row>
        </div>

        <!-- standard code form -->
        <v-card-text v-if="isStandardCodeFormVisible && !isCodeEditing" class="pt-0">
          <div class="mb-6"> {{ $t("qrcode_how_many_codes") }}</div>
          <v-form v-model="isStandardCodeFormValid" class="mt-4">
            <v-text-field class="mt-3" label="Quantity" v-model="quantity" type="number" counter="true"
              :counter-value="remainingQuota" :rules="quantityRules" required>
            </v-text-field>
          </v-form>
        </v-card-text>

        <!-- GS1 digital link form -->
        <v-form ref="form" v-model="isGs1CodeFormValid" v-if="isGS1CodeFormVisible || isCodeEditing" class="pt-0">

          <!-- GTIN field -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-label class="black--text">
                <span class="font-weight-bold mr-2">{{ $t('qrcode_gtin') }}*</span>
                <span class="caption">{{ $t('qrcode_products_gtin') }}</span>
              </v-label>
              <v-text-field
                small
                dense
                outlined
                :counter="14"
                :rules="gtinRules"
                required
                v-model="form.gtin"
                :disabled="disableFormFields"
                validate-on-blur />
            </v-col>
            <v-spacer></v-spacer>

            <!-- lot field -->
            <v-col md="6">
              <v-label class="black--text">
                <span class="font-weight-bold mr-2">{{ $t('qrcode_lot') }}*</span>
                <span class="caption">{{ $t('qrcode_lot_batch_number') }}</span>
              </v-label>
              <v-text-field
                small
                dense
                outlined
                persistent-hint
                :counter="20"
                :rules="fieldRules"
                v-model="form.lot"
                :required="false"
                :disabled="disableFormFields"
                validate-on-blur>
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="{
                          ...on,
                          click: handleRefreshIconClick
                        }" :disabled="disableFormFields">
                        mdi-refresh
                      </v-icon>
                    </template>
                    <span class="black--text body-2">{{ $t('qrcode_generate_batch_number') }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <!-- CPV field -->
          <v-row>
            <v-col md="6">
              <v-label class="black--text">
                <span class="font-weight-bold mr-2">{{ $t('qrcode_cpv') }}*</span>
                <span class="caption">{{ $t('qrcode_consumer_product_variant_vintage_year') }}</span>
              </v-label>
              <v-text-field
                small
                dense
                outlined
                persistent-hint
                :counter="20"
                v-model="form.cpv"
                :rules="fieldRules"
                :required="false"
                :disabled="disableFormFields"
                validate-on-blur />
            </v-col>
          </v-row>

          <!-- disables all fields -->
          <v-row class="mt-4">
            <v-col class="border border-primary d-flex flex-column" v-if="gs1Code" align-center>
              <div class="d-flex flex-row fill-height">
                <v-chip class="align-self-center pa-0 px-1 mr-1" label color="#002C6C" text-color="white" small>
                  {{ $t('qrcode_gs1') }}
                </v-chip>
                <span :class="[!isGs1CodeFormValid ? 'gs1-error-message' : 'gs1-label-text', 'align-self-center']">
                  {{gs1Code}}
                </span>
              </div>
              <div class="mt-2" v-if="isRequestInvalid && requestErrorMessage.length">
                <div v-for="(message, idx) in requestErrorMessage" :key="idx" class="gs1-error-message mb-1">
                  {{ message }}
                </div>
              </div>
            </v-col>
          </v-row>

          <!-- tip text -->
          <v-row class="mt-2 mb-4">
            <v-col>
              <div class="info-text">
                <span class="font-weight-bold mr-2">{{ $t('qrcode_tip') }}:</span>
                <i18n
                  path="qrcode_info_text"
                  tag="label"
                  class="font-weight-regular"
                  scope="global"
                >
                  <template v-slot:qrcode_ingestion_feature>
                    <a
                      @click="goToHelpCenter(helpCenterLink)"
                      target="_blank"
                      class="text-decoration-underline info-text font-weight-regular"
                      rel="noopener noreferrer"
                    >
                      {{ $t('qrcode_ingestion_feature') }}
                    </a>
                  </template>
                  <template v-slot:product_field_below>
                    {{ $t('product_field_below') }}
                  </template>
                </i18n>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </div>

      <!-- modal action buttons -->
      <v-card-actions class="pa-4 px-8 mt-3 pb-6">
        <!-- back button with icon -->
        <v-btn
          color="primary"
          class="pl-0"
          text
          v-if="displayBackButton"
          @click="handleBackButtonClick">
          <v-icon class="mr-1">mdi-arrow-left</v-icon>{{$t('back')}}
        </v-btn>
        <v-spacer></v-spacer>
        <!-- display cancel button when editing gs1 code -->
        <v-btn class="mr-16" @click="closeModal" color="primary" text v-if="displayCancelButton">
          {{$t('cancel')}}
        </v-btn>
        <v-btn
          :class="disableNextButton ? 'grey' : 'primary'"
          :disabled="disableNextButton"
          :dark="!disableNextButton"
          :loading="isCreatingCode"
          @click="handleActionButtonClick">
          {{actionButtonText}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Utils from '@/utils/utils'
import { mapGetters, mapMutations } from 'vuex'
import amplitude from 'amplitude-js'

export default {
  name: 'generate-qrcode-dialog',
  data: () => ({
    qrCodeType: null,
    displayStandardCodeForm: false,
    isStandardCodeFormValid: false,
    isGs1CodeFormValid: false,
    displayGS1CodeForm: false,
    batchSize: 20,
    quantity: 1,
    form: {
      gtin: '',
      cpv: '',
      lot: ''
    },
    helpCenterLink: 'https://help.scantrust.com/hc/en-us/articles/11328726701084-e-label-Editor-QR-Codes-GS1-Digital-Link-Preparation-and-Generation-in-Bulk-Enterprise'
  }),
  props: {
    displayDialog: {
      type: Boolean,
      required: true
    },
    selectedProduct: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['totalCodes', 'totalActiveCodesCount', 'inactiveCodes', 'selectedCampaign', 'isCreatingCode', 'codes', 'isCodeRenameError', 'codeRenameErrorMsg', 'isCodeEditing', 'selectedProductCode', 'profile']),

    dialog: {
      get () {
        return this.displayDialog
      },
      set (value) {
        this.$emit('update:displayDialog', value)
      }
    },
    codeTypeOptions () {
      return [
        { text: this.$t('qrcode_with_gs1_digital_link'), value: Utils.CODE_TYPE.GS1 },
        { text: this.$t('qrcode_standard_qr_code'), value: Utils.CODE_TYPE.DEFAULT }
      ]
    },
    actionButtonText () {
      if (this.displayStandardCodeForm || (this.displayGS1CodeForm && !this.isCodeEditing && !this.isCodeRenameError)) return this.$t('activate')
      else if ((this.isCodeEditing && this.displayGS1CodeForm) || this.isCodeRenameError) return this.$t('save') // display save button when editing gs1 codes
      return this.$t('next')
    },
    disableNextButton () {
      return (this.qrCodeType === null) || (this.isStandardCodeSelected && this.quantity < 1 && !this.isStandardCodeFormValid) ||
        (this.isGS1CodeFormVisible && !this.isGs1CodeFormValid)
    },
    isGS1CodeSelected () {
      return this.qrCodeType === Utils.CODE_TYPE.GS1
    },
    isStandardCodeSelected () {
      return this.qrCodeType === Utils.CODE_TYPE.DEFAULT
    },
    isStandardCodeFormVisible () {
      return this.isStandardCodeSelected && this.displayStandardCodeForm
    },
    isGS1CodeFormVisible () {
      return this.isGS1CodeSelected && this.displayGS1CodeForm
    },
    hideQrCodeTypeSelection () {
      return this.isStandardCodeFormVisible || this.isGS1CodeFormVisible
    },
    displayBackButton () {
      return (this.isStandardCodeFormVisible && this.isCodeSpacesEnabled) || (this.isGS1CodeFormVisible && !this.isCodeEditing && !this.isCodeRenameError)
    },
    displayCancelButton () {
      return !this.hideQrCodeTypeSelection || this.isCodeEditing || this.isCodeRenameError || !this.isCodeSpacesEnabled
    },
    maxQuota () {
      return this.totalCodes - this.totalActiveCodesCount
    },
    quantityRules () {
      return [
        v => !!v || 'Quantity is required',
        v => v > 0 || 'Quantity needs to be at least 1',
        v => v <= this.maxQuota || `You only have ${this.maxQuota} codes available.`
      ]
    },
    gtinRules () {
      return [
        v => !!v || this.$t('qrcode_gtin_required'),
        v => /^[0-9]+$/.test(v) || this.$t('qrcode_field_numeric_allowed'),
        v => v.length === 14 || this.$t('qrcode_gtin_fourteen_characters')
      ]
    },
    fieldRules () {
      return [
        v => !v || Utils.isAlphaNumeric(v) || this.$t('qrcode_field_numeric_alpha_allowed'),
        v => !v || v.length <= 20 || this.$t('qrcode_field_twenty_characters_maximum')
      ]
    },
    sortedCodes () {
      const sortedArray = this.codes
      return sortedArray.sort((a, b) => {
        return new Date(b.activated_at) - new Date(a.activated_at)
      })
    },
    gs1Code () {
      return (this.form.gtin ? `${Utils.AI.GTIN}/${this.form.gtin}` : '') +
        (this.form.cpv ? ((this.form.lot || this.form.gtin) && '/') + `${Utils.AI.CPV}/${this.form.cpv}` : '') +
        (this.form.lot ? (this.form.gtin && '/') + `${Utils.AI.LOT_BATCH_NUMBER}/${this.form.lot}` : '')
    },
    disableFormFields () {
      return this.isGS1CodeSelected && this.isCreatingCode
    },
    isRequestInvalid () {
      return this.isCodeRenameError
    },
    requestErrorMessage () {
      return this.codeRenameErrorMsg
    },
    modalTitle () {
      if (this.isCodeEditing || this.isCodeRenameError) {
        return this.$t('qrcode_edit_gs1_link')
      }
      return this.$t('qrcode_new_codes')
    },
    isCodeSpacesEnabled () {
      return this.profile?.company?.code_spaces_enabled
    }
  },
  methods: {
    ...mapMutations({
      setTotalActiveCount: 'SET_TOTAL_ACTIVE_CODES_COUNT',
      setIsCreatingCode: 'SET_IS_CREATING_CODE',
      setRenameCodeErrorMessage: 'SET_RENAME_CODE_ERROR_MESSAGE',
      setRenameCodeError: 'SET_RENAME_CODE_ERROR',
      setSelectedProductCode: 'SET_SELECTED_PRODUCT_CODE',
      setCodeEditing: 'SET_CODE_EDITING'
    }),

    closeModal () {
      this.dialog = !this.dialog
      if (this.isStandardCodeSelected) this.resetStandardFormValues()
      else if (this.isGS1CodeSelected) this.resetGS1FormValues()
    },
    handleActionButtonClick () {
      if (this.isStandardCodeSelected) {
        if (!this.displayStandardCodeForm) {
          this.displayStandardCodeForm = true
        } else if (this.isStandardCodeFormVisible && this.isStandardCodeFormValid) {
        // only call generate standard code function if standard code is visible and form is valid
          this.generateStandardCode(this.quantity)
        }
      } else if (this.isGS1CodeSelected) {
        if (!this.displayGS1CodeForm) {
          // display gs1 form
          this.displayGS1CodeForm = true
        } else if (this.isGS1CodeFormVisible && this.isGs1CodeFormValid) {
          // gs1 codes generation
          const tags = {}
          tags.gs1_gtin = this.form.gtin
          if (this.form.lot) tags.gs1_lot = this.form.lot
          if (this.form.cpv) tags.gs1_cpv = this.form.cpv
          this.generateGS1Code(this.selectedProduct, tags, this.gs1Code)
        }
      }
    },
    resetQrCodeSelection () {
      if (this.qrCodeType) this.qrCodeType = null
    },
    resetStandardFormValues () {
      this.quantity = 1
      this.resetQrCodeSelection()
      if (this.displayStandardCodeForm) this.displayStandardCodeForm = false
    },
    resetGS1FormValues () {
      this.resetQrCodeSelection()
      if (this.displayGS1CodeForm) this.displayGS1CodeForm = false
      if (this.isGs1CodeFormValid) this.isGs1CodeFormValid = false
      this.form = {
        gtin: '',
        cpv: '',
        lot: ''
      }

      if (this.isCodeEditing) {
        this.setSelectedProductCode(null)
        this.setCodeEditing(false)
        this.codeRenameErrorMsg.length && this.resetErrorValues()
      }
    },
    resetErrorValues () {
      this.setRenameCodeErrorMessage({ errorMessage: [] })
      this.setRenameCodeError(false)
    },
    remainingQuota () {
      return `Remaining ${(this.maxQuota - this.quantity) > 0 ? (this.maxQuota - this.quantity) : 0}`
    },
    handleBackButtonClick () {
      if (this.displayStandardCodeForm) {
        this.displayStandardCodeForm = false
      } else if (this.displayGS1CodeForm) {
        this.displayGS1CodeForm = false
      }
    },
    async createBatch (quantity) {
      if (!quantity) {
        return Promise.resolve()
      }
      try {
        // delete batch to not set it on multiple codes
        if (this.inactiveCodes.length < quantity && this.inactiveCodes.length < this.batchSize) {
          await this.$store.dispatch('loadInactiveCodes', { campaign: this.selectedCampaign.id })
        }
        const array = this.inactiveCodes.splice(0, quantity <= this.batchSize ? quantity : this.batchSize)
        await this.$store.dispatch('bulkTransferAndUpdate', { codes: array, product: this.selectedProduct.sku, status: 1 })
        if (quantity <= this.batchSize) {
          await this.createBatch(0)
        } else {
          await this.createBatch(quantity - this.batchSize)
        }
      } catch (err) {
        if (err && err.response && err.response.status === 429) {
          setTimeout(() => {
            this.createBatch(quantity)
          }, 10000)
        }
      }
    },
    async generateStandardCode () {
      this.dialog = false
      this.setIsCreatingCode(true)
      if (this.quantity === 1) {
        try {
          const code = await this.$store.dispatch('activateAndAssignCode', { product: this.selectedProduct })
          code.isNew = true
          setTimeout(() => {
            code.isNew = false
          })
          amplitude.getInstance().logEvent('create QR codes', { quantity: this.quantity })
          this.setTotalActiveCount(parseInt(this.totalActiveCodesCount) + parseInt(this.quantity))
          this.resetStandardFormValues()
          this.setIsCreatingCode(false)
        } catch (err) {
          this.setIsCreatingCode(false)
          await this.$store.dispatch('showSnackBar', { message: this.$t('qrcode_snackbar_message') })
        }
      } else {
        await this.createBatch(this.quantity)
        this.setIsCreatingCode(false)
        await this.$store.dispatch('loadProductCodeCount', { product: this.selectedProduct.id })
        await this.$store.dispatch('loadCodes', { campaign: this.selectedCampaign.id, product: this.$route.params.id, offset: 0, noFilter: true })
        this.sortedCodes.forEach((code, index) => {
          if (index < this.quantity) {
            code.isNew = true
          }
        })
        amplitude.getInstance().logEvent('create QR codes', { quantity: this.quantity })
        this.setTotalActiveCount(parseInt(this.totalActiveCodesCount) + parseInt(this.quantity))
        this.resetStandardFormValues()
      }
    },
    handleRefreshIconClick () {
      this.form.lot = Utils.generateLotNumber()
    },
    goToHelpCenter (link) {
      parent.postMessage({ type: 'helpcenter', data: link }, '*')
    },
    async generateGS1Code (product, tags, gs1Code) {
      try {
        if (!this.inactiveCodes.length || this.inactiveCodes.length === 0) {
          await this.$store.dispatch('showSnackBar', { message: this.$t('qrcode_not_available') })
          return
        }
        this.dialog = false
        this.setIsCreatingCode(true)

        const code = await this.$store.dispatch('activateCodeTransferAndRename', {
          product,
          gs1Code,
          tags
        })

        if (!this.isCodeEditing) {
          code.isNew = true
          setTimeout(() => {
            code.isNew = false
          })
          this.setTotalActiveCount(parseInt(this.totalActiveCodesCount) + 1)
        }

        amplitude.getInstance().logEvent('create GS1 QR codes')
        this.setIsCreatingCode(false)
        this.resetGS1FormValues()
      } catch (e) {
        this.setIsCreatingCode(false)
        // display modal on code creation error
        this.dialog = true
      }
    }

  },
  watch: {
    selectedProductCode (newVal) {
      if (newVal !== null) {
        this.displayGS1CodeForm = true
        this.qrCodeType = Utils.CODE_TYPE.GS1

        // check old value against new code and reset error messages
        if (this.selectedProductCode && this.selectedProductCode.id !== newVal.id && this.isCodeRenameError) {
          this.resetErrorValues()
        }

        // update form fields with gs1 code
        const matchPattern = /^01\/(\d{14})(\/22\/([a-zA-Z0-9]+))?(\/10\/([a-zA-Z0-9]+))?/
        const message = matchPattern.exec(this.selectedProductCode?.message)

        if (message) {
          this.form.gtin = message[1]
          if (message[3]) this.form.cpv = message[3]
          if (message[5]) {
            this.form.lot = message[5]
          }
        }
      }
    },

    dialog (newVal) {
      // only interested when the dialog is visible
      if (newVal === true) {
        if (!this.isCodeSpacesEnabled) {
          this.qrCodeType = Utils.CODE_TYPE.DEFAULT
          this.displayStandardCodeForm = true
        }
      }
    }

  }
}
</script>

<style scoped>
.gs1-error-message {
  color: #F22A42;
  font-size: 14px;
  font-weight: 300
}

.gs1-label-text {
  color: #999999;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.info-text {
  color: #777777;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.v-tooltip__content {
  pointer-events: initial;
}
</style>
