import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "code-tag-editor"
  }, [_c(VTooltip, {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "disabled": _vm.isDisabled,
            "color": "primary",
            "icon": ""
          },
          on: {
            "click": _vm.openDialog
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, [_vm._v("mdi-tag-multiple-outline")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('edit_tags')))])]), _c(VDialog, {
    attrs: {
      "width": "600",
      "scrollable": "",
      "persistent": ""
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.mode == 'edit' ? _c(VCard, {
    staticClass: "edit-tag-dialog"
  }, [_c(VCardTitle, [_vm._v(" " + _vm._s(_vm.$t('edit_tags')) + " "), _c(VSpacer), _c(VBtn, {
    attrs: {
      "disabled": _vm.hasLimit('tags') && _vm.filteredTags.length >= _vm.getLimit('tags'),
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.mode = 'new';
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-tag-plus-outline")]), _vm._v(_vm._s(_vm.$t('new_tag')))], 1)], 1), _vm.sortedTags.length > 1 || _vm.tagsToDelete.length > 0 ? _c(VCardText, {
    staticClass: "py-5"
  }, [_vm.codes.length == 1 || !_vm.showEditRadio ? _c('div', {
    staticClass: "pl-2 pb-4 tag-hint font-weight-light"
  }, [_vm._v(" " + _vm._s(_vm.$t('empty_tag_hint')) + " ")]) : _vm._e(), _vm.codes.length > 1 && _vm.showEditRadio ? _c('div', {
    staticClass: "pl-2 pb-4 tag-hint font-weight-light"
  }, [_vm._v(" " + _vm._s(_vm.$t('bulk_tag_edit_hint')) + " "), _c(VRadioGroup, {
    staticClass: "radio-ctn font-weight-medium",
    model: {
      value: _vm.updateMode,
      callback: function callback($$v) {
        _vm.updateMode = $$v;
      },
      expression: "updateMode"
    }
  }, [_c(VRadio, {
    attrs: {
      "value": "standard",
      "label": _vm.$t('ignore_empty_tags')
    }
  }), _c(VRadio, {
    attrs: {
      "value": "clear-empty-tags",
      "label": _vm.$t('delete_empty_tags')
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex tag-header font-weight-bold mx-3"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.$t('tag_name')))]), _c('div', [_vm._v(_vm._s(_vm.$t('tag_value')))]), _c(VSpacer), _vm.hasLimit('tags') ? _c('div', [_vm._v(_vm._s(_vm.filteredTags.length) + "/" + _vm._s(_vm.getLimit('tags')))]) : _vm._e()], 1), _vm.tagsToDelete.length > 0 && !_vm.filteredTags.length ? _c('div', {
    staticClass: "pl-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('no_tags')) + " ")]) : _vm._e(), _vm._l(_vm.filteredTags, function (tag) {
    return _c('tag-input', {
      key: tag.key,
      staticClass: "ma-2",
      attrs: {
        "label": tag.key,
        "tag-value": _vm.codeTags[tag.key],
        "rules": _vm.editRules
      },
      on: {
        "delete-tag": function deleteTag($event) {
          return _vm.deleteTag(tag);
        },
        "update:tagValue": function updateTagValue($event) {
          return _vm.$set(_vm.codeTags, tag.key, $event);
        },
        "update:tag-value": function updateTagValue($event) {
          return _vm.$set(_vm.codeTags, tag.key, $event);
        }
      }
    });
  })], 2) : _vm._e(), _vm.sortedTags.length <= 1 && !_vm.tagsToDelete.length ? _c(VCardText, {
    staticClass: "py-5"
  }, [_vm._v(" " + _vm._s(_vm.$t('tags_empty_state')) + " ")]) : _vm._e(), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('close')))]), _vm.sortedTags.length <= 1 && _vm.tagsToDelete.length === 0 ? _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary",
      "loading": _vm.saving
    },
    on: {
      "click": function click($event) {
        _vm.mode = 'new';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('ok')))]) : _vm._e(), _vm.sortedTags.length > 1 || _vm.tagsToDelete.length > 0 ? _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary",
      "loading": _vm.saving
    },
    on: {
      "click": _vm.saveTags
    }
  }, [_vm._v(_vm._s(_vm.$t('save')))]) : _vm._e()], 1)], 1) : _vm._e(), _vm.mode == 'new' ? _c(VCard, {
    staticClass: "edit-tag-dialog"
  }, [_c(VCardTitle, [_vm._v(" " + _vm._s(_vm.$t('new_tag')) + " ")]), _c(VCardText, {
    staticClass: "py-5"
  }, [_c('div', {
    staticClass: "pb-5"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('new_tag_hint'))
    }
  })]), _c(VForm, {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('div', {
    staticClass: "tag-input d-flex"
  }, [_c('div', {
    staticClass: "label font-weight-bold pt-2"
  }, [_vm._v(_vm._s(_vm.$t('tag_name')))]), _c(VTextField, {
    attrs: {
      "counter": 50,
      "placeholder": _vm.$t('tag_name_example'),
      "dense": "",
      "single-line": "",
      "outlined": "",
      "rules": _vm.newRules
    },
    model: {
      value: _vm.form.key,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "key", $$v);
      },
      expression: "form.key"
    }
  })], 1), _c('div', {
    staticClass: "errors-ctn"
  }, _vm._l(_vm.errors, function (error) {
    return _c('div', {
      key: error
    }, [_vm._v(_vm._s(error))]);
  }), 0)])], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.goToEdit
    }
  }, [_vm._v(_vm._s(_vm.$t('back')))]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary",
      "loading": _vm.saving
    },
    on: {
      "click": _vm.createTag
    }
  }, [_vm._v(_vm._s(_vm.$t('create')))])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }