import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c(VBtn, {
    attrs: {
      "fab": ""
    },
    on: {
      "click": _vm.open
    }
  }, [_c(VIcon, {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" $csv ")])], 1), _c('div', {
    staticClass: "pt-4"
  }, [_vm._v(_vm._s(_vm.$t('multiple_products')))])], 1), _c(VDialog, {
    attrs: {
      "width": "800"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('update_products_by_file')))]), _c(VStepper, {
    attrs: {
      "value": _vm.step
    }
  }, [_c(VStepperHeader, [_c(VStepperStep, {
    attrs: {
      "step": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t('prepare')))]), _c(VDivider), _c(VStepperStep, {
    attrs: {
      "step": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t('upload')))])], 1), _c(VStepperItems, [_c(VStepperContent, {
    attrs: {
      "step": "1"
    }
  }, [_c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "important font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('important_notice')) + ":")]), _c(VLayout, [_c(VFlex, {
    staticClass: "infos font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t('file_header_must_contain')) + ":"), _c('br'), _c('br'), _vm._v(" - sku"), _c('br'), _vm._v(" - name"), _c('br'), _c('br'), _vm._v(" " + _vm._s(_vm.$t('other_supported_columns')) + ":"), _c('br'), _vm._v(" - url"), _c('br'), _vm._v(" - description"), _c('br'), _c('br'), _vm._v(" " + _vm._s(_vm.$t('file_format_hint_2')) + " ")]), _c(VFlex, [_c('div', {
    staticClass: "file-upload-ctn"
  }, [_c(VLayout, {
    staticClass: "upload-btn",
    attrs: {
      "align-center": "",
      "justify-center": "",
      "column": ""
    },
    on: {
      "click": function click($event) {
        return _vm.clickOnFileInput($event, 'csv-file');
      }
    }
  }, [!_vm.file || !_vm.file.name ? _c('div', {
    staticClass: "text-xs-center"
  }, [_c(VIcon, {
    attrs: {
      "x-large": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-cloud-upload")]), _c('div', {
    staticClass: "text-uppercase font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('choose_file')))]), _c('div', {
    staticClass: "format-hint"
  }, [_vm._v(" " + _vm._s(_vm.$t('supported_formats')) + ":"), _c('br'), _vm._v(" CSV, XLS, XLSX ")])], 1) : _vm._e(), _vm.file && _vm.file.name ? _c('div', {
    staticClass: "text-xs-center"
  }, [_c(VIcon, {
    attrs: {
      "x-large": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-cloud-upload")]), _c('div', {
    staticClass: "text-uppercase font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('change_file')))]), _c('div', {
    staticClass: "format-hint"
  }, [_vm._v(" " + _vm._s(_vm.file.name) + " ")])], 1) : _vm._e()]), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "csv-file",
    attrs: {
      "accept": ".csv,.xls,.xlsx",
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileChange($event);
      }
    }
  })], 1)])], 1), _vm.sheetNames.length > 1 ? _c(VLayout, [_c(VFlex, [_c(VSelect, {
    staticClass: "my-4",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "label": "Select a sheet to upload",
      "items": _vm.sheetNames
    },
    on: {
      "change": _vm.findSheetIndex
    },
    model: {
      value: _vm.selectedSheet,
      callback: function callback($$v) {
        _vm.selectedSheet = $$v;
      },
      expression: "selectedSheet"
    }
  })], 1)], 1) : _vm._e()], 1), _c(VLayout, {
    staticClass: "footer"
  }, [_c(VFlex, {
    attrs: {
      "grow": ""
    }
  }, [_c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.downloadTemplate('csv');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('download_file_template', {
    format: 'CSV'
  }))), _c(VIcon, {
    staticClass: "ml-2"
  }, [_vm._v("mdi-cloud-download")])], 1), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.downloadTemplate('xlsx');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('download_file_template', {
    format: 'XLSX'
  }))), _c(VIcon, {
    staticClass: "ml-2"
  }, [_vm._v("mdi-cloud-download")])], 1)], 1), _c(VFlex, {
    attrs: {
      "shrink": ""
    }
  }, [_c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('cancel')))]), _c(VBtn, {
    attrs: {
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.parseCSV();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('next')))])], 1)], 1)], 1), _c(VStepperContent, {
    staticClass: "custom-stepper",
    attrs: {
      "step": "2"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.completed,
      expression: "!completed"
    }],
    staticClass: "loading"
  }, [_c(VProgressLinear, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.completed,
      expression: "!completed"
    }],
    attrs: {
      "indeterminate": true
    }
  }), _c(VLayout, {
    attrs: {
      "align-center": "",
      "justify-center": "",
      "row": "",
      "fill-height": ""
    }
  }, [_c(VFlex, {
    staticClass: "text-center infos font-weight-bold",
    attrs: {
      "shrink": ""
    }
  }, [_vm._v(_vm._s(_vm.$t('uploading')) + " (" + _vm._s(this.uploadCount) + " / " + _vm._s(this.uploadData.length) + ")"), _c('br'), this.throttling ? _c('div', [_vm._v(_vm._s(_vm.$t('upload_in_progress')))]) : _vm._e()])], 1)], 1), _vm.completed && _vm.uploadErrors.length ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "important font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('errors')))]), _c('div', {
    staticClass: "infos font-weight-medium"
  }, [_vm._v(_vm._s(_vm.$t('upload_file_error')) + ":"), _c('br'), _c('br')]), _vm._l(_vm.uploadErrors, function (line, index) {
    return _c('div', {
      key: "errors-".concat(index),
      staticClass: "infos font-weight-medium"
    }, [_vm._v(" " + _vm._s(line) + " ")]);
  }), _vm.hasWarning ? _c('div', {
    staticClass: "infos mt-5 font-weight-medium"
  }, [_vm._v(_vm._s(_vm.$t('upload_error_proceed_warning'))), _c('br'), _c('br')]) : _vm._e(), _vm.hasWarning ? _c(VBtn, {
    attrs: {
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.forceUpload();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('upload_anyways')))]) : _vm._e()], 2) : _vm._e(), _vm.completed && !_vm.uploadErrors.length ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "important font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('upload_complete')))]), _vm.updateCount > 0 ? _c('div', {
    staticClass: "infos font-weight-medium"
  }, [_vm._v(_vm._s(_vm.$t('product_count_updated', {
    count: this.updateCount
  })))]) : _vm._e(), _c('br'), _vm.createCount > 0 ? _c('div', {
    staticClass: "infos font-weight-medium"
  }, [_vm._v(_vm._s(_vm.$t('product_count_created', {
    count: this.createCount
  })))]) : _vm._e()]) : _vm._e(), _c(VLayout, {
    staticClass: "footer",
    attrs: {
      "row": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "grow": ""
    }
  }, [_c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('close')))])], 1), _vm.completed && !_vm.uploadErrors.length ? _c(VFlex, {
    attrs: {
      "shrink": ""
    }
  }, [_c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.goToCodesList
    }
  }, [_vm._v(_vm._s(_vm.$t('create_codes')))])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }