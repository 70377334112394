export default [
  { key: 'de', description: 'German' },
  { key: 'nl', description: 'Dutch' },
  { key: 'bg', description: 'Bulgarian' },
  { key: 'hr', description: 'Croatian' },
  { key: 'cs', description: 'Czech' },
  { key: 'da', description: 'Danish' },
  { key: 'et', description: 'Estonian' },
  { key: 'fi', description: 'Finnish' },
  { key: 'fr', description: 'French' },
  { key: 'el', description: 'Greek' },
  { key: 'hu', description: 'Hungarian' },
  { key: 'en', description: 'English' },
  { key: 'it', description: 'Italian' },
  { key: 'ga', description: 'Irish' },
  { key: 'lv', description: 'Latvian' },
  { key: 'lt', description: 'Lithuanian' },
  { key: 'mt', description: 'Maltese' },
  { key: 'pl', description: 'Polish' },
  { key: 'pt', description: 'Portugese' },
  { key: 'sk', description: 'Slovak' },
  { key: 'sl', description: 'Slovenian' },
  { key: 'ro', description: 'Romanian' },
  { key: 'es', description: 'Spanish' },
  { key: 'sv', description: 'Swedish' }
]
